<template>
<div class="flex-col">

  <!-- Filtros -->
  <div class="flex flex-row">

      <!-- Emplazamientos -->
      <div class="w-1/4 mr-4">
        <b-field :label="$t('select_location')">
          <b-select ref="input"
                    v-model="filters.location_id"
                    :placeholder="$t('select_location')"
                    icon="map-marker-alt"
                    icon-pack="fas"
                    @input="getPanelsInfo()"
                    expanded
          >
            <option :value="0" :key="'location_0'">{{ $t('all') }}</option>
            <option v-for="option in locationsList"
                    :value="option['id']" :key="'location_'+option['id']"
            >
              {{ option['name'] }}
            </option>
          </b-select>
        </b-field>
      </div>

      <!-- Estado -->
      <div class="w-1/4 mr-4">
        <b-field :label="$t('dsos_status')">
          <b-select ref="input"
                    v-model="filters.status_id"
                    :placeholder="$t('select_status')"
                    icon="search"
                    icon-pack="fas"
                    @input="getPanelsInfo()"
                    expanded
          >
            <option :value="50" :key="'status_0'">En redacción (E03)</option>
            <option :value="51" :key="'status_1'" :selected=true>Redactados (E04 - E11)</option>
            <option :value="52" :key="'status_2'">En ejecución / completados (E08 - E11)</option>
            <option :value="53" :key="'status_3'">Completados (E09 - E10)</option>

          </b-select>
        </b-field>
      </div>

      <!-- select DSO -->
      <div class="w-2/4">
        <div class="w-1/2 float-right">
          <b-field :label="'Detalle DSO'">
          <b-select ref="input"
                    v-model="selectedDso"
                    :placeholder="$t('select_activity')"
                    icon="search"
                    icon-pack="fas"
                    @input="goToDSODashboard(selectedDso)"
                    expanded
            >
              <option v-for="option in activitiesList"
                      :value="option['id']" :key="'activity_'+option['id']"
              >
                {{ option['name'] }}
              </option>
          </b-select>
          </b-field>
        </div>
      </div>

      <!-- <b-tooltip :label="txtTooltipExport || this.$t('export')" position="is-left">
        <div class="rounded-full bg-blue w-8 h-8 flex items-center justify-center text-grey-lightest cursor-pointer" @click="exportReport">
          <b-icon icon="download" pack="fas" size="is-small" v-if="! isLoadingExport" />
          <b-icon
            pack="fas"
            icon="sync-alt"
            custom-class="fa-spin"
            v-if="isLoadingExport"
            >
          </b-icon>
        </div>
      </b-tooltip> -->
  </div>



  <div class="">

    <!-- Panel 1 -->
    <div class="panel" >
      <label class="text-blue font-bold mb-4">Avance del presupuesto</label>
      <!-- layout filtro 1 Redactados -->
      <div class="flex">
        <div class="flex flex-col w-full">
          <span><label class="text-blue italic">PEC del Lote:</label> {{ panel1.pecl }}<br></span>
          <span><label class="text-blue italic">PEC máximo Modificado:</label> {{ panel1.pecl_max }}<br></span>
          <span><label class="text-blue italic">Baja:</label> {{ panel1.baja }}%<br></span>
        </div>
      </div>
      <div class="flex">
        <div class="flex flex-col w-1/3 text-blue font-bold">REDACTADO</div>
        <div class="flex flex-col w-1/3 text-blue font-bold">CERTIFICADO</div>
        <div class="flex flex-col w-1/3 text-blue font-bold">GRADO AVANCE</div>
      </div>
      <div class="flex">

        <!-- REDACTADO -->
        <div class="flex flex-col w-1/3">
          <span><label class="text-blue italic">DSO'S Contrato ALC:</label> {{ panel1.CON_ALC_RED }}<br></span>
          <span><label class="text-blue italic">DSO'S Modificado ALC:</label> {{ panel1.MOD_ALC_RED }}<br></span>
          <span><label class="text-blue italic">DSO'S Modificado SIS:</label> {{ panel1.MOD_SIS_RED }}<br></span>
          <span><label class="text-blue italic">DSO'S Total:</label> {{ panel1.TOTAL_RED }}<br></span>

          <span><label class="text-blue italic">Suma Modificado:</label> {{ panel1.SUM_MOD_RED }}<br></span>
          <span><label class="text-blue italic">Control Modificado:</label> {{ panel1.CON_MOD_RED }} %<br></span>

          <span><label class="text-blue italic">HITO Redacción DSO'S:</label> {{ panel1.HITO_RED }} %<br></span>
          <span><label class="text-blue italic">HITO Ejecución Obras:</label> {{ panel1.HITO_EJE }} %<br></span>
        </div>

        <!-- CERTIFICADO -->
        <div class="flex flex-col w-1/3">
          <span><label class="text-blue italic">DSO'S Contrato ALC:</label> {{ panel1.CON_ALC_CER }}<br></span>
          <span><label class="text-blue italic">DSO'S Modificado ALC:</label> {{ panel1.MOD_ALC_CER }}<br></span>
          <span><label class="text-blue italic">DSO'S Modificado SIS:</label> {{ panel1.MOD_SIS_CER }}<br></span>
          <span><label class="text-blue italic">DSO'S Total:</label> {{ panel1.TOTAL_CER }}<br></span>

          <span><label class="text-blue italic">Suma Modificado:</label> {{ panel1.SUM_MOD_CER }}<br></span>
          <span><label class="text-blue italic">Control Modificado:</label> {{ panel1.CON_MOD_CER }} %<br></span>
        </div>

        <!-- GRADO AVANCE -->
        <div class="flex flex-col w-1/3">
          <span><label class="text-blue italic">DSO'S Contrato ALC:</label> {{ panel1.CON_ALC_GRA }} %<br></span>
          <span><label class="text-blue italic">DSO'S Modificado ALC:</label> {{ panel1.MOD_ALC_GRA }} %<br></span>
          <span><label class="text-blue italic">DSO'S Modificado SIS:</label> {{ panel1.MOD_SIS_GRA }} %<br></span>
          <span><label class="text-blue italic">DSO'S Total:</label> {{ panel1.TOTAL_GRA }} %<br></span>

          <span><label class="text-blue italic">Suma Modificado:</label> {{ panel1.SUM_MOD_GRA }} %<br></span>
        </div>
      </div>

    </div>

    <!-- Panel 2 -->
    <div class="panel w-full mt-8">
      <div class="flex flex-row w-full text-blue font-bold">Comparación Redactado vs Planificación Inicial Acreditado vs Mínimo a Ejecutar</div>
      <div class="flex flex-row w-full"><line-chart :data="panel2" :suffix="'€'" /></div>
    </div>

    <!-- Panel 3 -->
    <div class="panel w-full mt-8">
      <div class="flex flex-row w-full text-blue font-bold">Comparación Ejecutado vs Planificación Inicial Acreditado vs Mínimo a Ejecutar</div>
      <div class="flex flex-row w-full"><line-chart :data="panel3" :suffix="'€'" /></div>
    </div>

    <!-- Panel 4 -->
    <div class="panel w-full mt-8">
      <div class="flex flex-row w-full text-blue font-bold">Avance Acreditado por DSO</div>
      <div class="flex flex-row w-full"><column-chart :data="panel4" :suffix="'€'" :colors="['#284ec0', '#fd8607', '#d12411']"/></div>
    </div>

    <!-- Panel 5 -->
    <div class="panel w-full mt-8">
      <div class="flex flex-row w-full text-blue font-bold">% Avance Acreditado por DSO</div>
      <div class="flex flex-row w-full"><column-chart :data="panel5" :suffix="'%'" :colors="['#d12411']"/></div>
    </div>

    <div class="flex flex-row">
      <!-- Panel 6 -->
      <div class="panel flex flex-col w-1/2">
        <label class="text-blue font-bold">Ml colectores certificados por diametro</label>
        <pie-chart suffix="" :data="panel6"/>

        <table class="mx-5 mt-4">
          <tbody>
            <tr class="panel" v-for="(line, key, index) in panel6" :key="'6_'+index">
              <td align="center" class="justify-start py-2 w-2/12 bg-grey-lightest">
                <div v-if="piechartColors[index]" :style="'width: 20px; border-radius: 1em; height: 20px; background:' + piechartColors[index]"></div>
              </td>
              <td align="center" class="justify-start py-2 w-5/12 bg-indigo-lightest">
                <span class="text-sm font-semibold text-blue">{{ key }}</span>
              </td>
              <td align="center" class="justify-start py-2 w-5/12 bg-grey-lightest">
                <span class="text-sm font-semibold">{{ line }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Panel 7 -->
      <div class="panel flex flex-col w-1/2 ml-2">
        <label class="text-blue font-bold">Materiales</label>
        <pie-chart suffix="" :data="panel7" />

        <table class="mx-5 mt-4">
          <tbody>
            <tr class="panel" v-for="(line, key, index) in panel7" :key="'7_'+index">
              <td align="center" class="justify-start py-2 w-2/12 bg-grey-lightest">
                <div v-if="piechartColors[index]" :style="'width: 20px; border-radius: 1em; height: 20px; background:' + piechartColors[index]"></div>
              </td>
              <td align="center" class="justify-start py-2 w-5/12 bg-indigo-lightest">
                <span class="text-sm font-semibold text-blue">{{ key }}</span>
              </td>
              <td align="center" class="justify-start py-2 w-5/12 bg-grey-lightest">
                <span class="text-sm font-semibold">{{ line }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Panel 8 -->
    <div class="w-full mt-8">
      <div class="flex flex-row w-full text-blue font-bold">ml de colector por DSO</div>
      <div class="flex flex-row w-full"><column-chart :suffix="'ml'" :data="panel8" /></div>
    </div>

    <!-- Panel 9 -->
    <div class="w-full mt-8">
      <div class="flex flex-row w-full text-blue font-bold">% de avance ml de colector por DSO</div>
      <div class="flex flex-row w-full"><column-chart :min="0" :max="100" :suffix="'%'" :data="panel9" :colors="['#d12411']" /></div>
    </div>

  </div>
</div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import VueChartkick from 'vue-chartkick'
import Chart from 'chart.js'
import moment from 'moment'

Vue.use(VueChartkick, {adapter: Chart})
Vue.use(moment)

export default {
  middleware: 'auth',
  title: 'project_home_page_title',
  subtitle: 'project_home_page_subtitle',
  menuOption: '2-1',

  components: {
    ScaleLoader,
  },

  data: () => ({
    locationsList: [],
    activitiesList: [],
    selectedDso: null,
    filters: {
      location_id: 0,
      status_id: 51, // para filtrar actividades segun su estado. 51 default
    },
    panel1: {},
    panel2: [],
    panel3: [],
    panel4: [],
    panel5: [],
    panel6: null,
    panel7: null,
    panel8: null,
    panel9: null,
    piechartColors: ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#3b3eac', '#0099c6', '#dd4477', '#66aa00', '#b82e2e']
  }),

  computed: {
    ...mapGetters({
      project: 'app/project',
      user: 'auth/user',
    }),
  },

  mounted() {

    if (this.project == null) {

      this.$router.push('/projects');

    } else {

      this.getLocationsList();
      this.getPanelsInfo();

      let pageInfo = {
        title: this.project.name,
        subtitle: this.$t('project_home_page_subtitle'),
        menuOption: '2-1',
      }

      this.$store.dispatch('app/updatePageInfo', {info: pageInfo})
    }
  },

  methods: {


    async getLocationsList() {
      let url = '/api/v2/locations?p=' + this.project.id;
      const {data} = await axios.get(url);
      if (data && data.success) {
        this.locationsList = data.locations
      } else {
        this.$notify.error('error_loading_locations')
      }
    },


    async getActivitiesList() {
      let url = '/api/v2/project/' + this.project.id + '/dashboard/activities-list';
      const {data} = await axios.get(url, {params: this.filters});
      if (data && data.success) {
        this.activitiesList = data.data
      } else {
        this.$notify.error('error_loading_activities')
      }
    },


    async getPanelsInfo() {
      this.getPanel1();
      this.getPanel23();
      await this.getActivitiesList(); // esperar, asi carga en cache lista actividades usado por siguientes llamadas
      this.getPanel45();
      this.getPanel67();
      this.getPanel89();
    },


    async getPanel1() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/saneapanel1';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel1 = response.data.data
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel1")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    async getPanel23() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/saneapanel23';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel2 = response.data.data.panel2
          self.panel3 = response.data.data.panel3
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel23")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    async getPanel45() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/saneapanel45';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel4 = response.data.data.panel4
          self.panel5 = response.data.data.panel5
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel45")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    async getPanel67() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/saneapanel67';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel6 = response.data.data.panel6
          self.panel7 = response.data.data.panel7
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel67")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    async getPanel89() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/saneapanel89';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel8 = response.data.data.panel8
          self.panel9 = response.data.data.panel9
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel89")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },


    goToDSODashboard(selectedDso) {
      this.$router.push({ name: 'project.dashboard.sanea.dso', params: { activity_id: selectedDso }})
    },


  },

};
</script>

<style scoped>
.column-scroll {
  width: 50%;
  height: calc(100vh - 150px);
  overflow-x: hidden;
  overflow-y: auto;
}
.column-scroll:not(:last-child) {
  margin-right: 0.5rem;
}
.column-scroll:last-child {
  margin-left: 0.5rem;
}
</style>
