<template>
<div class="page-with-top-bar" style="padding:0!important">
  <div class="flex flex-row justify-end px-4 top-bar">
    <div class="flex flex-col w-1/2 hidden sm:block">
      <p class="m-0 text-sm font-bold font-raleway text-grey-dark"><span>Estado: </span> <span>{{ issue && issue.is_open ? 'Abierta' : 'Cerrada' }}</span></p>
      <p class="m-0 text-sm font-bold font-raleway text-grey-dark"><span>Creador: </span> <span>{{ issue && issue.creator ? issue.creator.fullname : '-' }}</span></p>
      <p class="m-0 text-sm font-bold font-raleway text-grey-dark"><span>Responsable: </span><span>{{ issue && issue.manager ? issue.manager.fullname : '-' }}</span></p>
      <p class="m-0 text-sm font-bold font-raleway text-grey-dark" v-if="issue && issue.estimated_resolution_date"><span>{{ $t('estimated_resolution_date') }}: </span><span>{{ moment(issue.estimated_resolution_date).format('DD-MM-YYYY') }}</span></p>
    </div>
    <div class="flex w-full items-center justify-end text-white">

      <div class="p-2 text-gray-900 bg-white rounded-lg shadow-lg- font-medium capitalize text-right">
         <!-- Ir a la subactividad -->
         <span v-if="subactivityId"
          class="px-1 mt-2 cursor-pointer hover:text-gray-700 lg:hover:bg-epc-50- xl:hover:bg-gray-200 text-sm xl:rounded xl:px-2 xl:py-2 lg:hover:text-white-"
          @click="$router.push({'name': 'subactivity.home', params: {ubactivity_id: subactivityId, id: activityId}})">
          <b-tooltip :label="$t('back_to_subactivity')" position="is-top">
            <i class="fas fa-arrow-left p-2 text-white- bg-gray-200 bg-epc-900- rounded-full lg:w-8">
            </i>
            <span class="mx-1 hidden xl:inline">{{ $t('back_to_subactivity') }}</span>
          </b-tooltip>
        </span>
         <!-- Ir a a la actividad principal -->
         <span v-else
          class="px-1 mt-2 cursor-pointer hover:text-gray-700 lg:hover:bg-epc-50- xl:hover:bg-gray-200 text-sm xl:rounded xl:px-2 xl:py-2 lg:hover:text-white-"
          @click="$router.push({'name': 'activity.home', params: {id: activityId }})">
          <b-tooltip :label="$t('back_to_main_activity')" position="is-top">
            <i class="fas fa-arrow-left p-2 text-white- bg-gray-200 bg-epc-900- rounded-full lg:w-8">
            </i>
            <span class="mx-1 hidden xl:inline">{{ $t('back_to_main_activity') }}</span>
          </b-tooltip>
        </span>
      </div>

        <!-- Ir a a la actividad principal -->
        <!-- <button v-if="subactivityId"
          @click="$router.push({'name': 'subactivity.home', params: {subactivity_id: subactivityId, id: activityId }})"
          class="flex items-center justify-center px-4 py-2 mr-8 border rounded bg-green hover:bg-green-dark text-grey-lightest border-green-dark hover:border-green-darker"
        >
          <b-icon pack="fas" icon="arrow-left" size="is-small" />
          <span class="ml-2 font-medium">{{ $t('back_to_subactivity') }}</span>
        </button>
        <button v-else
          @click="$router.push({'name': 'activity.home', params: {id: activityId }})"
          class="flex items-center justify-center px-4 py-2 mr-8 border rounded bg-green hover:bg-green-dark text-grey-lightest border-green-dark hover:border-green-darker"
        >
          <b-icon pack="fas" icon="arrow-left" size="is-small" />
          <span class="ml-2 font-medium">{{ $t('back_to_main_activity') }}</span>
        </button> -->

        <!-- Abrir Chat -->
        <!-- <v-button-icon-round
          @click="openChat"
          icon="comment"
          tooltip="open_chat"
          tooltip-position="bottom"
          class="mr-1"
        /> chat desactivado -->
        <!-- Cambiar Estado -->
        <v-button-icon-round
            v-if="issue.is_manager"
            @click="changeStatus"
            icon="undo-alt"
            tooltip="change_status"
            tooltip-position="bottom"
            class="mr-1"
            :color="statusColor"
        />
        <!-- Generar Informe -->
        <v-button-icon-round
            v-if="project.can_admin || issue.is_manager || issue.is_creator"
            icon="file-alt"
            tooltip="generate_report"
            tooltip-position="bottom"
            @click="generateReport"
            class="mr-1"
        />
        <!-- Agregar participantes a la actividad -->
        <!-- <v-button-icon-round
          icon="users"
          tooltip="add_participants"
          tooltip-position="bottom"
          class="mr-1"
        /> -->
        <!-- Invitar usuarios a la actividad -->
        <!-- <v-button-icon-round
          icon="address-book"
          tooltip="add_users"
          tooltip-position="bottom"
          class="mr-1"
        /> -->
        <!-- Editar -->
        <v-button-icon-round
            icon="pencil-alt"
            tooltip="edit"
            tooltip-position="bottom"
            @click="onClickEdit"
            class="mr-1"
            v-if="project.can_admin || issue.is_creator || issue.is_manager"
        />
        <!-- Eliminar -->
        <v-button-icon-round
            color="red"
            icon="trash-alt"
            tooltip="remove"
            tooltip-position="bottom"
            @click="onClickDelete"
            v-if="project.can_admin || issue.is_creator || issue.is_project_manager"
        />
        <!-- Info incidencia -->
        <v-button-icon-round
          icon="arrow-right"
          color="grey-darkest"
          tooltip="issue_info"
          tooltip-position="left"
          class="ml-6"
          @click="showSide"
        />
    </div>
  </div>


  <!-- Contenido -->
  <div class="flex px-4 pt-4 below-top-bar bg-grey-lightest flex-col lg:flex-row">
    <div class="w-full lg:hidden containt-tabs-menu">
      <b-tabs ref="refTabsMenu"
        type="is-toggle-rounded"
        v-model="tabSelectedMenu"
        expanded
        class="sticky top-0 mb-3"
        @input="_HandleChangeTab">

        <b-tab-item label="Comentarios"
          value="tab_menu_comments"
          icon="comments-alt"
          icon-pack="fas" class="hidden"></b-tab-item>

        <b-tab-item
          label="Modulos"
          value="tab_menu_modulos"
          icon="cogs"
          icon-pack="fas"></b-tab-item>

      </b-tabs>
    </div>

    <div class="w-full flex">
      <!-- Columna IZQUIERDA -->
      <div class="w-full lg:w-2/5 lg:mr-2" v-show="showPanel == 'left' || showPanel == 'both'">
        <div class="w-full mb-24 column-scroll">
          <entity-description
            class="pr-4" v-if="issue.description"
            :title="$t('issue_description')" :content="issue.description"
          />
          <entity-description
            class="pr-4" v-if="issue.steps_to_implement"
            :title="$t('issue_steps_to_implement')" :content="issue.steps_to_implement"
          />
          <entity-description
            class="pr-4" v-if="issue.steps_implemented"
            :title="$t('issue_steps_implemented')" :content="issue.steps_implemented"
          />
          <entity-comments
            v-if="issue.id !== 0"
            :entity-id="issue.id"
            entity-type="issue"
            :can-add="issue.is_creator || issue.is_manager || issue.is_participant || issue.is_collaborator"
            class="pr-4 pb-32"
          />
        </div>
      </div>

      <!-- DERECHA -->
      <div class="w-full lg:w-3/5 lg:ml-2" v-show="showPanel == 'right' || showPanel == 'both'">
        <div class="w-full mb-24 column-scroll">

          <div class="panel" v-if="issue.drawing">
            <div class="text-lg font-thin uppercase text-blue-light">Localización en plano</div>
            <canvas id="canvas" width="720" height="500" style="background-color: #f5f5f5"></canvas>
          </div>

          <div v-if="issue.pictogram_id" class="panel">

          </div>



          <div class="panel last:mb">
            <div class="text-lg font-thin uppercase text-blue-light">{{ $t('geolocation') }}</div>
            <b v-if="issue.location" class="text-gray text-sm rounded-xl inline-block mb-2 py-0.5 px-2 border-2 border-gray-400 bg-gray-200">{{ issue.location.name }}</b>
            <input type="hidden" name="address_id" id="address_id" value="">
            <googlemaps ref="mapRef"
              name="issue-location"
              :datamaps="datamaps"
              :draggable="draggable"
              :newPlace="newPlace"
              inputAutocomplete="address_id"/>
          </div>

          <!-- Documentos -->
          <div v-if="! isLoading" class="last:mb">
            <entity-documents
              :entity-id="issue.id"
              entity-type="issue"
              :can-add="issue.is_creator || issue.is_manager || issue.is_participant || issue.is_collaborator"
              class="my-4"
            />

            <!-- <entity-chat
                v-if="issue.id !== 0"
                :entity-id="entityId"
                entity-type="issue"
                :key="'issue.' + issue.id"
                :auto-open="withChat"
            /> chat desactivado -->
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="overflow-y-scroll slider bg-grey-darkest" :class="sliderClass" style="z-index: 70;">
    <!-- Info actividad -->
    <div class="info-entity">
        <div class="row-hide">
          <v-button-icon-round
            icon="arrow-right"
            color="blue"
            tooltip-position="left"
            class="float-right ml-6"
            @click="showSide"/>
        </div>
        <div class="text-xs text-grey">{{$t('issue_label')}}</div>
        <div class="text-sm font-semibold text-white">{{issue.name}}</div>
        <div class="float-left main-info">
          <div class="text-xs text-grey">{{$t('manager')}}</div>
          <div class="flex items-center info-data">
            <div class="w-8 h-8 mr-2 overflow-hidden border rounded-full border-grey" v-if="issue.manager">
                <img :src="issue.manager.avatar" :alt="issue.manager.fullname">
            </div>
            <div>
              <div class="text-sm text-blue-lighter" v-if="issue.manager && issue.manager.fullname">
                  {{ issue.manager.fullname }}
              </div>
              <div class="text-xs text-white" v-if="issue.manager && issue.manager.department">
                {{ issue.manager.department.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="float-left main-info">
            <div class="text-xs text-grey">{{$t('created_at')}}</div>
            <div class="text-xs text-white info-data">{{issue.created_at}}</div>
            <div class="text-xs text-grey">{{$t('last_update')}}</div>
            <div class="text-xs text-white info-data">{{issue.updated_at}}</div>
        </div>
        <div class="more-info">
           <div class="text-xs text-grey">{{$t('issue_description')}}</div>
           <div class="text-xs text-white bg-white p-2 rounded-sm info-data" v-html="issue.description ? issue.description : 'Sin descripción'"></div>
        </div>
        <div class="more-info">
           <div class="text-xs text-grey">{{$t('users_participants')}}</div>
           <ul id="log-participants">
            <li v-for="user in issue.participants" :key="user.user_id" >
              <div class="flex items-center info-data" v-if="user['avatar'] && user['fullname']">
                <div class="w-8 h-8 mr-2 overflow-hidden border rounded-full border-grey">
                    <img :src="user['avatar']" :alt="user['fullname']">
                </div>
                <div>
                    <div class="text-sm text-blue-lighter">
                    {{ user['fullname'] }}
                    </div>
                    <div class="text-xs text-grey" v-if="user['department']">
                      {{ user['department']['name'] }}
                    </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="more-info">
           <div class="text-xs text-grey">{{$t('log')}}</div>
           <ul id="log-actions">
            <li v-for="action of logActions" class="p-2">

              <div class="float-left w-full">

                  <div class="float-left w-8 h-8 overflow-hidden rounded-full">
                    <img :src="action.author.avatar" :alt="action.author.fullname">
                  </div>

                  <div class="float-left px-2 py-1 ml-3 text-xs text-white uppercase rounded-full" :class="action.entity_type_color">{{ action.entity_type }}</div>
                  <div class="float-left px-2 py-1 ml-3 text-xs text-white uppercase rounded-full" :class="action.action_type_color">{{ action.action_type }}</div>

              </div>

              <div class="float-left w-full mb-4 text-grey">
                <div class="text-xs text-left" style="margin-left:50px;">
                    {{ action.created_at }}
                </div>
              </div>

            </li>
          </ul>
        </div>

    </div>
  </div>
  <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
</div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from 'vuex'
import EventBus from '~/plugins/bus'
import axios from 'axios'
import at from "~/store/modules/at";
import entityDescription from '·/components/entity-description'
import entityComments from '·/components/entity-comments'
import entityDocuments from '·/components/entity-documents'
import moment from 'moment'
import Googlemaps from '~/components/GoogleMaps.vue'
import PdfCanvas from "~/plugins/pdfCanvas";

Vue.use(moment)


export default {
  middleware: 'auth',
  title: '',
  subtitle: 'issue_home_page_subtitle',
  menuOption: '2-8',

  components: {
    entityDescription,
    entityComments,
    entityDocuments,
    Googlemaps
  },

  data: () => ({
    issue: {
      id: 0,
      name: ''
    },
    atValues: [],
    sliderShown: false,
    sliderClass: '',
    activityId: undefined,
    subactivityId: undefined,
    logActions: undefined,
    isLoading: true,
    isActivityManager: false,
    // issueChatComponent: null,
    // issueDocumentsComponent: null,
    statusColor: 'blue',
    //mapa
    datamaps: [],
    draggable: false,
    newPlace: '',

    showPanelRight: true,
    showPanelLeft: true,

    tabSelectedMenu: -1 // tab seleccionado por defecto
  }),

  watch: {
    '$route.params.issue_id': function() {
      // Reload params
      this.loadParams()
    }
  },

  computed: {
    withChat() {
      return this.$route.params.with_chat
    },
    entityId: {
      get: function() {
        return parseInt(this.$route.params.issue_id)
      },
      set: function(newVal) {
        this.entityId = newVal
      }
    },
    ...mapGetters({
      company: 'app/company',
      project: 'app/project',
      mobile: 'device/mobile'
    }),

    showPanel() {
      if( this.mobile.on_mobile ) {

        if( this.showPanelLeft && this.showPanelRight ) {
          this.tabSelectedMenu = 1
          this.showPanelLeft = false
        }

        return this.showPanelRight ? 'right' : 'left'
      }

      this.showPanelRight = true
      this.showPanelLeft = true
      return 'both'
    }
  },

  created() {
    this.loadParams();

    EventBus.$on('update:entityId', function(entityId) {
        this.entityId = entityId
    })
  },

  beforeDestroy(){
    EventBus.$off("update:entityId");
  },

  methods: {
    loadParams() {
      this.activityId = this.$route.params.activity_id
      this.subactivityId = this.$route.params.subactivity_id ? this.$route.params.subactivity_id : this.subactivityId
      this.loadIssueData(this.$route.params.issue_id)
      this.loadIssueUsers(this.$route.params.issue_id)
    },

    async loadIssueUsers(issueId){

      // Referenciamos
      var self = this;

      // Nos traemos usuarios de la incidencia para menciones
      const { data } = await axios.get(
        "/api/v2/issue/" + issueId + "/chat/users"
      );

      // Si users los mete en la store
      if (data && data.success) {

        data.data.forEach(function(element) {
          self.atValues.push(element)
        });

      }
      at.commit('increment', self.atValues)

    },

    loadIssueData(issueId) {

      var self = this

      axios.get('/api/v2/issue/' + issueId + '?h=1&with_doc=1')
        .then(function(response) {
          if (response.data && response.data.success) {

            let data = response.data
            console.log('response: ', data)

            // Cargamos los datos de la incidencia
            self.issue = data.data
            self.isActivityManager = data.data.is_activity_manager;

            // Seteamos el proyecto y las tags
            let project = self.issue.project
            self.$store.dispatch('app/setProject', { project })
            let tags = project.tags
            self.$store.dispatch("app/setTags", { tags });

            // Cargamos los componentes que son dependientes de la información de esta llamada
            // self.issueChatComponent = "entity-chat"
            // self.issueDocumentsComponent = "entity-documents"


            self.issue.created_at = moment(self.issue.created_at).format('DD/MM/YYYY H:mm:ss')
            self.issue.updated_at = moment(self.issue.updated_at).format('DD/MM/YYYY H:mm:ss')

            // Asignamos la id de la actividad padre
            self.activityId = data.data.activity_id;

            self.statusColor = self.issue.is_open ? 'blue' : 'grey'

            // Actualizamos el título de la página
            let pageInfo = {
              title: self.issue.name,
              subtitle: self.$t('issue_home_page_subtitle'),
              menuOption: self.$options.menuOption
            }

            // drawing
            if (self.issue.drawing) {
              self.loadDrawing(self.issue.drawing)
            }

            //map
            self.datamaps.push(self.issue.location)
            // al momento de emitir este evento, el componente GoogleMaps no está montado y el mapa se queda en gris
            // EventBus.$emit('load-locations', self.datamaps);
            // Parcheado con settimeout para que funcione. Arreglar.... #TODO
            self.$nextTick(() => {
              setTimeout(() => {
                EventBus.$emit('load-locations', self.datamaps);
              }, 2000);
            });

            self.$store.dispatch('app/updatePageInfo', {info: pageInfo})
            self.isLoading = false

          } else if(response.data && response.data.deny){
            self.$notify.error('no_access_permissions')
            self.$router.push({ name: 'project.home', params: {} })
          } else {
            self.$notify.error('error_loading_issue_data')
            self.$router.push({ name: 'project.home', params: {} })
          }
        })
        .catch(error => {
          console.log(error.response);
          self.$notify.error('error_loading_issue_data')
          self.$router.push({ name: 'project.home', params: {} })
        });
    },

    // Botones
    onClickEdit() {

      // Router push
      this.$router.push({ name: 'issue.edit', params: { issue_id: this.issue.id } })
    },

    onClickDelete() {
      this.$dialog.confirm({
        title: this.$t('delete_issue'),
        message: this.$t('delete_issue_confirm_text', [this.issue.name]),
        confirmText: this.$t('delete_issue'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          let url = '/api/v2/issue/' + this.issue.id + '/delete'
          const { data } = await axios.post(url)
          if (data && data.success) {
            this.$notify.success('success_deleting_issue')
            this.$router.push({ name: 'activity.home', activity_id: this.activityId })
          } else {
            this.$notify.error(data.error)
          }
        }
      })
    },

    generateReport() {
      this.$dialog.confirm({
        title: this.$t('generate_report'),
        message: this.$t('confirm_generate_report', [this.issue.name]),
        confirmText: this.$t('generate_report'),
        type: 'is-success',
        hasIcon: true,
        onConfirm: async () => {
          let url = '/api/v2/issue/' + this.issue.id + '/report/' + this.company.alias
          const { data } = await axios.get(url)

          if (data) {
              window.open(data.data);
          } else {
            this.$notify.error('error_download_report')
          }

        }
      })
    },

    async showSide() {
      this.sliderClass = this.sliderShown ? 'slide-out' : 'slide-in'
      this.sliderShown = !this.sliderShown
      //Obtenemos el log de la actividad
      if(!this.logActions){
        const { data } = await axios.get('/api/v2/issue/' + this.issue.id + '/log?h=1')
        if (data && data.success) {
          this.logActions = data.data
        }

      }
    },
    openChat(){
      EventBus.$emit('open-chat', 'issue.' + this.issue.id.toString())
    },

    async changeStatus(){
        let self = this
        let newStatus = self.issue.is_open ? 0 : 1
        await axios.post('/api/v2/issue/' + this.issue.id + '/status', { status: newStatus })
          .then(function(response) {
            if (response.data && response.data.success) {
              self.issue.is_open = newStatus
              self.statusColor = self.issue.is_open ? 'blue' : 'grey'
              self.$notify.success('success_updating_status_issue')
            } else {
              self.$notify.error('error_updating_status_issue')
            }
          })
          .catch(error => {
            console.log(error.response);
            self.$notify.error('error_updating_status_issue')
          });
    },


    // Muestra en el canvas la primera pagina del pdf del plano para mostrar ubicacion de incidencia
    async loadDrawing(drawing) {
      // Convertimos la pagina del plano a un png
      let imgSrc = await PdfCanvas.pdfToPng(drawing, {scale: 0.6})

      let canvas = document.getElementById('canvas')
      let context = canvas.getContext('2d');

      // La imagen png generada a partir del pdf
      let img = new Image();
      img.src = imgSrc

      // Ahora la dibujamos dentro del canvas
      img.onload = () => {

        context.drawImage(img, 0, 0);

        // una vez cargada la imagen "de fondo", pintamos encima cada marker
        this.issue.metadata?.markers?.forEach(marker => {

          const imgMarker = new Image();
          imgMarker.src = this.asset(PdfCanvas.markerImg.src)

          imgMarker.onload = () => {
            context.drawImage(imgMarker,
              ((img.width * marker.document_x) / 100) - PdfCanvas.markerImg.width , // coordenada x - anchura imagen
              ((img.height * marker.document_y) / 100) - PdfCanvas.markerImg.height // coordenada y - altura imagen
            );
          };
        })
      }
    },


    // Obsoleta, de cuando usabamos solo un marker. Se mostraba solo la porcion de documento alrededor del marker que cabia dentro del div
    async loadDrawingOneMarkerOLD(drawing) {

      // Ubicacion de la incidencia dentro del plano
      let location = {
          id: this.issue.id,
          title: this.issue.code + ' ' + this.issue.name,
          x: this.issue.metadata.document_x,
          y: this.issue.metadata.document_y
        }

      // Convertimos la pagina del plano a un png
      let imgSrc = await PdfCanvas.pdfToPng(drawing, {})

      let canvas = document.getElementById('canvas')
      let context = canvas.getContext('2d');

      // Cuánto hay que desplazar la imagen del plano para que salga centrado en el canvas
      let offsetX = 0
      let offsetY = 0
      // el centro del canvas (si incidencia menor que estos valores, no centrar imagen para que no se vea fondo blanco)
      let minX = canvas.width / 2;
      let minY = canvas.height / 2;

      // Será la imagen del marker de la ubicacion
      const imgMarker = new Image();

      // La imagen png generada a partir del pdf
      let img = new Image();
      img.src = imgSrc

      // Ahora la centramos dentro del canvas
      img.onload = () => {
        // window.open(img.src)
        // posicion de la issue en pixeles segun dimensiones imagen generada a partir de pdf
        offsetX = (img.width * location.x) / 100
        offsetY = (img.height * location.y) / 100

        offsetX = Math.max(offsetX - minX, 0);
        offsetY = Math.max(offsetY - minY, 0);
        // como el centrado será hacia la izquierda y/o hacia arriba, el desplazamiento será negativo
        context.drawImage(img, -1 * offsetX, -1 * offsetY);

        // una vez cargada la imagen "de fondo", pintamos encima el marker
        imgMarker.src = this.asset(PdfCanvas.markerImg.src)
      }



      // Y finalmente dibujamos un marker en el punto de la incidencia
      imgMarker.onload = () => {
        let markerOffsetX = 0
        let markerOffsetY = 0
        // Si el offset es 0, no hay desplazamiento de imagen (el marker está entre 0 y la mitad). Por tanto pintar el marker desplazado del centro
        if (offsetX == 0) {
          markerOffsetX -= (img.width * location.x) / 100 - minX // cuanto desplazado está de la mitad
        }
        if (offsetY == 0) {
          markerOffsetY -= (img.height * location.y) / 100 - minY
        }
        context.drawImage(
          imgMarker,
          (canvas.width / 2) - markerOffsetX - PdfCanvas.markerImg.width, // desde la mitad, resta el desplazamiento y resta la imagen
          (canvas.height / 2) - markerOffsetY - PdfCanvas.markerImg.height
        );
      };


    },

    _HandleChangeTab(value) {
      this.showPanelRight = value === 1
      this.showPanelLeft = value === 0
    },

  },

};
</script>

<style scoped>
.containt-tabs-menu::v-deep .b-tabs section {
  display: none;
}
.column-scroll {
  /* width: 50%; */
  height: calc(100vh - 150px);
  overflow-x: hidden;
  overflow-y: auto;
}
.column-scroll:not(:last-child) {
  margin-right: 0.5rem;
  border-color: #adc2ea;
  border-right-width: 1px;
}
/* .column-scroll:last-child {
  margin-left: 0.5rem;
} */
.slider {
  position: fixed;
  top: 61px;
  right: 0;
  bottom: 0;
  width: 360px;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}
.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}
.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}
@keyframes slide-in {
  100% { transform: translateX(0%); }
}
@-webkit-keyframes slide-in {
  100% { -webkit-transform: translateX(0%); }
}
@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(100%); }
}
@-webkit-keyframes slide-out {
  0% { -webkit-transform: translateX(0%); }
  100% { -webkit-transform: translateX(100%); }
}

.info-entity{padding: 20px;}
.info-entity .row-hide{float: right; width: 100%;}
.info-entity .main-info{padding-right: 12px; margin-top: 20px; width: 50%;}
.info-entity .main-info~.main-info{padding-right: 0;}
.info-entity .info-data{margin-top: 10px; margin-bottom: 10px;}
.info-entity .more-info{display: inline-block; width: 100%; margin-top: 20px;}

.timeline-box {
    position: relative;
    margin-top: 20px;
}
.timeline-box:before {
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    margin-left: 0;
    content: '';
    border-left: 1px solid transparent;
    border-color: #ffffff;
}
.timeline-box .tl-row {
    position: relative;
    padding: 10px 0;
}
.timeline-box .tl-row .tl-item {
    width: 50%;
}
.timeline-box .popover {
    min-width: 50px;
}
.timeline-box .tl-row .tl-item .popover {
    position: relative;
    display: block;
    margin: 0;
    box-shadow: 0 0 0 transparent;
}
.timeline-box .tl-row .tl-item .popover.left {
    float: right;
    margin-right: 35px;
}
.timeline-box .tl-row .tl-item.float-right .popover.right {
    margin-left: 35px;
    background: white;
}
.timeline-box .tl-row .tl-item .tl-panel {
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    margin-top: -8px;
    margin-left: 23px;
    text-align: left;
}
.timeline-box .tl-row .tl-item.float-right .tl-panel {
    right: 50%;
    left: auto;
    margin-right: 23px;
    margin-left: 0;
    text-align: right;
}
.timeline-box .tl-row .tl-item .tl-title {
    font-size: 15px;
    font-weight: bold;
    margin: 0 0 10px;
}
.timeline-box .tl-row .tl-item .tl-label {
    display: inline-block;
    margin-bottom: 10px;
    padding: 5px 8px;
}
.timeline-box .tl-row .tl-item .tl-content {
    font-size: 13px;
    margin: 0 0 10px;
    opacity: .80;
    -moz-opacity: .80;
    filter: alpha(opacity: 80);
}
.timeline-box .tl-row .tl-item .tl-time {
    font-style: italic;
    opacity: .40;
    -moz-opacity: .40;
    filter: alpha(opacity: 40);
}
.timeline-box .tl-row .tl-item .tl-icon,
.timeline-box .tl-row .tl-item .tl-bullet {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 17px;
    height: 17px;
    margin-top: -9px;
    margin-left: -8px;
    border-radius: 100px;
}
.timeline-box .tl-row .tl-item .tl-bullet:before {
    display: block;
    width: 9px;
    height: 9px;
    margin: 4px 0 0 4px;
    content: '';
    border-radius: 100px;
    background: #fff;
}
.timeline-box .tl-row .tl-item .tl-icon {
    font-size: 18px;
    line-height: 34px;
    width: 34px;
    height: 34px;
    margin-top: -18px;
    margin-left: -17px;
    text-align: center;
}
/* Timeline Horizontal */

.timeline-scroll {
    overflow-x: scroll;
    overflow-y: hidden;
}
.timeline-horizontal {
    height: 400px;
    padding: 10px 0;
}
.timeline-horizontal .tl-row {
    float: left;
    width: 400px;
    height: 173px;
}
.timeline-horizontal.timeline-box:before {
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    border: 0;
}
.timeline-horizontal.timeline-box .tl-row .tl-item {
    width: auto;
}
.timeline-horizontal.timeline-box .tl-row .tl-item .tl-panel {
    top: auto;
    bottom: -60px;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: center;
}
.timeline-horizontal.timeline-box .tl-row .tl-item .tl-bullet {
    margin-left: -9px;
}
.timeline-horizontal.timeline-box .tl-row .tl-item .tl-icon,
.timeline-horizontal.timeline-box .tl-row .tl-item .tl-bullet {
    top: auto;
    bottom: -27px;
}
.timeline-horizontal.timeline-box .tl-row .tl-item .popover {
    position: absolute;
    top: auto;
    bottom: 10px;
    width: 100%;
}
.timeline-horizontal.timeline-box .tl-row .tl-item.float-right .tl-panel {
    bottom: 0;
}
.timeline-horizontal.timeline-box .tl-row .tl-item.float-right .popover {
    top: 220px;
    bottom: auto;
}

/* Timeline columns */

.timeline-box-right.timeline-box .tl-row .tl-item,
.timeline-box-left.timeline-box .tl-row .tl-item {
    width: auto;
    float: none !important;
}

.timeline-box-right.timeline-box .tl-row .tl-item .popover,
.timeline-box-left.timeline-box .tl-row .tl-item .popover {
    float: none;
}

.timeline-box-right.timeline-box:before {
    left: auto;
    right: 17px;
}

.timeline-box-right.timeline-box .tl-row {
    padding-right: 20px;
}

.timeline-box-right.timeline-box .tl-row .tl-item .tl-icon,
.timeline-box-right.timeline-box .tl-row .tl-item .tl-bullet {
    left: auto;
    right: 17px;
    margin-left: 0;
    margin-right: -17px;
}

.timeline-box-left.timeline-box:before {
    right: auto;
    left: 17px;
}

.timeline-box-left.timeline-box .tl-row {
    padding-left: 20px;
}

.timeline-box-left.timeline-box .tl-row .tl-item .tl-icon,
.timeline-box-left.timeline-box .tl-row .tl-item .tl-bullet {
    right: auto;
    left: 17px;
    margin-right: 0;
    margin-left: -17px;
}

.popover-content{padding: 12px;}

.popover.right > .arrow:after {
    bottom: -10px;
    left: 1px;
    content: " ";
    border-right-color: #fff;
    border-left-width: 0;
}
.popover.right .arrow:after {
    bottom: -10px;
    left: 1px;
    border-right-color: rgba(0, 0, 0, .2);
    border-left-width: 0;
}

body .popover.right .arrow:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent #ffffff transparent transparent;
    left: -15px !important;
    position: absolute;
    top: 43%;
}

.last\:mb:last-child {
  margin-bottom: 15rem;
}

@media (min-width: 640px) {
    .sm\:block {
        display: block !important;
    }
}
</style>
