<template>
  <div>
    <div class="top-bar flex justify-end px-4">
      <div class="flex items-center text-white">
        <v-button v-if="!this.createNew" icon="list" @click="goToPage('extrafields')" class="mr-4">{{ $t('extrafields')
          }}</v-button>
        <v-button v-if="!this.createNew && !this.isSubactivity && company.isLoteCanal" icon="road"
          @click="goToPage('streets')">{{ $t('streets') }}</v-button>
        <v-button v-if="!this.createNew && company.isLoteCanal" class="ml-2" @click="goToPage('dismissed-pipes')">{{
          $t('deleted_pipes') }}</v-button>
      </div>
    </div>

    <div class="panel">
      <div class="flex flex-col md:flex-row w-full">
        <div class="w-full md:w-1/2 flex flex-col sm:flex-row">
          <div class="w-full md:w-1/2 sm:pr-4">
            <l-form-input ref="activityName" :form="form" field="name" label="activity_name"
              :disabled="!this.isCreator" />
          </div>
          <div class="w-full md:w-1/2 md:pr-4">
            <l-form-select :form="form" field="location_id" :options="locationsList" label="select_location"
              icon="map-marker-alt" :disabled="!this.isCreator" full optionDisplayField="reference_code" />
          </div>
        </div>
        <div class="w-full md:w-1/2 flex flex-col sm:flex-row">
          <div class="w-full md:w-1/3 md:pr-4">
            <v-switch v-model="form.critical_path" ref="critical_path" :label="$t('critical_path')"
              :color="form.critical_path ? 'green' : 'red'" v-on:checked="(value) => form.critical_path = value"
              style="margin-top: 4%; margin-left: 4%" />
          </div>
          <div class="w-full md:w-1/3 md:pr-4">
            <v-switch v-model="form.in_planning" ref="in_planning" :label="$t('in_planning')"
              :color="form.in_planning ? 'green' : 'red'" v-on:checked="(value) => form.in_planning = value"
              style="margin-top: 4%; margin-left: 4%" />
          </div>
          <div class="w-full md:w-1/3 md:pr-4">
            <v-switch v-model="form.show_on_devices" ref="show_on_devices" :label="$t('show_on_devices')"
              :color="form.show_on_devices ? 'green' : 'red'" v-on:checked="(value) => form.show_on_devices = value"
              style="margin-top: 4%; margin-left: 4%"
              :class="{ 'Disableded': form.show_on_devices == 0, 'Activated': form.show_on_devices }" />
          </div>
        </div>
      </div>
      <div class="flex flex-col md:flex-row w-full mt-5">
        <div class="w-full md:w-1/4 md:pr-4">
          <l-form-input v-model="form.planning_code" ref="planning_code" :form="form" field="planning_code"
            label="planning_code" />
        </div>

        <div class="w-full md:w-1/4 md:pr-4">
          <label for="weight" class="label">
            {{ $t('weight') }}
            <!-- Cambio  para que se pueda agregar en activity-->
            <!-- <span class="text-red text-xs font-normal" v-show="!this.isSubactivity">{{ $t('only_subactivities') }}</span> -->
          </label>
          <!-- <l-form-input v-model="form.weight" ref="weight" :form="form" field="weight"
            :disabled="!this.isSubactivity" /> -->
          <l-form-input v-model="form.weight" ref="weight" :form="form" field="weight" />
        </div>

        <div class="w-full md:w-1/4 md:pr-4">
          <label for="activity_status" class="label">
            {{ $t('activity_status') }}
          </label>
          <l-form-select :form="form" field="status_id" :options="statusesList" placeholder="select_status" full
            optionDisplayField="name" />
        </div>

        <div class="w-full md:w-1/4 md:pr-4">
          <label for="activity_type" class="label">
            {{ $t('activity_type') }}
          </label>
          <l-form-select :form="form" field="activity_type_id" :options="typesList" placeholder="activity_type" full
            optionDisplayField="name" />
        </div>
      </div>

      <!-- Fechas Actividad -->
      <div v-if="!this.isSubactivity">
        <div class="flex flex-col md:flex-row w-full mt-6">
          <div class="w-full md:w-1/4 md:pr-4">
            <l-form-double-date :form="form" startField="scheduled_start_date" endField="scheduled_end_date"
              label="scheduled_date" :disabled="!this.createNew ? true : !this.isCreator" />
          </div>
          <div class="w-full md:w-1/4 md:pr-4">
            <l-form-double-date :form="form" startField="current_start_date" endField="current_end_date"
              label="estimated_date" :disabled="this.createNew ? true : !this.isCreator" />
          </div>
          <div class="w-full md:w-1/4 md:pr-4">
            <l-form-double-date :form="form" startField="real_start_date" endField="real_end_date" label="real_date"
              :disabled="false" />
          </div>

          <div class="w-1/4">
            <div class="w-1/2">
              <b-field :label="$t('expected_end_date')">
                <b-input :value="calculateExpectedEndDate" :disabled="true" />
              </b-field>
            </div>
          </div>
        </div>

      </div>
      <!-- Fechas Subactividad -->
      <div class="flex flex-col md:flex-row w-full mt-8" v-else>
        <div class="w-full md:w-1/4 md:pr-4">
          <l-form-double-date :form="form" startField="scheduled_start_date" endField="scheduled_end_date"
            minStartField="activity_parent_current_start_date" maxEndField="activity_parent_current_end_date"
            label="scheduled_date" :disabled="!this.createNew ? true : !this.isCreator" />
        </div>
        <div class="w-full md:w-1/4 md:pr-4">
          <l-form-double-date :form="form" startField="current_start_date" endField="current_end_date"
            minStartField="activity_parent_current_start_date" maxEndField="activity_parent_current_end_date"
            label="estimated_date" :disabled="this.createNew ? true : !this.isCreator" />
        </div>
        <div class="w-full md:w-1/4 md:pr-4">
          <l-form-double-date :form="form" startField="real_start_date" endField="real_end_date" label="real_date"
            :disabled="false" />
        </div>

        <div class="w-full md:w-1/4">
          <div class="w-full md:w-1/2">
            <b-field :label="$t('expected_end_date')">
              <b-input :value="calculateExpectedEndDate" :disabled="true" />
            </b-field>
          </div>
        </div>
      </div>

      <!-- <div class="flex mt-8"> -->

      <!-- </div> -->

      <!-- Descripción -->
      <div class="flex items-center mt-8">
        <l-form-input :form="form" field="description" label="activity_description" input-type="textarea"
          :maxlength="2000" class="w-full" :disabled="!this.isCreator" />
      </div>
      <!-- ETIQUETAS -->
      <div class="w-full items-center">
        <span class="label">{{ $t('activity_tags') }}</span>
        <model-tags :model-id="isSubactivity ? parseInt(subactivityId) : parseInt(activityId)" model-type="activity"
          :allow-edit="false" :add-from-list="true" :parent-model-id="project.id" parent-model-type="project"
          ref="model_tags" />
      </div>
      <!-- Etiquetas, Primavera -->
      <!--<div class="flex items-center mt-4">-->
      <!--<div class="w-1/3 pr-4">-->
      <!--<l-form-input-->
      <!--:form="form"-->
      <!--field="tags"-->
      <!--label="labels"-->
      <!--:disabled="!this.isCreator"-->
      <!--/>-->
      <!--</div>-->
      <!--<div class="w-1/3 pr-4">-->
      <!--<l-form-input-->
      <!--:form="form"-->
      <!--field="primavera_id"-->
      <!--disabled-->
      <!--label="primavera_id"-->
      <!--/>-->
      <!--</div>-->
      <!--<div class="w-1/3">-->
      <!--<l-form-input-->
      <!--:form="form"-->
      <!--field="primavera_wbs"-->
      <!--disabled-->
      <!--label="primavera_wbs"-->
      <!--/>-->
      <!--</div>-->
      <!--</div>-->
      <!-- Responsable -->
      <div class="flex flex-col sm:flex-row w-full items-start mt-8">
        <userlist ref="manager" title="activity_manager" icon="bullhorn" api-url="/api/v2/activity"
          :parent-entity-id="createNew ? null : this.isSubactivity ? parseInt(this.subactivityId) : parseInt(this.activityId)"
          :users-permission-type="['admin']" :allow-create-users="!createNew" :local-mode="createNew"
          :only-one-user="true" :allowZeroUsers="true" @user-added="managerAdded($event)"
          @user-removed="managerRemoved($event)" class="w-full sm:w-1/2 sm:mr-3"
          :disabled="(!this.isManager && !this.isHeadDepartment) && !createNew" :is-manager="this.isManager"
          :only-internal-users="true" :project-id="this.project.id" :is-subactivity="isSubactivity"
          :activity-id="createNew ? null : this.isSubactivity ? parseInt(this.subactivityId) : parseInt(this.activityId)"
          roleName="activity_admin" />
        <div class="w-full sm:w-1/2 sm:ml-3">
          <b-field v-show="form.department" :label="$t('department')">
            <b-input :value="departmentName" :disabled="true" />
          </b-field>
        </div>
      </div>
      <!-- Participantes y Colaboradores -->
      <div class="flex flex-col sm:flex-row w-full items-start mt-8">
        <userlist ref="participants" title="users_participants" icon="users" api-url="/api/v2/activity"
          :parent-entity-id="createNew ? null : this.isSubactivity ? parseInt(this.subactivityId) : parseInt(this.activityId)"
          :users-permission-type="['participate']" :allow-create-users="false" @user-added="participantAdded($event)"
          :local-mode="createNew" :only-internal-users="true" :project-id="this.project.id"
          :superior-hierarchical-users="hierarchicallySuperiorUsers" class="w-full sm:w-1/2 sm:mr-3"
          roleName="participant" />
        <userlist ref="collaborators" title="users_collaborators" icon="address-book" api-url="/api/v2/activity"
          :parent-entity-id="createNew ? null : this.isSubactivity ? parseInt(this.subactivityId) : parseInt(this.activityId)"
          :users-permission-type="['collaborate']" @user-added="collaboratorAdded($event)" :allowZeroUsers="true"
          :allow-external-users="true" :allow-company-users="false" :allow-create-users="false" :local-mode="createNew"
          :only-external-users="true" :project-id="this.project.id" class="w-full sm:w-1/2 sm:ml-3"
          roleName="collaborator" />
      </div>

      <div class="flex flex-col sm:flex-row w-full items-start mt-8">
        <!-- Visualizadores -->
        <userlist ref="viewers" title="viewers_users" icon="eye" api-url="/api/v2/activity"
          :parent-entity-id="createNew ? null : this.isSubactivity ? parseInt(this.subactivityId) : parseInt(this.activityId)"
          :users-permission-type="['view']" @user-added="activityViewerAdded($event)" :local-mode="createNew"
          :allow-zero-users="true" :only-internal-users="false" :allow-filter-for-role="true"
          :project-id="this.project.id" class="w-full sm:w-1/2 sm:ml-3" roleName="activity_viewer" />
      </div>
    </div>
    <form @submit.prevent="sendActivityData" @keydown="form.onKeydown($event)"
      class="w-full flex flex-col sm:flex-row items-center justify-end mb-8">
      <v-button :disabled="disableSaveButton" type="submit" icon="thumbs-up" icon-pack="far"
        class="sm:mr-4 mb-4 sm:mb-0">{{ $t('save') }}</v-button>
      <v-button color="grey" icon="undo-alt" @click="$router.go(-1)">{{ $t('cancel') }}</v-button>
    </form>
  </div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import { mapGetters } from 'vuex'
import Form from 'vform'
import Userlist from '~/components/UserBox/UserList'
import moment from 'moment'
import EventBus from '~/plugins/bus'
import ModelTags from "../../components/model-tags";

Vue.use(moment)

export default {
  middleware: 'auth',
  title: 'activity_edit_page_title',
  subtitle: 'activity_edit_page_subtitle',
  menuOption: '2-2',

  components: {
    Userlist,
    ModelTags
  },

  data: () => ({
    createNew: false,
    activityId: null,
    subactivityId: null,
    isSubactivity: false,
    isCreator: true,
    isManager: false,
    isHeadDepartment: false,
    form: new Form({
      activity_id: null,
      subactivity_id: null,
      project_id: null,
      name: '',
      location_id: '',
      scheduled_start_date: null,
      scheduled_end_date: null,
      current_start_date: null,
      current_end_date: null,
      real_start_date: null,
      real_end_date: null,
      expected_end_date: null,
      activity_parent_scheduled_start_date: null,
      activity_parent_scheduled_end_date: null,
      activity_parent_current_start_date: null,
      activity_parent_current_end_date: null,
      activity_parent_real_start_date: null,
      activity_parent_real_end_date: null,
      moment_scheduled_start_date: null,
      moment_scheduled_end_date: null,
      moment_current_start_date: null,
      moment_current_end_date: null,
      moment_real_start_date: null,
      moment_real_end_date: null,
      moment_expected_end_date: null,
      description: '',
      tags: '',
      primavera_id: '',
      primavera_wbs: '',
      department: null,
      critical_path: null,
      show_on_devices: 1,
      in_planning: false,
      planning_code: null,
      weight: null,
      status_id: null,
      activity_type_id: null
    }),
    locationsList: [],
    statusesList: [],
    typesList: [],
    hierarchicallySuperiorUsers: [],
    disableSaveButton: false,
    old_real_start_date: null,
    expected_end_date_string: '', // almacenara el expected_end_date en formato string para que no haga cambios de zonas horarias ni reste dias ni cosas raras
  }),

  computed: {
    ...mapGetters({
      project: 'app/project',
      company: 'app/company',
    }),

    departmentName() {
      return (this.form.department) ? this.form.department.name + ' (#' + this.form.department.id + ')' : ''
    },

    calculateExpectedEndDate() {

      let endDate = null

      if (this.old_real_start_date && !moment(this.form.real_start_date).isSame(this.old_real_start_date)) {
        let days = moment.duration(moment(this.old_real_start_date, 'DD/MM/YYYY').diff(moment(this.form.real_start_date, 'DD/MM/YYYY'))).asDays();
        endDate = moment(this.form.expected_end_date, 'DD/MM/YYYY').add(Math.floor(days) * -1, 'days').format("DD/MM/YYYY")
        this.expected_end_date_string = endDate.toString()
        return endDate
      }

      this.expected_end_date_string = this.form.expected_end_date
        ? this.form.expected_end_date.toString()
        : ''

      return this.form.expected_end_date
    },
  },

  created() {

    this.isSubactivity = this.$route.name === 'subactivity.create' || this.$route.name === 'subactivity.edit'
    this.createNew = this.$route.name === 'activity.create' || this.$route.name === 'subactivity.create'

    // Crear Actividad
    if (this.createNew && !this.isSubactivity) {
      this.$options.title = 'activity_create_page_title'
      this.$options.subtitle = 'activity_create_page_subtitle'
    }
    // Crear Subactividad
    else if (this.createNew && this.isSubactivity) {
      this.activityId = this.$route.params.activity_id
      this.$options.title = 'add_subactivity'
      this.$options.subtitle = ['subactivity_create_page_subtitle', [this.$route.params.name]]
      //Inicializamos el form con las fechas de la subactividad
      this.getParentActivityData(this.activityId)
    }
    // Editar Actividad
    else if (!this.createNew && !this.isSubactivity) {
      this.activityId = this.$route.params.activity_id
    }
    // Editar Subactividad
    else if (!this.createNew && this.isSubactivity) {
      this.activityId = this.$route.params.activity_id
      this.subactivityId = this.$route.params.subactivity_id
      //Inicializamos el form con las fechas de la subactividad
      this.getParentActivityData(this.activityId)
    }
  },

  mounted() {
    // Lista con las localizaciones del proyecto
    this.getLocationsList()
    // Editar
    if (!this.createNew) {
      // Editar actividad
      this.getActivityData()
      this.$refs.manager.refreshData()
      this.$refs.participants.refreshData()
      this.$refs.collaborators.refreshData()
    }
    // Crear
    else {
      if (this.isSubactivity) {
        this.getActivityManagerAsParticipantUser(this.activityId)
        this.getActivityCreatorAsParticipantUser(this.activityId)
      }
    }
    this.$refs.activityName.setFocus()
  },

  methods: {

    async getLocationsList() {
      const { data } = await axios.get('/api/v2/locations', { params: { 'p': this.project.id, 'all': true } })
      if (data && data.success) {
        this.locationsList = data.locations
      } else {
        this.$notify.error('error_loading_locations')
      }
    },

    async getActivityData() {
      let id = this.isSubactivity ? this.subactivityId : this.activityId
      const { data } = await axios.get('/api/v2/activity/' + id, { params: { with_statuses: 1, with_types: 1 } })
      if (data && data.success) {
        this.$refs.model_tags.initTags(data.data.tags)
        this.form.name = data.data.name
        this.form.description = data.data.description
        this.form.location_id = data.data.location_id
        this.form.scheduled_start_date = new Date(data.data.scheduled_start_date)
        this.form.scheduled_end_date = new Date(data.data.scheduled_end_date)
        this.form.current_start_date = new Date(data.data.current_start_date)
        this.form.current_end_date = new Date(data.data.current_end_date)
        this.form.real_start_date = new Date(data.data.real_start_date)
        this.form.real_end_date = new Date(data.data.real_end_date)
        this.form.expected_end_date = data.data.expected_end_date
          ? moment(new Date(data.data.expected_end_date), 'DD/MM/YYYY').format("DD/MM/YYYY")
          : moment(new Date(this.form.real_end_date), 'YYYY-MM-DD').format("DD/MM/YYYY")
        this.expected_end_date_string = this.form.expected_end_date.toString() // una copia en formato string para que no se hagan transformaciones automaticas de zona horaria
        this.form.primavera_id = data.data.planification_id
        this.form.primavera_wbs = data.data.planification_cat
        this.form.critical_path = data.data.critical_path
        this.form.department = data.data.department
        this.form.planning_code = data.data.planning_code
        this.form.in_planning = data.data.in_planning
        this.form.show_on_devices = data.data.show_on_devices
        this.form.weight = data.data.weight
        this.form.status_id = data.data.status_id
        this.form.activity_type_id = data.data.activity_type_id

        this.old_real_start_date = this.form.real_start_date

        // Call the trigger method manually to check the v-switch
        if (this.form.critical_path) {
          this.$refs.critical_path.trigger()
        }

        // Call the trigger method manually to check the v-switch
        if (this.form.in_planning) {
          this.$refs.in_planning.trigger()
        }

        if (!this.form.show_on_devices) {
          this.$refs.show_on_devices.trigger()
        }

        // Almacenamos los usuarios que están por encima en jerarquía del manager actual
        this.hierarchicallySuperiorUsers = data.data.hierarchically_superior_users

        if (data.data.is_creator)
          this.isCreator = true
        if (data.data.is_manager)
          this.isManager = true
        if (data.data.is_head_department)
          this.isHeadDepartment = true

        // Almacenamos lista de estados
        this.statusesList = data.data.statuses
        this.typesList = data.data.types
      } else {
        this.$notify.error('error_loading_activity_data')
      }
    },

    async getParentActivityData(parentId) {
      const { data } = await axios.get('/api/v2/activity/' + parentId)
      if (data && data.success) {
        this.form.activity_parent_scheduled_start_date = new Date(data.data.scheduled_start_date)
        this.form.activity_parent_scheduled_end_date = new Date(data.data.scheduled_end_date)
        this.form.activity_parent_current_start_date = new Date(data.data.current_start_date)
        this.form.activity_parent_current_end_date = new Date(data.data.current_end_date)
        this.form.activity_parent_real_start_date = new Date(data.data.real_start_date)
        this.form.activity_parent_real_end_date = new Date(data.data.real_end_date)
      }
    },

    async sendActivityData() {
      var url = '/api/v2/activity';
      this.disableButton();

      // Comprobamos si se ha añadido al menos un responsable
      if (this.$refs.manager.usersList.length > 0) {
        this.form.manager_user = this.$refs.manager.usersList[0].user_id
      } else {
        // Error: al menos un usuario
        this.$notify.error('error_no_users_in_list', { list: this.$t('activity_manager') });
        return
      }

      // El proyecto actual
      this.form.project_id = this.project.id
      // Añadimos participantes y colaboradores
      this.form.participants = this.$refs.participants.getUsersIds()
      this.form.collaborators = this.$refs.collaborators.getUsersIds()

      this.form.activity_id = this.activityId

      // Subactividad
      if (this.isSubactivity) {
        this.form.subactivity_id = this.subactivityId
        url += (this.subactivityId ? ('/' + this.subactivityId) : '')
      } else {
        url += (this.activityId ? ('/' + this.activityId) : '')
      }

      //Tratamos las fechas del form con moment para evitar problemas de zonas horarias
      this.form.moment_scheduled_start_date = moment(this.form.scheduled_start_date).format('YYYY-MM-DD')
      this.form.moment_scheduled_end_date = moment(this.form.scheduled_end_date).format('YYYY-MM-DD')
      this.form.moment_current_start_date = moment(this.form.current_start_date).format('YYYY-MM-DD')
      this.form.moment_current_end_date = moment(this.form.current_end_date).format('YYYY-MM-DD')
      this.form.moment_real_start_date = moment(this.form.real_start_date).format('YYYY-MM-DD')
      this.form.moment_real_end_date = moment(this.form.real_end_date).format('YYYY-MM-DD')
      // Enviamos expected_end_date como las otras, en formato YYYY-MM-DD
      this.form.moment_expected_end_date = this.expected_end_date_string.split('/')[2] + '-' + // YYYY
        this.expected_end_date_string.split('/')[1] + '-' + // MM
        this.expected_end_date_string.split('/')[0]; // DD

      // Send critical path as null if it is unchecked
      if (this.form.critical_path === 0) {
        this.form.critical_path = null
      }

      // Enviamos el form
      const { data } = await this.form.post(url)
      if (data && data.success) {

        // Actualizamos las etiquetas asignadas
        // let id = 0
        // // if (this.createNew) {
        // //   id = this.form.activity_id
        // // } else {
        // //   id = this.isSubactivity ?
        // // }
        this.$refs.model_tags.syncTagsToModel(this.createNew ? data.activity_id : data.activity.id)

        if (this.isSubactivity) {
          this.$notify.success(this.createNew ? 'success_creating_subactivity' : 'success_editing_subactivity')
          this.$router.push({
            name: 'subactivity.home', params: {
              subactivity_id: this.createNew ? data.activity_id : this.subactivityId, activity_id: this.activityId
            }
          })
        } else {
          this.$notify.success(this.createNew ? 'success_creating_activity' : 'success_editing_activity')
          this.$router.push({
            name: 'activity.home', params: {
              activity_id: this.createNew ? data.activity_id : this.activityId
            }
          })
        }
      } else {
        this.$notify.error(this.isSubactivity ? 'error_creating_subactivity' : 'error_creating_activity')
      }
    },

    async getHierarchicalAsParticipantUser(departmentId, user, reloadHierarchy = false) {
      let url = '/api/v2/department/' + departmentId + '/hierarchical'
      const { data } = await axios.get(url, { params: { with_dept_users: 1 } })
      if (data && data.success) {
        // Pasamos el flag de añadir al backend según si estamos creando o editando
        let addBackend = !this.createNew
        this.addUsersToParticipants(data.users_list, addBackend)

        // Si existen, añadimos los usuarios de jerarquía superior
        if (reloadHierarchy && data.users_list.length > 0) {
          this.hierarchicallySuperiorUsers = data.users_list.map(function (user) {
            return user.user_id
          })
        }
      }
    },

    async deleteHierarchicalAsParticipantUser(departmentId) {
      let url = '/api/v2/department/' + departmentId + '/hierarchical'
      const { data } = await axios.get(url)
      if (data && data.success) {
        this.removeUsersToParticipants(data.users_list)
      }
    },

    // Busca el departamento del responsable recién añadido y añadimos como participantes a su línea jerárquica
    async managerAdded(user) {
      //Comprobamos que no está ni como participante ni como colaborador
      if (!this.isUserInList(user, this.$refs.participants, this.$refs.collaborators)) {
        const { data } = await axios.get('/api/v2/project/' + this.project.id + '/user/' + user.user_id + '/department?m=false')
        if (data && data.success) {
          this.form.department = data.department
          //Añadimos sus padres en jerarquía como participantes, incluyendo jefes y usuarios de departamento
          // y recargando la lista de usuarios superiores jerárquicamente
          this.getHierarchicalAsParticipantUser(data.department.id, user, true)
        }
      } else {
        this.removeUserFromList(user, this.$refs.manager, ['admin'])
        this.$notify.error('error_user_in_use_manager')
      }
    },

    async collaboratorAdded(user) {
      //Comprobamos que no está ni como manager ni como participante
      if (this.isUserInList(user, this.$refs.manager, this.$refs.participants)) {
        this.removeUserFromList(user, this.$refs.collaborators, ['collaborate'])
        this.$notify.error('error_user_in_use_collaborator')
      }
    },

    async participantAdded(user) {
      // Comprobamos que no está ni como manager ni como colaborador
      // Ya no se usa la Jerarquía del organigrama
      // if (! this.isUserInList(user, this.$refs.manager, this.$refs.collaborators)) {
      //   const { data } = await axios.get('/api/v2/project/' + this.project.id + '/user/' + user.user_id + '/department?m=false')
      //   if (data && data.success) {
      //     // this.form.department = data.department
      //     //Añadimos sus padres en jerarquía como participantes, incluyendo jefes y usuarios de departamento
      //     this.getHierarchicalAsParticipantUser(data.department.id, user)
      //   }
      // } else {
      //   this.removeUserFromList(user, this.$refs.participants, ['participate'])
      //   this.$notify.error('error_user_in_use_participant')
      // }

      if (this.isUserInList(user, this.$refs.manager, this.$refs.collaborators)) {
        this.removeUserFromList(user, this.$refs.participants, ['participant'])  // cambiamos participate to participant que es el rol que existe en la base de datos
        this.$notify.error('error_user_in_use_participant')
      }
    },

    async activityViewerAdded(user) {
      //Comprobamos que no está ni como manager ni como participante
      if (this.isUserInList(user, this.$refs.manager, this.$refs.participants)) {
        this.removeUserFromList(user, this.$refs.viewers, ['activity_viewer'])
        this.$notify.error('error_user_in_use_collaborator')
      }
    },

    //Busca a los usuarios de su línea jerárquica y los elimina para mantener la coherencia
    async managerRemoved(user) {
      this.form.department = null
      this.hierarchicallySuperiorUsers = []
      //Obtenemos el departamento del usuario
      const { data } = await axios.get('/api/v2/project/' + this.project.id + '/user/' + user.user_id + '/department?m=false')
      if (data && data.success) {
        this.form.department = data.department
        //Eliminamos sus padres en jerarquía como participantes
        // Se nos ha solicitado que se mantengan los usuarios aunque se elimine el responsable
        // this.deleteHierarchicalAsParticipantUser(data.department.id)
      }
    },

    async getActivityManagerAsParticipantUser(activityId) {
      let url = '/api/v2/activity/' + activityId + '/users'
      const { data } = await axios.get(url, { params: { p: 'admin', r: 'activity_admin' } })
      if (data && data.success) {
        // Añado el responsable de la actividad como participante en la subactividad
        this.addUsersToParticipants(data.users_list, false)
      } else {
        this.$notify.error('error_getting_manager_user')
      }
    },

    async getActivityCreatorAsParticipantUser(activityId) {
      let url = '/api/v2/activity/' + activityId + '/creator'
      const { data } = await axios.get(url)
      if (data && data.success) {
        // Añado el creador de la actividad como participante en la subactividad
        this.addUsersToParticipants(data.users_list, false)
      } else {
        this.$notify.error('error_getting_creator_user')
      }
    },

    addUsersToParticipants(ListOrigin, addToBackend = true) {
      for (var i = ListOrigin.length - 1; i >= 0; i--) {
        var exist = false
        for (var j = this.$refs.participants.usersList.length - 1; j >= 0; j--) {
          if (this.$refs.participants.usersList[j].user_id === ListOrigin[i].user_id) {
            exist = true
          }
        }
        // Comprobamos para no añadir el responsable también como participante
        if (!exist && this.$refs.manager.usersList.length === 0 ||
          (!exist && this.$refs.manager.usersList[0].user_id !== ListOrigin[i].user_id)) {
          this.$refs.participants.usersList.push(ListOrigin[i])

          // Añadimos el usuario al backend si procede
          if (addToBackend) {
            EventBus.$emit('add-hierarchical-participant', ListOrigin[i]);
          }
        }
        // Esto es lo que había antes y funcionaba regular, lo borraremos una vez esté toda esta parte terminada
        // if(! exist && user === null || (! exist && user !== null && user.user_id != ListOrigin[i].user_id)){
        //   this.$refs.participants.usersList.push(ListOrigin[i])
        //   EventBus.$emit('add-hierarchical-participant', ListOrigin[i]);
        // }
        // }
      }
    },

    removeUsersToParticipants(ListOrigin) {
      for (var i = ListOrigin.length - 1; i >= 0; i--) {
        var exist = false;
        for (var j = this.$refs.participants.usersList.length - 1; j >= 0; j--) {
          if (this.$refs.participants.usersList[j].user_id == ListOrigin[i].user_id) {
            this.$refs.participants.usersList.splice(j, 1);
            EventBus.$emit('remove-hierarchical-participant', ListOrigin[i]);
          }
        }
      }
    },

    isUserInList(user, list1, list2) {
      var exist = false
      for (var j = list1.usersList.length - 1; j >= 0; j--) {
        if (list1.usersList[j].user_id == user.user_id) {
          exist = true
          break
        }
      }
      for (var j = list2.usersList.length - 1; j >= 0; j--) {
        if (list2.usersList[j].user_id == user.user_id) {
          exist = true
          break
        }
      }

      return exist
    },

    async removeUserFromList(user, list, permission) {

      for (var j = list.usersList.length - 1; j >= 0; j--) {
        if (list.usersList[j].user_id == user.user_id) {
          list.usersList.splice(j, 1);
          break
        }
      }

      // Solo si estamos editando, ya que en la creación no se añaden al backend hasta guardar
      if (!this.createNew) {

        let url = ''
        // if (this.isSubactivity) {

        // URL
        url = '/api/v2/activity/' + (this.isSubactivity ? this.subactivityId : this.activityId) + '/users/remove'

        // Axios
        const { data } = await axios.post(url, {
          'user_id': user.user_id,
          // 'permission_type': permission // parametro incorrecto enviado
          'role_name': permission[0] // parametro correcto y recive una cadena no un array
        })

        // }else{

        //   // URL
        //   url = '/api/v2/activity/' + this.activityId + '/users/remove'

        //   // Axios
        //   const { data } = await axios.post(url, {
        //     'user_id': user.user_id,
        //     'permission_type': permission
        //   })
        // }
      }
    },

    getEntityId() {
      if (!this.createNew) {
        return this.isSubactivity ? parseInt(this.subactivityId) : parseInt(this.activityId)
      } else {
        return null
      }
    },

    disableButton() {
      this.disableSaveButton = true
      let self = this
      setTimeout(function () {
        self.disableSaveButton = false
      }, 2000);
    },

    goToPage(destiny = 'extrafields') {

      switch (destiny) {

        case 'extrafields':
          if (this.isSubactivity) {
            this.$router.push({
              name: 'subactivity.extrafields.edit', params: {
                subactivity_id: this.subactivityId, activity_id: this.activityId
              }
            })
          } else {
            this.$router.push({
              name: 'activity.extrafields.edit', params: {
                activity_id: this.activityId
              }
            })
          }
          break;

        case 'streets':
          this.$router.push({
            name: 'activity.streets.edit', params: {
              activity_id: this.activityId
            }
          })
          break;

        case 'dismissed-pipes':
          this.$router.push({
            name: 'activity.dismissed-pipes.edit', params: {
              activity_id: this.activityId
            }
          })
          break;
      }

    }

  },
};
</script>

<style scoped>
@media (min-width: 640px) {
  .sm\:mb-0 {
    margin-bottom: 0px !important;
  }
}

/* FIX TEMPORAL. UN PAQUETE (PROBABLEMENTE @BIMDATA/VIEWER) ESTA SOBREESCRIBIENDO LA CLASE .flex-col */
@media (min-width: 1024px) {
  .lg\:flex-row {
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .md\:flex-row {
    flex-direction: row;
  }
}

@media (min-width: 640px) {
  .sm\:flex-row {
    flex-direction: row;
  }
}
</style>
