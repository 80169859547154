<template>
  <b-modal :active.sync="isModalActive" @after-leave="hide()" scroll="keep" :can-cancel="canCancel">
    <div class="p-4 bg-white border rounded shadow-lg border-blue-light">

      <header v-if="!editorPDF" class="w-full text-xl font-semibold uppercase text-blue-light text-center">
        {{$t('validate_task')}}
      </header>
      <header v-else class="w-full text-xl font-semibold text-blue-light cursor-pointer">
        <span @click="editorPDF=false">&lt;&lt; {{$t('close_editor_without_saving')}}</span>
      </header>

      <div v-if="!editorPDF">

            <div v-if="form.isLastGroup && (task.status == 5 && task.conditionals)" class="w-full flex flex-col items-center">


              <!-- Task name -->
              <div class="flex flex-row items-center mt-2">
                <div class="font-semibold text-gray-500">{{ task.name }}</div>
              </div>

              <!-- Conditionals -->
              <div v-if="isLoading" class="my-4 text-center">
                <scale-loader />
              </div>
              <div v-else class="flex flex-col items-center mt-2">
                <div class="text-xl"><b>{{ conditionals.question }}</b></div>

                <div class="flex flex-row justify-around w-48 mt-2">

                  <button
                    v-for="(action, key, index) in conditionals.actions"
                    :key="index"
                    :class="action.button.class"
                    type="button"
                    :disabled="isLoading"
                    @click="conditionalAction(action.type, action.data, conditionals.question, action.button.label)">{{ action.button.label }}</button>

                </div>
              </div>

            </div>



            <div v-else class="w-full">
              <form @submit.prevent="submitForm" class="w-full">
                <div>

                  <div class="flex flex-row items-center mt-4">
                    <!-- <div class="w-3 h-3 mr-1 rounded-full bg-green"></div> -->
                    <div class="font-semibold text-black">{{ task.name }}</div>
                  </div>

                  <div class="w-full pb-3 mt-4" >
                    <div class="text-xs font-medium text-blue">{{ $t("observations") }}</div>
                    <b-input
                      v-model="form.observation"
                      name="observation"
                      :placeholder="$t('observations')"
                      class="w-full"
                      maxlength="2000"
                      type="textarea"
                    ></b-input>

                    <div class="flex justify-center w-full pb-3 mt-4 gap-1" >
                      <!-- SIEMPRE DESPLEGADO <div class="flex flex-col w-1/4">
                        <button class="btn btn-blue" type="button" :disabled="isLoading" @click="toggleUpload()">
                          <i class="fas fa-paperclip"></i>&nbsp;
                          Adjuntar doc
                        </button>
                      </div> -->

                      <div class="flex flex-col w-1/4">
                        <button class="btn btn-red" type="button" @click="openCollapse = !openCollapse" :disabled="isLoading || fileEditing || form.isFirstGroup">
                          {{$t('reject')}}
                        </button>
                      </div>
                      <div class="flex flex-col w-1/4">
                        <button class="btn btn-blue" type="button" @click="submitStatus(7)" :disabled="isLoading || fileEditing">
                          {{$t('update_without_validating')}} 
                        </button>
                      </div>
                      <div class="flex flex-col w-1/4">
                        <button class="btn btn-green" type="button" @click="submitStatus(3)" :disabled="isLoading || fileEditing">
                          {{$t('validate')}} <span v-if="form.isLastGroup">*</span>
                        </button>
                      </div>
                    </div>

                    <b-collapse
                        animation="slide"
                        v-model="openCollapse">
                        <div class="w-full border text-center mt-4 pt-4" style="background-color: #fee; border: 1px solid #faa; border-radius:.4em">
                            <b class="">{{$t('select_reject_group')}}</b> <br/>
                            <span class="inline-flex mt-5" v-for="(group) in task.validation_groups" :key="group.id">
                                <div @click="form.selectedGroup = group.order_column" v-if="group.status != 0 && group.id != task.current_group.id" class="h-10 p-2 mr-1 font-bold text-xs text-center text-white btn"
                                    :class="form.selectedGroup == group.order_column ? 'btn-blue': 'btn-gray'">
                                        {{ group.group.name }}
                                </div>
                            </span> <br/>
                            <div class="inline-flex mb-3 mt-5">
                              <button class="btn btn-red" type="button" @click="submitStatus(4)" :disabled="isLoading || fileEditing || form.isFirstGroup || form.selectedGroup == null ">
                                {{$t('confirm_reject')}} <br>
                              </button>
                            </div>
                        </div>
                    </b-collapse>


                    <div class="w-full pb-3 mt-4" v-if="!canCancel">
                      <div class="text-red-700 px-4 py-3 rounded relative" style="border: 1px solid rgb(250, 204, 21); border-radius: 6px; padding: 0.25em; background-color: rgb(254, 248, 195);" role="alert">
                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <strong class="font-bold">{{$t('processing_request')}}</strong>
                        <span class="block sm:inline">{{$t('processing_request_info')}}</span>
                      </div>
                    </div>

                    <div class="text-right text-xs" v-if="form.isLastGroup">
                      * {{$t('validate_document_info')}}
                    </div>

                    <div v-html="$t('pending_document_confirm', [`<i class='fas fa-file-upload'></i>`])" class="text-right text-xs text-red" v-if="fileEditing">
                    </div>

                  </div>

                  <div class="w-full pb-3 mt-4" v-if="showUpload">

                    <!-- DOCUMENTOS DE PROYECTO. PLANTILLAS -->
                    <div v-if="task.documentsSelectables">
                      <div class="text-blue font-bold">Plantillas <b>({{ task.documentsSelectables.length }})</b></div>
                      <div v-for="docTemplate in task.documentsSelectables" :key="'doc_selec_'+docTemplate.document_id">
                        <div class="border border-dashed border-blue rounded p-1 mb-1 flex" style="">
                          <span class="mr-auto text-blue">{{ docTemplate.name }}</span>
                          <span class="ml-auto">
                            <b-tooltip :label="$t('upload_document_project')" position="is-left" :multilined="false">
                              <i class="fas fa-file-import text-blue" @click="editPDF(docTemplate)"></i>
                            </b-tooltip>
                          </span>
                        </div>
                      </div>
                    </div>


                    <!-- DOCS YA SUBIDOS -->
                    <div class="font-medium text-blue mt-8">Documentos subidos <b>({{ oldFiles.length }})</b></div>
                    <div v-for="(oldFile, index) in oldFiles" :key="'old_' + index" style="border: 1px solid #b5b5b5; border-radius: 6px;padding: 0.25em;margin-bottom:2px;">
                      <span class="text-xs font-bold">{{ oldFile.name }}</span>
                      <span v-if="differentNames(oldFile.name, oldFile.name2)"><br>{{ oldFile.name2 }}</span>

                      <div class="py-1">
                        <span class="border border-blue border-dashed rounded p-1 cursor-pointer text-white hover:bg-blue"
                          @click="uploadVersion(oldFile)" style="background-color: #63b3ed;">
                          {{$t('upload_local_version')}} <i class="fas fa-file-medical"></i>
                        </span>
                        <span class="border border-blue border-dashed rounded p-1 cursor-pointer text-white hover:bg-blue"
                          @click="editPDF(oldFile, oldFile.document_id)" style="background-color: #63b3ed;">
                          {{$t('upload_online_version')}} <i class="fas fa-pen"></i>
                        </span>
                      </div>
                    </div>


                    <!-- DOCS NUEVOS PENDIENTES DE SUBIR -->
                    <div class="font-medium text-blue mt-8">{{$t('pending_document_upload')}} <b>({{ newFiles.length }})</b></div>
                    <div v-for="(newFile, index) in newFiles" :key="'new_' + index" style="border: 1px solid #b5b5b5; border-radius: 6px;padding: 0.25em;background-color: #e1fabe;">
                      <span class="text-xs font-bold" v-if="newFile.desc_name">{{ newFile.desc_name }}</span>
                      <span class="text-xs font-bold" v-else>{{ newFile.name }}</span>
                      <br>
                      {{ newFile.custom_name }}
                      <b-tooltip :label="$t('delete')" position="is-top" :multilined="false">
                        <i class="fas fa-trash-alt" @click="newFiles.splice(index,1)"></i>
                      </b-tooltip>
                      <b-tooltip :label="$t('file_saved_validate_reject')" position="is-top" :multilined="false">
                        <i class="fas fa-exclamation text-blue"></i>
                      </b-tooltip>
                    </div>

                    <!-- DOCUMENTO ACTUAL MODIFICANDO... -->
                    <div v-if="file.size > 0" class="font-medium text-blue">{{$t('current_document')}}</div>
                    <div v-if="file.size > 0" style="border: 1px solid #b5b5b5; border-radius: 6px;padding: 0.25em;background-color: #f9eecd;">
                      <span class="text-xs font-bold" v-if="file.desc_name">{{ file.desc_name }}</span>
                      <span class="text-xs font-bold" v-else>{{ file.name }}</span>
                      <br>
                      {{ file.custom_name }}
                      <b-tooltip :label="$t('set_filename')" position="is-top" :multilined="false" >
                        <i class="fas fa-file-signature" @click="toggleCodification()"></i>
                      </b-tooltip>
                      <b-tooltip :label="$t('upload')" position="is-top" :multilined="false">
                        <i class="fas fa-file-upload" @click="sendToQueue()"></i>
                      </b-tooltip>
                      <b-tooltip :label="$t('delete')" position="is-top" :multilined="false">
                        <i class="fas fa-trash-alt" @click="removeFile()"></i>
                      </b-tooltip>
                      <b-tooltip :label="$t('confirm_upload_document')" position="is-top" :multilined="false">
                        <i class="fas fa-exclamation text-red"></i>
                      </b-tooltip>
                    </div>

                    <!-- DOCUMENTO ACTUAL (DE NUEVA VERSION) MODIFICANDO... -->
                    <div v-if="fileVersion.documentId != 0" class="font-medium text-blue">{{$t('current_document')}}</div>
                    <div v-if="fileVersion.documentId != 0" style="border: 1px solid #b5b5b5; border-radius: 6px;padding: 0.25em;background-color: #f9eecd;">
                      <span class="text-xs font-bold" v-if="fileVersion.desc_name">{{ fileVersion.desc_name }}</span>
                      <span class="text-xs font-bold" v-else>{{ fileVersion.name }}</span>
                      <br>
                      {{ fileVersion.custom_name }}
                      <b-tooltip :label="$t('set_filename')" position="is-top" :multilined="false" >
                        <i class="fas fa-file-signature" @click="toggleCodification()"></i>
                      </b-tooltip>
                      <b-tooltip :label="$t('upload')" position="is-top" :multilined="false">
                        <i class="fas fa-file-upload" @click="sendToQueue(1)"></i>
                      </b-tooltip>
                      <b-tooltip :label="$t('delete')" position="is-top" :multilined="false">
                        <i class="fas fa-trash-alt" @click="removeFile(1)"></i>
                      </b-tooltip>
                      <b-tooltip :label="$t('confirm_upload_document')" position="is-top" :multilined="false">
                        <i class="fas fa-exclamation text-red"></i>
                      </b-tooltip>
                    </div>

                    <div class="w-full pb-3 mt-4" v-if="showCodification">

                      <codification-lean v-if="project.codification_form.startsWith('lean')"
                        :form="form"
                        :prefixCode="project.codification_form == 'lean2' || project.codification_form == 'lean3'
                          ? validableParentCode
                          : ''"
                        @generatedCodename="(obj) => applyFileName(obj)"/>

                      <codification-dragados v-if="project.codification_form=='dragados'"
                        :form="form" :is-new-file="!!this.file.size"
                        :version-file-name="this.fileVersion ? this.fileVersion.custom_name : ''"
                        @generatedCodename="(obj) => applyFileName(obj)"/>

                      <codification-default v-if="project.codification_form === null"
                        :is-new-file="!!this.file.size"
                        :version-file-name-new="this.fileVersion ? this.fileVersion.name : ''"
                        :version-file-name-custom="this.fileVersion ? this.fileVersion.custom_name : ''"
                        :version-file-name-desc="this.fileVersion ? this.fileVersion.desc_name : ''"
                        @generatedCodename="(obj) => applyFileName(obj)"/>

                    </div>

                    <b-field v-if="emptyFile() && company.alias != 'solarpack'" class="file is-success" style="padding-top: 1.25em;">
                      <b-upload v-model="file" class="w-full file-label" :multiple="false" drag-drop :accept="accept">
                        <div class="w-full text-center font-bold">
                          <p>{{$t('add_new_file')}} <b>+</b></p>
                        </div>
                      </b-upload>
                    </b-field>

                    <!-- campo oculto subir fichero version -->
                    <b-field v-if="true" class="file is-success">
                      <b-upload v-model="fileVersion" id="fileVersionInput" v-show="false" class="file-label" :multiple="false" drag-drop :accept="accept">
                        <div class="w-full text-center text-grey">
                          <p>{{$t('add_version')}} +</p>
                        </div>
                      </b-upload>
                    </b-field>

                    <div v-if="isLoading" class="my-4 text-center">
                      <scale-loader />
                    </div>
                  </div>


                </div>


              </form>
            </div>

      </div>

      <div v-show="editorPDF">
        <div id="webviewer" ref="webviewer" class="flex flex-col w-full h-full" style="height:90vh"></div>
      </div>

    </div>
  </b-modal>
</template>

<script>
import Vue from 'vue';
import axios from "axios";
import { mapGetters } from "vuex";
import EventBus from "~/plugins/bus";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import moment from 'moment'
import WebViewer from "@pdftron/webviewer";
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import store from "~/store";
import CodificationLean from '·/components/documents-codifications/codification-lean'
import CodificationDragados from '·/components/documents-codifications/codification-dragados.vue';
import CodificationDefault from '·/components/documents-codifications/codification-default.vue';

export default {
  name: "validation-completion-modal",

  components: {
    ScaleLoader,
    WebViewer,
    CodificationLean,
    CodificationDragados,
    CodificationDefault,
  },

  props: {
    flowId: { type: Number, required: true },
    validableName: { type: String, required: true },
    validableParentCode: { type: String, required: false, default: '' },
  },

  data: () => ({
    conditionals: {},
    isModalActive: false,
    openCollapse: false,
    canCancel: ['escape', 'x', 'outside'],
    id: null,
    isLoading: false,
    showUpload: true, // ya siempre desplegado
    showCodification: false,
    desc_name: '', // contendrá un nombre descriptivio auxiliar (campo document.name)
    custom_name: '', // contendrá el nombre fichero codificado lean 5s digital (campo document.original_filename)
    coded_name: 0,

    task: {}, // los datos de la task
    taskLineId: null, // los datos de la task

    statuses: ['started', 'pending', 'pending_validation', 'refused', 'completed'],

    form: {
      observation: null,
      status: 0,
      selectedGroup: null,
      isFirstGroup: false, // flag, grupo que va a validar esta tarea es el primero (propietario) y no podrá rechazarla
      isLastGroup: false, // flag, grupo que va a validar esta tarea es el ultimo de la linea de validacion (para disparar acciones en backend)
    },
    fileEditing: false, // flag que indica si hay algun fichero en edicion
    file: {}, // el fichero actual seleccionado (de 1 en 1 para poder codificar)
    fileVersion: { documentId: 0}, // el fichero actual seleccionado DE NUEVA VERSION
    newVersionDocId: 0, // contendrá el document id del fichero que hay que versionar
    newVersionDescName: '', // contendrá el name del fichero que hay que versionar
    newVersionCustomName: '', // contendrá el custom_name del fichero que hay que versionar
    newFiles: [], // cola de ficheros con nombre codificado (o no) listos para subir when submit
    oldFiles: [], // lista de ficheros de la tarea subidos previamente
    editorPDF: false, // flag mostrar editor PDFTron
    iframeId: null, // id del iframe donde PDFTron se ha montado
    accept: "", // on created()
    userSignatures: '', // firmas generadas en pdftron guardadas en otras ocasiones
    uppy: null, // instancia de la libreria para subir archivos
    keyFileUploadCurrent: null, // key del archivo que se esta subiendo actualmente
  }),

  mounted() {
    this.addMessageHandler(); // para pdftron
  },

  created() {

    this.accept = this.$acceptedExtensions // extensiones aceptadas

    this.uppy = new Uppy({
        debug: process.env.NODE_ENV === 'development', // activamos debug para development
        autoProceed: false,
        allowMultipleUploads: false,
      })
      .use(AwsS3Multipart, {
        limit: 3,
        companionUrl: '/api/v2/',
        companionHeaders: {
          'Authorization': "Bearer " + store.getters["auth/token"]
        }
      })
      // .on('upload-success', (file, response) => { this.onUploadSuccess(file, response) } )
      .on('upload-success', (file, response) => { // callback deveulto cuando el file ha sido subido correctamente
        console.log('%cEPC-TACKER: '+ '%c file ' + file.data.name + ' subido correctamente', 'background: #5577BB; color: #fff', 'color: #000')
      })
      .on('upload-progress', (file, progesss) => { // callback devuelto con el progreso de subido del archivo
        this.uploadPercentage = parseInt(
          Math.round((progesss.bytesUploaded * 100) / progesss.bytesTotal)
        );
      })
      .on('upload-error', (file, error, response) => { // callback devuelto si ha ocurrido algun error en la subida
        console.error('%cEPC-TACKER: '+ '%c ha ocurrido un error al subir el archivo ' + file.data.name, 'background: #5577BB; color: #fff', 'color: #000', error)
      })
  },

  beforeDestroy(){
    this.removeMessageHanlder(); // para pdftron
  },

  computed: {
    ...mapGetters({
      company: 'app/company',
      project: 'app/project',
      user: "auth/user",
    }),

    filesSelected() {
      return this.newFiles.length > 0;
    },

  },

  watch: {
    // detecta cuando se selecciona un fichero en el input oculto de subir version fichero
    fileVersion: function () {
      // si seleccionado nuevo fichero para subir version
      if (this.fileVersion.name && this.fileVersion.name.length > 0) {


        this.fileVersion.documentId = this.newVersionDocId // le añade el atributo documentId que indica actualizacion de version
        this.fileVersion.desc_name = this.newVersionDescName
        this.fileVersion.custom_name = this.getNextVersionFileName(this.newVersionCustomName)
        this.fileVersion.custom_name = this.fileVersion.custom_name.substr(0, this.fileVersion.custom_name.lastIndexOf(".")) + this.getFileExtension(this.fileVersion)

        // hemos forzado a todo fichero a pasar por getNextVersion porque dentro se detectará si encuentra version o no
        // this.fileVersion.custom_name = this.newVersionHasCodedName
        //   ? this.getNextVersionFileName(this.newVersionCustomName)
        //   : this.newVersionCustomName
        this.fileVersion.has_coded_name = this.newVersionHasCodedName
        this.newVersionDocId = 0
        this.newVersionDescName = ''
        this.newVersionCustomName = ''
        this.newVersionHasCodedName = false
        this.fileEditing = true
      } else {
        this.fileEditing = false
      }
    },

    file: function () {
      if (Object.keys(this.file).length === 0 && this.file.constructor === Object)
        this.fileEditing = false // si file es objecto vacio, no hay fichero editandose
      else
        this.fileEditing = true
    },

    showCodification: function () {
      // cada vez que se oculte este form, resetear valores
      if ( this.showCodification == false) {
        this.desc_name = ''
        this.custom_name = ''
        this.coded_name = 0
        this.form = this.defaultFormValues();
      }
    },

  },

  methods: {

    toggleUpload() {

      this.showUpload = !this.showUpload

      if (this.showUpload) {
        this.getOldFiles()
      }
    },

    async getOldFiles() {

      let self = this;

      let params = { }

      self.isLoading = true;
      let fileType = '-' //todos
      //traer los ficheros de esta task
      await axios.get("/api/v2/validations/task/"+ this.task.id + "/documents?" + fileType + "=1",
        { params: params })
        .then(function(response) {

          if (response.data && response.data.documents) {
            self.oldFiles = response.data.documents;
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(function () {
          self.isLoading = false;
        });
    },

    toggleCodification() {
      this.showCodification=!this.showCodification;
    },

    // envia un fichero recien seleccionado a la cola para subir cuando submit form
    sendToQueue(isVersion=0) {

      if (isVersion) {
        this.newFiles.push(this.fileVersion)
        this.fileVersion = { documentId: 0 }
      } else {
        this.newFiles.push(this.file)
        this.file = {}
      }

      this.showCodification=false
    },


    // aplica al campo custom_name el codigo de nombre generado desde componente hijo de codificacion
    applyFileName(obj) {

      // si estamos trabajando con un fichero a subir nuevo
      if (this.file.size) {
        this.file.desc_name = obj.desc_name ? obj.desc_name : '' // quitamos extension en el nombre descriptivo
        this.file.custom_name = obj.custom_name + this.getFileExtension(this.file)
        this.file.coded_name = obj.coded_name
      }
      // si estamos trabajando con subir una version de un fichero existente
      if (this.fileVersion.size) {
        this.fileVersion.desc_name = obj.desc_name ? obj.desc_name : '' // quitamos extension en el nombre descriptivo
        this.fileVersion.custom_name = obj.custom_name + this.getFileExtension(this.fileVersion)
        this.fileVersion.coded_name = obj.coded_name
      }

      this.toggleCodification(); // y oculta la codificacion tras acabar

    },

    // ejecuta el click del input oculto para subir fichero de version
    uploadVersion(doc) {
      this.showCodification=false // por si estaba abierto...

      this.newVersionDocId = doc.document_id
      this.newVersionDescName = doc.name2
      this.newVersionCustomName = doc.name
      this.newVersionHasCodedName = doc.coded_name
      document.getElementById('fileVersionInput').click()
    },

    // devuelve el nombre fichero sumando 1 al numero de version (si es DEF lo deja igual)
    // lo hace si nombre fich tiene 3 o mas partes separadas por '_' y en la penultima parte encuentra un numero (o V00, V01...)
    // si es asi suponemos que el nombre ha sido codificado (aunque coded_name sea 0, para cubrir casos cuando se codificó fuera de esta app)
    getNextVersionFileName(fileName, forceDef = false) {

      // esta funcion es solo para codificacion canal 'lean'. El resto no hace nada
      if (this.project.codification_form != 'lean' && this.project.codification_form != 'lean2') {
        return fileName
      }

      let splitName = fileName.split('_')

      if (splitName.length < 3) {
        return fileName // fichero no codificado o con menos campos de los debidos... no hacer nada
      }

      let version = splitName[splitName.length - 2] // version siempre es el penultimo

      if (version == 'DEF') {
        return fileName // ya era DEF, no se suma nada
      }

      // El ultimo grupo, si VALIDA, se fuerza version a DEFinitivo
      if (forceDef) {
        splitName[splitName.length - 2] = 'DEF'
      } else {
        version = version.replace('V', '').replace('0', '') // V02 -> 2
        if ( isNaN(parseInt(version)) ) {
          return fileName // no se detecta numero en este campo debe ser otra cosa, devolvemos tal cual
        }
        version = parseInt(version) + 1 // 3
        splitName[splitName.length - 2] = 'V' + version.toString().padStart(2, '0') // V03
      }

      return splitName.join('_')

    },

    submitStatus(status) {
      this.form.status = status
      this.submitForm()
    },

    /**
     * Envía el formulario de validacion de tarea
     */
    async submitForm() {

      if (!this.task.id) { // si pulsas 20 veces el boton validar, entra alguna llamada por ahi sin task.id (veridico!)
        return;
      }
      this.canCancel = false
      let url = "/api/v2/validations/task/" + this.task.id + "/status"

      this.isLoading = true;

      await axios({
        method: "post",
        url: url,
        data: this.form,
        })
        .then((response) => {
          this.taskLineId = response.data[0].id;
          this.$toast.open({
            message: response.data.error || this.$t("success_editing"),
            type: response.data.error ? "is-danger" : "is-success",
            position: "is-top-right",
          });
          EventBus.$emit("refreshValidationTasks");
        })
        .catch((error) => {
          //Se produjo un error
          this.isLoading = false;
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("update_error"),
            type: "is-danger",
            position: "is-top-right",
          });
        });


      if (this.filesSelected) {
        await this.uploadFiles();
      } else {
        this.isLoading = false;
      }
      EventBus.$emit("refreshValidationDocumentsNotifications");
      // this.$emit("reload-documents");
      this.hide();
      // this.$emit("reload");
      // notificamos flujo completado (projectRules)
      await axios.get('/api/v2/validation/' + this.flowId + '/callback')
    },

    async uploadFiles() {
      if (this.newFiles.length == 0) {
        return;
      }

      this.isLoading = true;
      let files = this.newFiles.slice();
      for (var i = files.length - 1; i >= 0; i--) {
        if (files[i].size / 1024 / 1024 > 1024) { // acepta ficheros de 1GB (podria fallar por timeout en conexion lenta)
          this.$notify.warning("document_exceeded_max_size", {
            name: files[i].name,
          });
        } else if (files[i].size <= 0) { // menores o iguales a 0 por ahora consideramos que estan corruptos
          this.$notify.warning("document_corrupt_cero_size", {
            name: files[i].name,
          });
        } else {
          let ok = await this.submitFile(files[i]);
          if (ok) {
            this.newFiles.splice(i, 1);
            this.$notify.success("upload-document_success", {
              files: files[i].name,
            });
          } else {
            this.isLoading = false;
            this.$notify.error("upload-document_error");
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'El fichero '+files[i].name+' no se ha podido subir, por favor intentelo de nuevo',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            return false;
          }
        }
      }

      // Todos los ficheros subidos
      if (this.newFiles.length == 0) {
        this.isLoading = false;
        this.$notify.success("upload-document_success", {
          files: files.length,
        });
        this.$emit("refreshValidationDocumentsNotifications");
        return true;
      }
    },

    async submitFile(file) {

      this.uploadPercentage = 0;
      this.cancelRequest = axios.CancelToken.source();

      // si el ultimo validador está VALIDANDO (aceptando) la tarea
      // se fuerza a DEF las nuevas versiones subidas
      if (this.form.isLastGroup &&
        this.form.status == 3 &&
        file.has_coded_name &&
        file.custom_name) {
          file.custom_name = this.getNextVersionFileName(file.custom_name, true)
      }

      let form = new FormData();

      // file.name no se puede escribir, usamos file.new_name
      // si escribió un nombre descriptivo, usamos ese, si no el nombre por defecto del fichero
      file.new_name = file.desc_name || file.name

      // campo obligatorio en api, 'original_filename'
      if (file.custom_name) {
        form.append("original_filename", file.custom_name.trim())
      } else {
        form.append("original_filename", file.new_name.trim())
      }

      // si hay coded_name = 1 es porque se ha metido el nombre a traves de form de codificacion
      if (file.coded_name) {
        form.append("coded_name", 1) // si viene de subir version, tendrá definido has_coded_name
      }

      // campo opcional en api, 'file_name' con nombre descriptivo
      if (file.desc_name) {
        form.append("file_name", file.new_name.trim())
      }
      //else en backend se calcula este campo a partir de original_filename si no se introduce nada


      return this.store(file, {})
        .then(async result => {
            // successFul contiene toda la lista de archivos que se han subido
            // para nuestro caso solo consideramos siempre el primero por que enviamos de a uno
            if( result.successful.length > 0 ) {
              let response = result.successful[0].response

              let params = {
                // uuid: response.uuid,
                key: response.body.key,
                // bucket: response.bucket,
                // name: file.name,
                content_type: file.type,
                // url: response.url
              }

              form.append("aws_response", JSON.stringify(params));

              let endpoint = ''
              if(file.documentId && file.documentId > 0) {
                // addversion
                form.append("validation_task_line_id", this.taskLineId);
                form.append("validation_task_id", this.task.id);

                form.append("entity_type", "validation"); // subir version no vincula a validationtask, solo modifica el document existente, debe llevar 'validation'
                form.append("entity_id", this.flowId);

                endpoint = '/api/v2/document/' + file.documentId + '/add_version/validation/' + this.flowId
              } else {
                // fichero nuevo
                form.append("entity_type", "validationtask");
                form.append("entity_id", this.task.id);
                form.append("project_id", this.project.id)
                endpoint = "/api/v2/validation" + "/" + this.flowId + "/document"
              }

              let resp = null
              resp = await axios.post(endpoint, form, {
                cancelToken: this.cancelRequest.token,
              })

              return resp && resp.data && resp.data.success ? resp.data.success : false
            }

            // failed contiene todos los archivos que tubieron fallo al subir
            if (result.failed.length > 0) {
              console.error('Errors:')
              result.failed.forEach((file) => {
                console.error(file.error)
              })

              return false
            }
        }).catch((error) => {
          console.log('fail #ff44b: ',error)
          return false;
        })

    },

    // CUSTOM VAPOR STORE METHOD
    async store(file, options = null) {

      // verificamos si existe algun archivo en la lista de carga de uppy
      // si existe lo eliminamos
      if( this.keyFileUploadCurrent ) {
        this.uppy.removeFile(this.keyFileUploadCurrent);
      }

      this.keyFileUploadCurrent = this.uppy.addFile({
        name: file.name, // file name
        type: file.type, // file type
        data: file, // file blob
        // meta: {
        //   // optional, store the directory path of a file so Uppy can tell identical files in different directories apart.
        //   relativePath: webkitFileSystemEntry.relativePath,
        // },
        source: 'Local',
        isRemote: false,
      });

      return this.uppy.upload()

    },

    // quita el fichero del cuadro de "edicion"
    removeFile(isVersion) {
      this.showCodification = false

      if (isVersion) {
        this.fileVersion = { documentId: 0 }
      } else {
        this.file = {}
      }

      this.showCodification=false
    },


    /**
     * Muestra este modal
     */
    show(task, isFirstGroup, isLastGroup) {
      this.getSignatures(); // aqui en show trae firmas nuevas cada vez que edita. En created solo cada vez que recarge. Evaluar...
      this.conditionals = null;
      this.task = task;

      this.form.isFirstGroup = isFirstGroup // deshabilita boton rechazar al 1er grupo
      this.form.isLastGroup = isLastGroup

      if(task.conditionals){
        this.conditionals = JSON.parse(task.conditionals);
      }

      this.getOldFiles()

      this.isModalActive = true;
    },

    getFileExtension(file) {
      let re = /(?:\.([^.]+))?$/;
      return "." + re.exec(file.name)[1];
    },

    emptyFile() {
      // ver si objeto fichero a subir está vacio, y tampoco se esta editando nueva version de fichero
      return Object.keys(this.file).length === 0 && this.file.constructor === Object
        &&
        this.fileVersion.documentId == 0;
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    /**
     * valores por defecto del formulario
     */
    defaultFormValues() {
      return {
        observation: null,
        status: 0,
        selectedGroup: null,
        isFirstGroup: this.form.isFirstGroup, // igual que estaba, este no se toca, se recibe en el show
        isLastGroup: this.form.isLastGroup // igual que estaba, este no se toca, se recibe en el show
      };
    },

    /**
     * Oculta este modal
     */
    hide() {
      this.task = {};
      this.canCancel = ['escape', 'x', 'outside']
      this.isModalActive = false;
      this.form = this.defaultFormValues();
      //resetamos archivos
      this.file = {}
      this.newFiles = []
      this.oldFiles = []
      this.editorPDF = false
      this.iframeId = null
      this.openCollapse = false
      // this.showUpload = false
    },



    /*
    * Acción de tarea condicional
    */
    async conditionalAction(actionType, actionData, question, answer)
    {

      this.isLoading = true;

      // Preparamos los datos para pasarlos al endpoint
      let params = {
        "type" : actionType,
        "data" : JSON.stringify(actionData) ?? null,
        "task_id" : this.task.id,
        "question": question,
        "answer": answer
      }

      // Enviamos los parametros para registrar la respuesta
      await axios.post("/api/v2/validation/"+ this.flowId +"/conditional", params)
      .then((response) => {

        // Si la acción es otra condicional, renderizamos la nueva pregunta
        if(actionType == 'next_conditional'){
          this.conditionals = JSON.parse(response.data[0]);
          this.isLoading = false;

        }else{

          // Si la acción es finalizar flujo o nueva tarea, cerramos la modal y refrescamos.
          this.isLoading = false;
          EventBus.$emit("refreshValidationTasks");
          this.hide();

        }



      })
      .catch((error) => {
        this.$notify.error(error);
              this.isLoading = false;

      });

    },


    // devuelve si 'name' es una version slugged de original_filename
    differentNames(original_filename, name) {
      // en el modelo Document el name es un slug de original_filename
      let sluggedName = original_filename.toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');

      return sluggedName != name
    },


    // *********** PDFTRON ***************

    editPDF(file, isVersionOfDocId = 0) {
      this.editorPDF = true
      this.file4editor = file

      if (this.iframeId) {
        // ya hay instancia de pdftron creada, tan solo cambiar el documento editado
        this.sendMessage(this.iframeId, isVersionOfDocId)
      } else {
        // PDFTron no ha sido cargado todavia, crear instancia
        this.loadPdftron(isVersionOfDocId)
      }
    },


    // addMessageHandler: function() {
    async addMessageHandler() {
      let self = this;

      // escuchará los mensajes enviados desde /pdftron_config.js
      window.addEventListener('message', self.handleAddMessage);
    },

    handleAddMessage(event) {
      let self = this;
      // recibido mensaje con id del iframe creado por Webviewer
      if (event.data.type=='viewerReady') {

        // en produccion: assets en cloudfront.net, distinto al dominio *.epc-tracker.com, limitaciones protocolarias no recibe id iframe
        // Si no recibe id de iframe, detectarlo aqui, será el primer nodo hijo del div 'webviewer'
        self.iframeId = event.data.data || document.getElementById("webviewer").childNodes[0].id

        let isVersionOfDocId = event.data.isVersionOfDocId || 0

        // enviamos mensaje a ese iframe con el documento a mostrar
        self.sendMessage(self.iframeId, isVersionOfDocId) // id del iframe que crea Webviewer para mostrarse
      }

      // recibido mensaje con el documento ya editado que hay que guardar
      if (event.data.type=='saveDocument') {

        // flag distinguir si subir version o crear nuevo desde plantilla
        if (event.data.isVersionOfDocId) {
          // nueva version
          event.data.data.documentId = event.data.isVersionOfDocId
          event.data.data.name = self.file4editor.name // mantiene nombre igual
        } else {
          // subir nuevo desde plantilla. Generar nombre fichero: nombrefich+nombreactividad
          event.data.data.name = self.genFileNamePDF()
        }

        self.newFiles.push(event.data.data)
        self.editorPDF = false // volvemos a validar/rechazar
        self.file4editor = {}

        // self.submitFile(event.data.data) // Blob del fichero editado. Guardado directo funciona OK
        //   .then(ok => {
        //     if (ok) {
        //       self.$notify.success("upload-document_success", event.data.data.name)
        //       self.editorPDF = false // volvemos a validar/rechazar
        //       self.file4editor = {}
        //       self.getOldFiles() // reload files
        //     }
        //   })
      }

      // recibido mensaje con las firmas del usuario a guardar
      if (event.data.type=='saveSignatures') {
        // guardar firmas en DB (firmas vienen serializadas. Son trazos si son dibujos y base64 si son imagenes)
        self.saveSignatures(event.data.data)
      }
    },


    removeMessageHanlder: function(){
      window.removeEventListener('message', this.handleAddMessage);
    },


    // envia postMessage que será recogido en /pdftron_config.js
    sendMessage(frameId='webviewer-1', isVersionOfDocId = 0) {
      // pasa un mensaje al iframe alojado en "otro" dominio (cloudfront.net)
      // con url del documento a cargar. Asi evitamos cors
      document.getElementById(frameId)
        .contentWindow.postMessage({
          type:'epc-document',
          data: this.file4editor,
          isVersionOfDocId: isVersionOfDocId
          }, '*')

      // establecer usuario que está editando (aparecerá su nombre al firmar)
      document.getElementById(frameId)
        .contentWindow.postMessage({ type:'epc-user', data: this.user }, '*')

      // carga en herramienta de firmas las firmas guardadas del usuario
      if (this.userSignatures.length > 0) {
        document.getElementById(frameId)
          .contentWindow.postMessage({ type:'user-signatures', data: this.userSignatures }, '*')
      }

    },


    loadPdftron(isVersionOfDocId = 0) {
      // console.log("path ", process.env.ASSET_PATH)
      // Esto creará un iframe dentro del div webviewer
      // y aparentemente estará alojado en otro dominio ya que va por la url de los assets (clouldfront.net)
      WebViewer({
        licenseKey: 'EPC TRACKER DEVELOPMENTS S.L. (epc-tracker.com):OEM:EPC Tracker::B+:AMS(20240330):82B571D30477A80A0360B13AC9A2D57860612FDDEBC3AD8846C78E53BF875C0791C6B6F5C7',
        path: `${process.env.ASSET_PATH}webviewer`,
        config: `${process.env.ASSET_PATH}pdftron_config.js`,
        custom: JSON.stringify({ validationTaskForm: true, isVersionOfDocId: isVersionOfDocId }),
      }, this.$refs.webviewer)
        .then(instance => {
          // todo el codigo está en el fichero de pdftron_config.js pasado arriba
          // para que pueda ser ejecutado en produccion donde el dominio (app/dev).epc-tracker.com
          // será distinto que los asset (*.cloudfront.net)
          // asi se evita los problemas de cors porque ese fichero pdftron_config.js se ejecuta en el mismo dominio que webviewer
          // para ello hemos insertado los dominios en whitelisted in public/pdftron_configorigin.txt
        })


    },


    async getSignatures() {

      let self = this;

      const {data} = await axios.get('/api/v2/user/' + this.user.id + '/pdf-signatures')
      if (data && data.success) {
        self.userSignatures = data.data?.signatures || ''
      } else {
        console.log("Could not load user PDFSignatures")
      }

    },


    async saveSignatures(signatures) {

      const {data} = await axios.post('/api/v2/user/' + this.user.id + '/pdf-signatures', { signatures })
      if (data && data.success) {
        // do nothing. silently saved
      } else {
        this.$notify.error(data.msg)
      }

    },


    // cuando generamos un fichero a partir de documento de proyecto, el nombre será: nombrefich+nombreactividad
    genFileNamePDF() {
      return this.file4editor.name.replace(/\.[^/.]+$/, "") // nombre fich sin extension
        + '_'
        + this.validableName.trim().toLowerCase().replaceAll(' ', '_') // nombre actividad sin espacios
        + this.getFileExtension(this.file4editor) // extension fich
    },


  },
};
</script>
<style scoped>
/* Oculta los botones para aumentar los inputs numéricos */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield; /* Standard */
}
</style>
