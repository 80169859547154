<template>
    <b-modal :active.sync="isModalActive" :width="600" scroll="keep" :can-cancel="canCancel" @close="closeModal">
        <div class="border border-blue-light rounded shadow bg-white p-4">
            <form @submit.prevent="saveIndirectCost" class="w-full">
                <header class="font-thin uppercase text-blue-light modal-card-head_ mb-4">
                    {{ $t('edit_indirect_cost') }}
                </header>
                <div class="modal-card-body_ relative">
                    <!-- Flexbox layout for the form fields -->
                    <div class="flex flex-wrap mx-4">
                        <!-- First Row -->
                        <div class="w-1/2 px-4">
                            <b-field :label="$t('code')">
                                <b-input v-model="indirectCost.code" title="code" required name="code"
                                    :placeholder="$t('code')" maxlength="100" />
                            </b-field>
                        </div>
                        <div class="w-1/2 px-4">
                            <b-field :label="$t('name')">
                                <b-input v-model="indirectCost.name" title="name" required name="name"
                                    :placeholder="$t('name')" maxlength="255" />
                            </b-field>
                        </div>
                    </div>
                    <div class="flex flex-wrap mx-4 mb-3 justify-end">
                        <v-button type="submit" class="px-4">{{ $t('save') }}</v-button>
                    </div>
                </div>
            </form>
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        projectId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            isModalActive: false,
            canCancel: ['escape', 'x', 'outside'],
            indirectCost: {
                code: '',
                name: ''
            },
            indirectCostId: null
        };
    },
    methods: {
        show(indirectCost) {
            this.indirectCost = { ...indirectCost };
            this.indirectCostId = indirectCost.id;
            this.isModalActive = true;
        },
        closeModal() {
            this.isModalActive = false;
        },
        async saveIndirectCost() {
            try {
                const response = await axios.put(`/api/v2/indirect-cost/${this.indirectCostId}`, {
                    code: this.indirectCost.code,
                    name: this.indirectCost.name,
                    project_id: this.projectId
                });

                if (response.data.success) {
                    this.$toast.open({
                        message: 'Indirect cost updated successfully',
                        type: 'is-success',
                        position: 'is-top-right'
                    });
                    this.$emit('reload-list');
                    this.closeModal();
                } else {
                    this.$toast.open({
                        message: 'Error updating indirect cost',
                        type: 'is-danger',
                        position: 'is-top-right'
                    });
                }
            } catch (error) {
                this.$toast.open({
                    message: 'Error updating indirect cost',
                    type: 'is-danger',
                    position: 'is-top-right'
                });
                console.error('Error updating indirect cost:', error);
            }
        }
    }
}
</script>

<style scoped>
/* Optional: Custom styling to adjust the form layout */
</style>
