<template>
    <div>
        <div class="export-button-container">
            <v-button @click="exportData" color="green">
                <span v-if="!isLoading">{{ $t('export') }}</span>
                <span v-else>
                    <i class="fa fa-spinner fa-spin"></i> {{ $t('loading') }}...
                </span>
            </v-button>
        </div>
        <div class="flex flex-row justify-center items-center">
            <img :src="sampleImg1" alt="Sample Image" class="" width="50%" />
            <img v-if="locale == 'es'"
                :src="'https://d1h4t2fnwpghfj.cloudfront.net/df1a23ec-8163-46fd-9828-9f30e0f3683c/img/dashboard2.png'"
                alt="produccion acumulada" class="" width="50%" />
        </div>

    </div>
</template>

<script>
import store from "~/store";
import axios from 'axios';
export default {
    props: {
        projectId: { type: Number, required: true },
    },
    data: () => ({
        locale: null,
        isLoading: false
    }),

    created() {
        this.locale = store.getters["lang/locale"];
    },

    computed: {
        sampleImg1() {
            return this.asset("public/dashboard-cost-estimate-sample-img-" + this.locale + ".png")
        },
    },
    methods: {
        async exportData() {
            this.isLoading = true;
            try {
                const response = await axios.post('/api/v2/export/cost-budget', {
                    project_id: this.projectId,
                    orderDir: 'asc',
                    orderField: 'id',
                    filters: {}
                }, {
                    responseType: 'blob'
                });

                // Crear un enlace para descargar el archivo
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'cost_budget.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error("Error al exportar datos:", error);
            }
            finally {
                this.isLoading = false;
            }
        }
    }

}
</script>

<style lang=" scss" scoped>

</style>
