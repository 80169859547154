<template>
  <div v-if="activity"> 

    <div class="panel">
      <div class="flex">
        <div class="mr-6">
          <b>{{$t('name')}}</b> {{activity.name}}
        </div>
        <div class="mr-6">
          <b>{{$t('start_date')}}</b> {{activity.current_start_date}}
        </div>
        <div class="mr-6">
          <b>{{$t('end_date')}}</b> {{activity.current_end_date}}
        </div>
      </div>

      <div class="mt-5">
        <b>Retraso:</b> {{activity.delayed_days}} días
      </div>
      
      <div class="flex mt-5">
        <div class="mr-8">
          <b>Predecesoras</b>
          <div v-for="pre in predecessors">
            {{pre}}
          </div>
        </div>
        <div class="mr-8">
          <b>Sucesoras</b>
          <div v-for="suc in successors">
            {{suc}}
          </div>
        </div>
      </div>

      <div class="w-full mt-5">
        <v-switch v-model="applyDelay" :label="'Aplicar Retraso'" :disabled="activity.delayed_days == 0"
          :color="applyDelay ? 'green' : 'red'" v-on:checked="(value) => applyDelay = value"
           />
      </div>

      <form @submit.prevent="sendForm"
        class="w-full flex sm:flex-row items-center justify-end mb-4 mt-4">
        <v-button type="submit" icon="thumbs-up" icon-pack="far" :disabled="activity.delayed_days == 0"
          class="mr-2">{{ $t('save') }}</v-button>
        <v-button color="grey" icon="undo-alt" @click="$router.go(-1)">Regresar</v-button>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import { mapGetters } from 'vuex'
import moment from 'moment'
import EventBus from '~/plugins/bus'

Vue.use(moment)

export default {
  middleware: 'auth',
  title: 'Prelaciones de Actividad',
  subtitle: 'Prelaciones de Actividad',
  menuOption: '2-2',

  components: {
  },

  data: () => ({
    activityId: null,
    activity: null,
    applyDelay: false,
    predecessors: [],
    successors: [],
  }),

  computed: {
    ...mapGetters({
      project: 'app/project',
      company: 'app/company',
    }),
  },

  created() {
    this.activityId = this.$route.params.activity_id
    this.getActivityData()
  },

  mounted() {

  },

  methods: {


    async getActivityData() {
        const { data } = await axios.get('/api/v2/activity/' + this.activityId + '?with_prelations=1')
        if (data && data.success) {
          this.activity = data.data

          this.successors = []
          this.predecessors = []

          this.getSuccessors(data.data.all_next_activities)
          this.getPredecessors(data.data.all_pre_activities)

        } else {
          this.$notify.error('error_loading_activity_data')
        }
    },

    async sendForm() {
      if (this.applyDelay) {
        const { data } = await axios.post('/api/v2/activity/' + this.activityId + '/apply-delay', {})
        if (data && data.success) {
          this.$notify.success("retraso aplicado correctamente");
        }
      }
    },

    getSuccessors(data) {
      for (let suc of data) {
        this.successors.push(suc.name)
        this.getSuccessors(suc.all_next_activities)
      }
    },

    getPredecessors(data) { 
      for (let pre of data) {
        this.predecessors.push(pre.name)
        this.getPredecessors(pre.all_pre_activities)
      }
    }
  }
};
</script>
