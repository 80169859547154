<template>
  <div>
    <!-- Filtros -->
    <activity-filters :projectId="project.id"
      @filters-updated="filtersUpdated"
      ref="filtersRef"/>

    <div class="flex w-full flex-wrap flex-col sm:flex-row relative">
      <!-- Filtro por subactividades -->
      <div class="flex sm:w-1/4">
        <v-switch
          :disabled="isLoadingTable"
          :focusable="! isLoadingTable"
          :label="$t('filter_by_subactivities')"
          :color="filterSubactivities ? 'green' : 'red'"
          :value="filterSubactivities"
          v-on:checked="checkFilterSubactivities"
          style="margin-left: 5px"
        />
      </div>

      <!-- Botones, sólo Jefes de departamento -->
      <div class="flex w-full sm:w-3/4">
        <quick-button-menu :buttons="quickButtonMenu()">
          <template v-if="project.user_department.can_admin || true"> <!-- always show -->
            <project-report v-if="!isLoadingTable" :filters="this.generatedFilters" :openWithButton="false"/>
            <project-report-excel v-if="showExcelReport" :openWithButton="false"/>
          </template>
          <!-- Botón Lotes canal exportar CSV proyecto -->
          <project-report-lotes v-if="showLotesReport" :openWithButton="false"/>
        </quick-button-menu>
      </div>
    </div>


    <div class="text-sm panel">

      <!--TODO: Eliminar este fragmento de codigo luego de que este en produccion no es necesario-->
      <!-- <b-field grouped group-multiline class="flex w-full">
        <div v-for="(column, index) in columns"
             :key="index"
             class="control">
          <b-checkbox v-model="column.visible" class="text-xs">
            <span>{{ $t(column.title) }}</span>
          </b-checkbox>
        </div>
      </b-field> -->

      <div class="mb-3">
        <multiselect v-model="columnsSelected"
          :options="_columns"
          :multiple="true"
          :deselect-label="$t('multiselect_deselect_label')"
          :select-label="$t('multiselect_select_label')"
          :selected-label="$t('multiselect_selected_label')"
          :show-labels="true"
          :hide-selected="false"
          :limit-text="count => `y ${count} más`"
          label="title"
          track-by="title"
          :close-on-select="true" :clear-on-select="false" :preserve-search="true"
          :max-height="150"
          :allow-empty="true"
          :limit="limitShowColumnsSelect"
          @select="_HandleSelectItem"
          @remove="_HandleRemoveItem">
        </multiselect>
      </div>


      <template>
        <!-- Contador de resultados -->
        <div class="flex w-full flex-col mb-3">
          <div class="flex flex-row-reverse">
            <div>
              <b-select v-model="perPage" @input="filtersUpdated">
                <option value="10">{{ $t('per_page', {amount: 10}) }}</option>
                <option value="25">{{ $t('per_page', {amount: 25}) }}</option>
                <option value="50">{{ $t('per_page', {amount: 50}) }}</option>
              </b-select>
            </div>
          </div>
          <div v-if="total" class="w-full text-right">
            <b>{{ $t('count_results') }}: {{ total }}</b>
          </div>
        </div>

        <b-table
          :data="list"
          :loading="isLoadingTable"

          paginated
          backend-pagination
          :per-page="perPage"
          :current-page.sync="currentPage"
          :total="total"
          @page-change="onPageChange"

          backend-sorting
          :default-sort="orderField"
          :default-sort-direction="orderDir"
          @sort="onSort"

          detailed
          detail-key="id"
          icon-pack="fas"
          :has-detailed-visible="displaySubactivitiesOrAssignments"
          :opened-detailed-ANULADO="[this.openedSubactivitiesId]"
          @details-open="openDetails"
          @details-close="(row, index) => this.openedSubactivitiesId = 0"

          class="text-xs border-blue-lighter"
          bordered
          narrowed
          striped
          :row-class="getRowClass"

          :sticky-header="stickyHeader"
          :mobile-cards="false"
          @select="handleRowSelected"
        >

          <template slot="empty">
            <div v-if="! isLoadingTable" class="m-4 text-lg text-dark-grey">
              <span>{{ $t('empty_activities_table_msg') }}</span>
            </div>
          </template>

          <template>

            <b-table-column field="star" cell-class="w-8 vertical-center" centered sortable>
              <template v-slot:header="">
                <i class="fas fa-star"></i>
              </template>
              <template v-slot="activities">
                <div v-if="!activities.row.favorite" @click="addFavorite(activities.row.id, activities.index)"
                    class="cursor-pointer">
                  <b-icon icon="star" pack="fas" size="is-small" class="text-grey"/>
                </div>

                <div v-if="activities.row.favorite" @click="deleteFavorite(activities.row.id, activities.index)"
                    class="cursor-pointer">
                  <b-icon icon="star" pack="fas" size="is-small" class="text-yellow-dark"/>
                </div>
              </template>
            </b-table-column>

            <b-table-column field="in_planning" label="P" :visible="columns['in_planning'].visible"
                            cell-class="w-8 vertical-center" centered sortable>
              <template v-slot:header="">
                <i class="fas fa-road"></i>
              </template>
              <template v-slot="activities">
                <div v-if="activities.row.in_planning">
                  <b-tooltip :label="$t('in_planning')">
                    <b-icon icon="road" pack="fas" size="is-small" class="text-green"/>
                  </b-tooltip>
                </div>
                <div v-if="!activities.row.in_planning">
                  <b-tooltip :label="$t('out_planning')">
                    <b-icon icon="road" pack="fas" size="is-small" class="text-grey"/>
                  </b-tooltip>
                </div>
              </template>
            </b-table-column>

            <b-table-column field="mobile" cell-class="w-8 vertical-center" centered sortable>
              <template v-slot:header="">
                <i class="fas fa-mobile-alt"></i>
              </template>
              <template v-slot="activities">
                <div v-if="!activities.row.show_on_devices" @click="showOnDevice(activities.row.id, activities.index)"
                    :class="{'cursor-pointer': project.can_admin}">
                  <b-icon icon="mobile-alt" pack="fas" size="is-small" class="text-grey"/>
                </div>

                <div v-if="activities.row.show_on_devices" @click="hideOnDevice(activities.row.id, activities.index)"
                    class="{'cursor-pointer': project.can_admin}">
                  <b-icon icon="mobile-alt" pack="fas" size="is-small" class="text-green"/>
                </div>
              </template>
            </b-table-column>

            <b-table-column field="group" cell-class="w-8 vertical-center" centered sortable>
              <template v-slot:header="">
                <i class="fas fa-layer-group"></i>
              </template>
              <template v-slot="activities">
                <div v-if="activities.row.group"
                  class="relative inline-flex items-center justify-center cursor-pointer w-7 h-7 overflow-hidd rounded-full"
                    :style="{'background-color' : colorGroup(activities.row.group) }">
                  <b-tooltip :label="activities.row.group.name"
                    position="is-right"
                    multilined>
                    <span class="font-medium text-white">{{ nameGroup(activities.row.group) }}</span>
                    <template v-slot:content>
                      <div><b>Grupo: </b> {{ activities.row.group.name }}</div>
                    </template>
                  </b-tooltip>
                </div>
                <!-- <div v-else
                  class="relative inline-flex items-center justify-center w-7 h-7 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                  <b-tooltip :label="$t('without_group')"
                    position="is-right">
                    <span class="font-medium text-gray-600 dark:text-gray-300">SG</span>
                  </b-tooltip>
                </div> -->
              </template>
            </b-table-column>

            <b-table-column field="user" cell-class="w-8 vertical-center" label="" :visible="columns['user'].visible"
                            centered>
              <template v-slot:header="">
                <i class="fas fa-user"></i>
              </template>
              <template v-slot="activities">
                <div class="relative inline-flex items-center justify-center cursor-pointer w-7 h-7 overflow-hidd rounded-full">
                  <b-tooltip v-if="activities.row.manager && activities.row.manager.user_id"
                            :label="activities.row.manager.fullname">
                    <img
                      :src="activities.row.manager.avatar"
                      class="w-7 h-7 overflow-hidden rounded-full">
                  </b-tooltip>
                </div>
              </template>
            </b-table-column>

            <b-table-column field="planning_code" :visible="columns['planning_code'].visible" :label="$t('code')"
                            sortable cell-class="vertical-center" v-slot="activities" :sticky="true">
              <div class="cursor-pointer" @click="onRowClicked(activities.row.is_subactivity, activities.row.parent_id, activities.row.id)">
                {{ activities.row.planning_code }}
              </div>
            </b-table-column>

            <b-table-column field="name" :visible="columns['name'].visible" :label="$t('name')" sortable
                            cell-class="vertical-center" v-slot="activities">
                <div class="cursor-pointer"
                     @click="onRowClicked(activities.row.is_subactivity, activities.row.parent_id, activities.row.id)">
                  <activity-time-status :date="activities.row.current_end_date" class="mr-2"
                                        :status="activities.row.status"
                                        v-if="activities.row.status != 4 && activities.row.status != 6"/>
                  <b-tooltip v-if="activities.row.critical_path != null" position="is-right"
                             :label="$t('critical_path')">
                    <b-icon class="fa is-small fa-project-diagram" style="color:#e3342f"></b-icon>
                  </b-tooltip>
                  <template v-if="activities.row.parent !== null">
                    <b-tooltip :label="activities.row.parent !== null ? activities.row.parent.name : ''" position="is-top"
                          multilined>
                    {{ activities.row.name }}
                    </b-tooltip>
                  </template>
                  <template v-else>
                    {{ activities.row.name }}
                  </template>
                </div>
            </b-table-column>

            <b-table-column field="location" :visible="columns['location'].visible" :label="$t('location')"
                            sortable cell-class="vertical-center" v-slot="activities">
              <div>
                <span v-if="activities.row.location">
                  {{ activities.row.location.name }}
                </span>
              </div>
            </b-table-column>

            <b-table-column field="type" :visible="columns['type'].visible" :label="$t('type')"
                            sortable cell-class="vertical-center" v-slot="activities">
              <div>
                <span v-if="activities.row.activityType">
                  {{ activities.row.activityType.name }}
                </span>
              </div>
            </b-table-column>

            <b-table-column field="progress" :label="$t('progress_label')" :visible="columns['progress'].visible"
                            cell-class="w-32 vertical-center" centered sortable v-slot="activities">
              <progress-bar
                :step="activities.row.progress"
                color="blue-light"
                class="w-full"
              />
            </b-table-column>

            <b-table-column field="current_start_date" :label="$t('start')"
                            :visible="columns['current_start_date'].visible" cell-class="w-25 vertical-center" sortable
                            centered v-slot="activities">
              {{ activities.row.current_start_date }}
            </b-table-column>

            <b-table-column field="current_end_date" :label="$t('end')" :visible="columns['current_end_date'].visible"
                            cell-class="w-25 vertical-center" sortable centered v-slot="activities">
              {{ activities.row.current_end_date }}
            </b-table-column>

            <b-table-column field="status" :label="$t('status')" cell-class="w-32 vertical-center"
                            :visible="columns['status'].visible" sortable centered v-slot="activities">
              <div class="relative  overflow-hidd">
                <activity-status-label :status=" activities.row.status == 7 && ignore_delayed_state ? 1 : activities.row.status "
                  fontSize="0.65"
                  class="w-full"
                  style="vertical-align: middle"/>
              </div>
            </b-table-column>

            <b-table-column v-if="hasStatusList()" field="status" :label="$t('custom_status')"
                            cell-class="w-32 vertical-center"
                            :visible="columns['status_id'].visible"
                            sortable centered v-slot="activities">
              {{ getStatusIdName(activities.row.status_id) }}
            </b-table-column>

            <b-table-column field="actions" label="" cell-class="w-12 vertical-center"
                            sortable centered>
              <template v-slot="activities">
                <div @click="goToPrelations(activities.row)">
                  <b-tooltip :label="'Ver Prelaciones'" position="is-top">
                    <b-icon icon="shuffle" pack="fas" size="is-small" class="mr-1" v-bind:class="[hasPrelations(activities.row) ? 'text-blue' : 'text-grey-light']"/>
                  </b-tooltip>
                </div>
              </template>
            </b-table-column>

            <b-table-column field="notifications" :label="$t('notifications')"
                            :visible="columns['notifications'].visible" cell-class="w-12 vertical-center"
                            sortable centered>
              <template v-slot:header="">
                <i class="fas fa-bell"></i>
              </template>
              <template v-slot="activities">
                <entity-notifications
                  :entityId="activities.row.id"
                  entityType="activity"
                  :mentions="activities.row.notifications.mentions"
                  :change_status="activities.row.notifications.change_status"
                  :comments="activities.row.notifications.comments"
                  :documents="activities.row.notifications.documents"
                  :assignments_warnings="activities.row.notifications.assignments_warnings"
                  :enabledChangedStatus="true"
                />
              </template>
            </b-table-column>

            <b-table-column field="id" numeric cell-class="is-hidden" :visible="false" v-slot="activities">
              {{ activities.row.id }}
            </b-table-column>

          </template>

          <template slot="detail" slot-scope="activities">

            <!--Mediciones -->
            <div class="mb-1" v-if="displayAssignments(activities.row)">
              <b-collapse
                class="card"
                animation="slide"
                aria-id="contentIdForA11y3"
                :open="false"
                @open="openDetailsSubactivity(activities.row, 0)">
                <template #trigger="props">
                  <div
                    class="card-header"
                    role="button"
                    aria-controls="contentIdForA11y3"
                    :aria-expanded="props.open">
                    <div class="card-header-title">
                      <h4>
                        <b>{{ $t('measurements') }}</b>
                      </h4>
                    </div>
                    <a class="card-header-icon">
                        <b-icon
                            :icon="props.open ? 'caret-down' : 'caret-up'">
                        </b-icon>
                    </a>
                  </div>
                </template>

                <div class="card-content p-0">
                  <div class="content">
                    <assignment-table :ref="'assignments_' + activities.row.id"
                      :list="activities.row.assignments"
                      :entityId="activities.row.id"
                      :entityBlockedAssignments="activities.row.blocked_assignments == 1"
                      :apiProject="apiProject"
                      @reload="reloadActivities"/>
                  </div>
                </div>
                <!-- <footer class="card-footer">
                    <a class="card-footer-item">Save</a>
                    <a class="card-footer-item">Edit</a>
                    <a class="card-footer-item">Delete</a>
                </footer> -->
              </b-collapse>
            </div>

            <!-- Subactividades -->
            <div v-if="displaySubactivities(activities.row)">
              <b-collapse
                class="card"
                animation="slide"
                aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div
                        class="card-header"
                        role="button"
                        aria-controls="contentIdForA11y3"
                        :aria-expanded="props.open">

                        <div class="card-header-title">
                          <h4>
                            <b>{{ activities.row.subactivities_num }} {{
                                activities.row.subactivities_num > 1 ?
                                  $t('subactivities') : $t('subactivity')
                              }}</b>
                          </h4>
                        </div>

                        <a class="card-header-icon">
                            <b-icon icon-pack="fas"
                                :icon="props.open ? 'caret-down' : 'caret-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content p-0">
                    <div class="content">

                      <template>
                        <b-table
                          ref="subactivities"
                          :data="getSubactivities(activities.row)"
                          default-sort="planning_code"
                          default-sort-direction="asc"
                          bordered
                          class="text-xs border-blue-lighter bg-grey-lighter subactivities"
                          narrowed

                          detailed
                          detail-key="id"
                          icon-pack="fas"
                          :has-detailed-visible="displaySubactivitiesOrAssignments"

                          @details-open="openDetailsSubactivity"

                          :row-class="getSubRowClass"
                          @select="handleRowSelected">

                          <template>

                            <b-table-column field="star" cell-class="w-8 vertical-center" centered sortable
                                            :custom-sort="sortByFavorite">
                              <template v-slot:header="">
                                <i class="fas fa-sm fa-star"></i>
                              </template>
                              <template v-slot="subactivities_info">
                                <div v-if="!subactivities_info.row.favorite"
                                    @click="addFavorite(subactivities_info.row.id, subactivities_info.index, true)"
                                    class="cursor-pointer">
                                  <b-icon icon="star" pack="fas" size="is-small" class="text-grey"/>
                                </div>
                                <div v-if="subactivities_info.row.favorite"
                                    @click="deleteFavorite(subactivities_info.row.id, subactivities_info.index, true)"
                                    class="cursor-pointer">
                                  <b-icon icon="star" pack="fas" size="is-small" class="text-yellow-dark"/>
                                </div>
                              </template>
                            </b-table-column>

                            <b-table-column field="in_planning" :visible="columns['in_planning'].visible" label="P"
                                            cell-class="w-8 vertical-center" centered sortable >
                              <template v-slot:header="">
                                <i class="fas fa-sm fa-road"></i>
                              </template>
                              <template v-slot="subactivities_info">
                                <div v-if="subactivities_info.row.in_planning">
                                  <b-tooltip :label="$t('in_planning')">
                                    <b-icon icon="road" pack="fas" size="is-small" class="text-green"/>
                                  </b-tooltip>
                                </div>
                                <div v-if="!subactivities_info.row.in_planning">
                                  <b-tooltip :label="$t('out_planning')">
                                    <b-icon icon="road" pack="fas" size="is-small" class="text-grey"/>
                                  </b-tooltip>
                                </div>
                              </template>
                            </b-table-column>

                            <b-table-column field="mobile" cell-class="w-8 vertical-center" centered sortable
                                            :custom-sort="sortByShowOnDevices">
                              <template v-slot:header="">
                                <i class="fas fa-sm fa-mobile-alt"></i>
                              </template>
                              <template v-slot="subactivities_info">
                                <div v-if="!subactivities_info.row.show_on_devices"
                                    @click="showOnDevice(subactivities_info.row.id, subactivities_info.index, true)"
                                    :class="{'cursor-pointer': project.can_admin}">
                                  <b-icon icon="mobile-alt" pack="fas" size="is-small" class="text-grey"/>
                                </div>
                                <div v-if="subactivities_info.row.show_on_devices"
                                    @click="hideOnDevice(subactivities_info.row.id, subactivities_info.index, true)"
                                    :class="{'cursor-pointer': project.can_admin}">
                                  <b-icon icon="mobile-alt" pack="fas" size="is-small" class="text-green"/>
                                </div>
                              </template>
                            </b-table-column>

                            <b-table-column field="user" :visible="columns['user'].visible" label=""
                                            cell-class="w-12 vertical-center" centered >
                              <template v-slot:header="">
                                <i class="fa fa-sm fa-user"></i>
                              </template>
                              <template v-slot="subactivities_info">
                                <b-tooltip v-if="subactivities_info.row.manager" :label="subactivities_info.row.manager.fullname">
                                  <img :src="subactivities_info.row.manager.avatar"
                                      class="w-6 h-6 overflow-hidden rounded-full reduce">
                                </b-tooltip>
                              </template>
                            </b-table-column>

                            <b-table-column field="planning_code" :visible="columns['planning_code'].visible"
                                        sortable cell-class="vertical-center">
                              <template v-slot:header="">
                                {{ $t('code') }}
                              </template>
                              <template v-slot="subactivities_info">
                                <div :class="subactivities_info.row.access ? 'cursor-pointer' : ''"
                                    @click="onRowClicked(subactivities_info.row.is_subactivity, subactivities_info.row.parent_id, subactivities_info.row.id, subactivities_info.row.access)"
                                  >{{ subactivities_info.row.planning_code }}</div>
                              </template>
                            </b-table-column>

                            <b-table-column field="name" :visible="columns['name'].visible"
                                            cell-class="vertical-center" sortable>
                              <template v-slot:header="">
                                {{ $t('name') }}
                              </template>
                              <template v-slot="subactivities_info">
                                <div :class="subactivities_info.row.access ? 'cursor-pointer' : ''"
                                    @click="onRowClicked(subactivities_info.row.is_subactivity, subactivities_info.row.parent_id, subactivities_info.row.id, subactivities_info.row.access)">
                                  <activity-time-status :date="subactivities_info.row.current_end_date" class="mr-2 reduce"
                                                        :status="subactivities_info.row.status"
                                                        v-if="subactivities_info.row.status != 4 && subactivities_info.row.status != 6"/>
                                  <b-tooltip v-if="subactivities_info.row.critical_path != null" position="is-right"
                                    :label="$t('critical_path')">
                                    <b-icon class="fa is-small fa-project-diagram" style="color:#e3342f"></b-icon>
                                  </b-tooltip>
                                  {{ subactivities_info.row.name }}
                                </div>
                              </template>
                            </b-table-column>

                            <b-table-column field="location" :visible="columns['location'].visible"
                                            cell-class="w-25 vertical-center" centered sortable>
                              <template v-slot:header="">
                                {{ $t('location') }}
                              </template>
                              <template v-slot="subactivities_info">
                                <span v-if="subactivities_info.row.location">
                                  {{subactivities_info.row.location.name }}
                                </span>
                              </template>
                            </b-table-column>

                            <b-table-column field="type" :visible="columns['type'].visible"
                                            cell-class="w-25 vertical-center" centered sortable>
                              <template v-slot:header="">
                                {{ $t('type') }}
                              </template>
                              <template v-slot="subactivities_info">
                                <span v-if="subactivities_info.row.activityType">
                                  {{subactivities_info.row.activityType.name }}
                                </span>
                              </template>
                            </b-table-column>

                            <b-table-column field="progress" :visible="columns['progress'].visible"
                                            cell-class="w-32 vertical-center" centered sortable>
                              <template v-slot:header="">
                                {{ $t('progress_label') }}
                              </template>
                              <template v-slot="subactivities_info">
                                <progress-bar
                                  :step="subactivities_info.row.progress"
                                  color="blue-light"
                                  class="w-full"
                                />
                              </template>
                            </b-table-column>

                            <b-table-column field="current_start_date" :visible="columns['current_start_date'].visible"
                                            cell-class="w-25 vertical-center" centered sortable>
                              <template v-slot:header="">
                                {{ $t('start') }}
                              </template>
                              <template v-slot="subactivities_info">
                              {{ subactivities_info.row.current_start_date }}
                              </template>
                            </b-table-column>

                            <b-table-column field="current_end_date" :visible="columns['current_end_date'].visible"
                                            cell-class="w-25 vertical-center" centered sortable>
                              <template v-slot:header="">
                                {{ $t('end') }}
                              </template>
                              <template v-slot="subactivities_info">
                                {{ subactivities_info.row.current_end_date }}
                              </template>
                            </b-table-column>

                            <b-table-column field="status" :visible="columns['status'].visible"
                                            cell-class="w-32 vertical-center" centered sortable>
                              <template v-slot:header="">
                                {{ $t('status') }}
                              </template>
                              <template v-slot="subactivities_info">
                                <activity-status-label
                                  :status=" subactivities_info.row.status == 7 && ignore_delayed_state ? 1 : subactivities_info.row.status"
                                  class="w-full"/>
                              </template>
                            </b-table-column>
                              <b-table-column field="actions" label="" cell-class="w-12 vertical-center" sortable centered>
                              <template v-slot="subactivities_info">
                                <div @click="goToPrelations(subactivities_info.row)">
                                  <b-tooltip :label="'Ver Prelaciones'" position="is-top">
                                    <b-icon icon="shuffle" pack="fas" size="is-small" class="mr-1" v-bind:class="[hasPrelations(subactivities_info.row) ? 'text-blue' : 'text-grey-light']"/>
                                  </b-tooltip>
                                </div>
                              </template>
                            </b-table-column>

                            <b-table-column field="notifications" :visible="columns['notifications'].visible"
                                            cell-class="w-24 vertical-center" centered sortable :custom-sort="sortByNotifications">
                              <template v-slot:header="">
                                <i class="fa fa-bell"></i>
                              </template>
                              <template v-slot="subactivities_info">
                                <entity-notifications
                                  :entityId="subactivities_info.row.id"
                                  entityType="activity"
                                  :mentions="subactivities_info.row.notifications.mentions"
                                  :change_status="subactivities_info.row.notifications.change_status"
                                  :comments="subactivities_info.row.notifications.comments"
                                  :documents="subactivities_info.row.notifications.documents"
                                  :assignments_warnings="subactivities_info.row.notifications.assignments_warnings"
                                  :enabledChangedStatus="true"
                                />
                              </template>
                            </b-table-column>

                          </template>

                          <template slot="detail" slot-scope="child">
                            <!--Mediciones -->
                            <div class="mb-1" v-if="displayAssignments(child.row)">
                              <div>
                                <h4>
                                  <b>{{ $t('measurements') }}</b>
                                </h4>
                              </div>

                              <assignment-table :ref="'assignments_' + child.row.id" :list="child.row.assignments" :entityId="child.row.id"
                                                :entityBlockedAssignments="child.row.blocked_assignments == 1"
                                                :apiProject="apiProject"
                                                @reload="reloadSubActivities"/>
                            </div>
                          </template>
                        </b-table>
                      </template>

                    </div>
                </div>
                <!-- <footer class="card-footer">
                </footer> -->
              </b-collapse>
            </div>
          </template>
        </b-table>
      </template>
    </div>

    <!-- Actualiza la tabla automáticamente, de momento se queda -->
    <!-- <mugen-scroll :handler="fetchData" :should-handle="!isLoadingTable" scroll-container="wrap"></mugen-scroll>
    <b-loading :is-full-page="false" :active.sync="isLoadingTable" :can-cancel="false"></b-loading> -->

  </div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import {mapGetters} from 'vuex'
import Form from 'vform'
import activityTimeStatus from '·/components/activity-time-status'
import activityStatusLabel from '·/components/activity-status-label'
import assignmentTable from '·/components/assignment-table'
import projectReport from '·/components/project-report'
import projectReportExcel from '·/components/project-report-excel'
import projectReportLotes from '·/components/project-report-lotes'
import moment from 'moment'
import entityNotifications from '·/components/entity-notifications'
import MugenScroll from 'vue-mugen-scroll'
import Buefy from 'buefy'
import BIcon from "buefy/src/components/icon/Icon";
import EventBus from '~/plugins/bus'
import ActivityFilters from '·/components/activity-filters';
import Multiselect from 'vue-multiselect'
import QuickButtonMenu from '../../components/quick-button-menu/quick-button-menu.vue'

Vue.use(moment);
Vue.use(Buefy);

export default {
  middleware: 'auth',
  title: 'activities_page_title',
  subtitle: 'activities_page_subtitle',
  menuOption: '2-2',

  components: {
    BIcon,
    activityTimeStatus,
    activityStatusLabel,
    entityNotifications,
    projectReport,
    projectReportExcel,
    projectReportLotes,
    assignmentTable,
    MugenScroll,
    ActivityFilters,
    Multiselect,
    QuickButtonMenu
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      project: 'app/project',
      company: 'app/company',
      visualConfig: 'app/visualConfig',
      mobile: 'device/mobile'
    }),

    _columns: function() {
      // return Object.values(this.columns)
      // Object.keys(myObject).map(key => myObject[key]);
      return Object.entries(this.columns).map(([key, value]) => ({ key, title: this.$t(value.title), visible: value.visible }));
    },

    stickyHeader() { return this.mobile.on_mobile}, // para fiar el header de la tabla cuando se esta mostrando en movil
    limitShowColumnsSelect() { // cantidad de columnas visibles seleccionadas a mostrar en el multiselect por defecto  8
      return this.mobile.on_mobile ? 3 : 8
    }
  },

  data: () => ({
    form: new Form({
      creator: 'Todos',
      fecha: '1 Enero 2018 - 31 Diciembre 2018',
    }),
    // paginationRows: 10,
    isLoadingTable: true,
    page: 1,
    last: 1,
    perPage: 25,
    currentPage: 1,
    total: 0,
    orderField: "planning_code",
    orderDir: "asc",
    list: [],
    filterSubactivities: false,
    showSubs: false,
    openedSubactivitiesId: 0,
    subactivities: [],
    showFilters: false,
    generatedFilters: {}, // objeto con los filtros generados por el componente 'activity-filters'
    columns: {
      'in_planning': {title: 'in_planning', visible: true},
      'user': {title: 'manager', visible: true},
      'planning_code': {title: 'code', visible: true},
      'name': {title: 'name', visible: true},
      'location': {title: 'location', visible: true},
      'type': {title: 'type', visible: true},
      'progress': {title: 'progress', visible: true},
      'current_start_date': {title: 'start_date', visible: true},
      'current_end_date': {title: 'end_date', visible: true},
      'status': {title: 'status', visible: true},
      'status_id': {title: 'custom_status', visible: true},
      'notifications': {title: 'notifications', visible: true},
    },
    showExcelReport: false,
    showLotesReport: false,
    childStatusList: [], // lista status de componente activity-filters
    apiProject: {}, // algunas caracteristicas del proyecto actual devueltas por api (si user es certificador)
    columnsSelected: [],

    ignore_delayed_state: false,
    lastRow: null,
  }),

  mounted() {

    this.columnsSelected = this._columns.filter(column => column.visible == true)

    // recupera de la localstore la pagina que estamos (util al volver de una validacion al listado)
    this.page = this.$store.getters["searchFilters/activities"].page || 1
    this.last = this.$store.getters["searchFilters/activities"].last || 1

    this.applyFilters(this.page);
    let self = this;

    // Muestra columna "mis estados" cuando el componente hijo 'activity-filters' recibe por api lista statusList
    EventBus.$on("statusListReceived", function (statusList) {
      self.childStatusList = statusList
    });

    // Si recibimos el evento refresh, refrescamos el componente
    EventBus.$on('refreshActivitiesNotifications', function (e) {
      self.isLoading = true;
      self.applyFilters(self.page);
    });

    // Si recibimos el evento refresh, refrescamos el componente
    EventBus.$on('refreshSubactivitiesNotifications', function (e) {
      self.isLoading = true;
      self.applyFilters(self.page);
    });
  },

  beforeDestroy(){
    EventBus.$off("statusListReceived");
    EventBus.$off("refreshActivitiesNotifications");
    EventBus.$off("refreshSubactivitiesNotifications");
  },

  created() {
    this.updateColumnsConfig()
    this.showExcelReport = (window.location.hostname.includes('test') ||
      window.location.hostname.includes('ignis') ||
      this.user.company.id == 16 ||
      this.company.alias == 'tsk' ||
      this.company.alias == 'x-elio')
      ? true : false

    this.showLotesReport = this.company.configs && this.company.configs.loteCanal

    this.ignore_delayed_state = this.visualConfig?.ignore_delayed_state
  },

  methods: {
    async getActivitiesList(args = []) {

      const {data} = await axios.get('/api/v2/activities', {params: args});
      // Last and total
      this.last = data.lastPage;
      this.total = data.total;
      this.apiProject = data.project;
      // guarda en la localstore la ultima pagina
      this.$store.dispatch('searchFilters/setActivitiesFilters', { last: this.last });

      if (data && data.activities) {
        //deducimos si no tiene status kanban aplicados y los ocultamos
        if (!this.hasStatusList())
          delete this.columns.status_id;

        this.list = this.activitiesRemapping(data.activities);
      } else {
        this.$notify.error('error_loading_activities')
      }

      this.isLoadingTable = false
    },

    /**
     * Remapea la actividad para esta tabla
     */
    activitiesRemapping(activities) {
      for (let a in activities) {
        if (activities[a].assignments && activities[a].assignments.length > 0) {
          activities[a].alert = 0;
          for (let i in activities[a].assignments) {
            let number = this.getAssignmentExecutionStatus(activities[a].assignments[i]);
            activities[a].assignments[i].execution_status = number;

            if (activities[a].alert !== number && activities[a].alert !== 1)
              activities[a].alert = number;

          }
        }
      }
      return activities;
    },

    filtersUpdated() {
      this.isLoadingTable = true;
      this.applyFilters(1);
    },

    customizeFilters() {
      let filters = this.$refs.filtersRef.generateFilters();
      // ya no mandamos este parametro para que no cargue las mediciones parte de una optimización de carga del listado
      // filters.with_assignments = 1;
      filters.order_field = this.orderField;
      filters.order_dir = this.orderDir;
      filters.per_page = this.perPage;
      //filters.s = 1; //con subactividades. Como se piden de todas formas al desplegar, se quitan de aqui para aligerar carga

      if (this.filterSubactivities) {
        filters.filter_subactivities = 1
      }
      filters.with_location = 1
      filters.with_prelations = 1

      return filters;
    },

    onRowClicked(isSubactivity, activityId, subactivityId, access = true) {
      if (access) {
        if (!isSubactivity) {
          this.$router.push({name: 'activity.home', params: {activity_id: activityId}})
        } else {
          this.$router.push({
            name: 'subactivity.home',
            params: {activity_id: activityId, subactivity_id: subactivityId}
          })
        }
      }
    },

    onCreateActivityClicked() {
      let url = '/api/v2/locations?all=1&p=' + this.project.id;
      self = this;
      axios.get(url)
        .then(function (response) {
          if (response.data && response.data.success && response.data.locations) {
            self.$router.push({name: 'activity.create'})
          } else {
            self.$notify.error('project_without_locations_create_activity')
          }
        });
    },

    displayKanban() {
      this.$router.push({name: 'project.activities.kanban'});
    },

    displayGantt() {
      this.$router.push({name: 'project.activities.gantt'});
    },

    async addFavorite(activityId, index, isSubactivity = false) {
      let url = '/api/v2/user/' + activityId + '/favorite/add';
      const {data} = await axios.post(url);
      if (data && data.success) {
        //refrescamos la tabla
        if (isSubactivity) {
          // Recorremos las subactividades de la actividad abierta y buscamos la subactividad para actualizarla
          // No podemos utilizar el index ya que si las subactividades han sido reordenadas éste se queda desfasado
          let i = 0;
          let found = false;
          while (!found && i <= this.subactivities[this.openedSubactivitiesId].length) {
            if (this.subactivities[this.openedSubactivitiesId][i].id === activityId) {
              this.subactivities[this.openedSubactivitiesId][i].favorite = true;
              found = true
            }
            i++
          }

        } else {
          this.list[index].favorite = true;
        }

      } else {
        this.$notify.error('error_add_favorite')
      }
    },

    async deleteFavorite(activityId, index, isSubactivity = false) {
      let url = '/api/v2/user/' + activityId + '/favorite/delete';
      const {data} = await axios.post(url);
      if (data && data.success) {
        //refrescamos la tabla
        if (isSubactivity) {
          // Recorremos las subactividades de la actividad abierta y buscamos la subactividad para actualizarla
          // No podemos utilizar el index ya que si las subactividades han sido reordenadas éste se queda desfasado
          let i = 0;
          let found = false;
          while (!found && i <= this.subactivities[this.openedSubactivitiesId].length) {
            if (this.subactivities[this.openedSubactivitiesId][i].id === activityId) {
              this.subactivities[this.openedSubactivitiesId][i].favorite = false
              found = true
            }
            i++
          }
        } else {
          this.list[index].favorite = false;
        }
      } else {
        this.$notify.error('error_add_favorite')
      }
    },

    async createProjectDossier() {
      //TO-DO: realizar llamada al generador de informes para el proyecto this.project.id
      return true
    },

    showSubactivities(activityId) {
      this.showSubs = (!this.showSubs);
    },

    applyFilters(page) {
      this.generatedFilters = this.customizeFilters();
      this.generatedFilters.page = (page <= this.last) ? page : 1;
      // actualiza en localstore la pagina actual
      this.$store.dispatch('searchFilters/setActivitiesFilters', { page: this.generatedFilters.page });
      this.currentPage = this.generatedFilters.page;
      this.getActivitiesList(this.generatedFilters)
    },

    fetchData() {
      if (this.page <= this.last) {
        this.isLoadingTable = true;
        this.applyFilters(this.page);
        // this.isLoadingTable = false. Se hace dentro de getActivitiesList
      }
    },

    onPageChange(page) {
      // guarda en la localstore la pagina que estamos
      this.$store.dispatch('searchFilters/setActivitiesFilters', { page: page });
      this.openedSubactivitiesId = 0;
      this.page = page;
      this.fetchData()
    },

    onSort(field, order) {
      this.openedSubactivitiesId = 0;
      this.orderField = field;
      this.orderDir = order;
      this.fetchData()
    },

    displaySubactivities(row) {
      return !row.isSubactivity && row.subactivities_num > 0
    },

    displayAssignments(row) {
      return row.assignments_num > 0;
      // return (row.assignments && row.assignments.length > 0);
    },

    displaySubactivitiesOrAssignments(row) {
      return !!(this.displayAssignments(row) || this.displaySubactivities(row));
    },

    openDetails(row, index) {
      if (typeof this.subactivities[row.id] === 'undefined') {
        this.isLoadingTable = true;
        let self = this;
        let url = '/api/v2/activity/' + row.id + '/subactivities';
        // let params = {with_assignments: 1};
        let params = {
          with_location: 1,
          with_prelations: 1
        }; // para optimizar la carga solo cargamos las actividades
        axios.get(url, {params: params}).then(function (response) {
          if (response.data && response.data.success) {
            self.subactivities[row.id] = response.data.subactivities
          }
          self.isLoadingTable = false;
          self.openedSubactivitiesId = row.id
          self.handleRowSelected(self.lastRow, true)
        })
      } else {
        self.isLoadingTable = false;
        self.openedSubactivitiesId = row.id
        this.handleRowSelected(this.lastRow, true)
      }
    },

    openDetailsSubactivity(row, index) {
      this.$nextTick(() => {
        // The whole view is rendered, so I can safely access or query
        // the DOM. ¯\_(ツ)_/¯
        this.$refs['assignments_' + row.id].loadAssigments(row.id)
      })
    },

    getSubactivities(row) {
      return this.subactivities[row.id]
    },

    getRowClass(row, index) {
      // (row, index) => ! row.parent_access ? '' : 'is-hidden'
      let cssClass = 'row_' + row.id;
      if (row.parent_access && !this.filterSubactivities) {
        cssClass += ' is-hidden'
      } else if (row.subactivities_notifications) {
        cssClass += ' sub-notifications'
      }

      return cssClass
    },

    getSubRowClass(row, index) {
      let cssClass = 'row_' + row.id;
      if (!row.access) {
        cssClass += 'disabled-subactivity'
      }
      return cssClass
    },

    checkFilterSubactivities() {
      this.filterSubactivities = !this.filterSubactivities;
      this.isLoadingTable = true;
      this.applyFilters(1)
    },

    sortByCode() {
      //ya viene asi de backend
    },

    sortByNotifications(a, b, isAsc) {
      return isAsc ?
        a.notifications.total - b.notifications.total :
        b.notifications.total - a.notifications.total
    },

    sortByFavorite(a, b, isAsc) {
      return isAsc ?
        (+a.favorite) - (+b.favorite) :
        (+b.favorite) - (+a.favorite)
    },

    sortByShowOnDevices() {

    },

    hasStatusList() {
      return this.childStatusList.length > 0;
      // return this.$refs.filtersRef.hasStatusList(); // da error por componente todavia no montado (ahora usamos emit-on)
    },

    getStatusIdName(id) {
      let status = this.$refs.filtersRef.statusList.find(b => b.id == id);

      if (status)
        return status.name;

      return '';
    },

    getAssignmentStatusColor(row) {
      switch (row.alert) {
        case -1:
          return 'bg-blue';

        case 0:
          return 'bg-green';

        case 1:
          return 'bg-red';

        default:
          return 'bg-blue';
      }
    },

    getAssignmentStatusLabel(row) {
      switch (row.alert) {
        case -1:
          return 'assignments-under';

        case 0:
          return 'assignments-completed';

        case 1:
          return 'assignments-over';
      }
    },

    /**
     * Calcula el estado de la medición
     * @param row
     * @returns {number}
     */
    getAssignmentExecutionStatus(row) {
      if (row.type === 'task')
        if (row.completed)
          return 0; // perfecto
        else return -1;// por debajo, tarea aun no completada

      if (row.executed_quantity < row.total)
        return -1; // por debajo
      else if (row.executed_quantity > row.total)
        return 1; // por encima
      else return 0; // cantidad exacta
    },

    reloadActivities(data) {
      this.applyFilters(this.page);
    },

    reloadSubActivities(data) {
      this.applyFilters(this.page);
    },

    async showOnDevice(activityId, index, isSubactivity = false) {
      if( !this.project.can_admin)
        return;
      let url = '/api/v2/activity/' + activityId + '/showOnDevices';
      const {data} = await axios.post(url, {'show' : 1});
      // const {data} = await axios.post(url, {params: {'show' : 1}});
      if (data && data.success) {
        //refrescamos la tabla
        if (isSubactivity) {
          // Recorremos las subactividades de la actividad abierta y buscamos la subactividad para actualizarla
          // No podemos utilizar el index ya que si las subactividades han sido reordenadas éste se queda desfasado
          let i = 0;
          let found = false;
          while (!found && i <= this.subactivities[this.openedSubactivitiesId].length) {
            if (this.subactivities[this.openedSubactivitiesId][i].id === activityId) {
              this.subactivities[this.openedSubactivitiesId][i].show_on_devices = true;
              found = true
            }
            i++
          }

        } else {
          this.list[index].show_on_devices = true;
          if( this.subactivities[this.openedSubactivitiesId] )
            this.subactivities[this.openedSubactivitiesId].forEach( function(item, indice, array) {
              item.show_on_devices = true
            });
        }

      } else {
        this.$notify.error('error_add_favorite')
      }
    },

    async hideOnDevice(activityId, index, isSubactivity = false) {
      if( !this.project.can_admin)
        return;
      let url = '/api/v2/activity/' + activityId + '/showOnDevices';
      const {data} = await axios.post(url, {'show' : 0});
      if (data && data.success) {
        //refrescamos la tabla
        if (isSubactivity) {
          // Recorremos las subactividades de la actividad abierta y buscamos la subactividad para actualizarla
          // No podemos utilizar el index ya que si las subactividades han sido reordenadas éste se queda desfasado
          let i = 0;
          let found = false;
          while (!found && i <= this.subactivities[this.openedSubactivitiesId].length) {
            if (this.subactivities[this.openedSubactivitiesId][i].id === activityId) {
              this.subactivities[this.openedSubactivitiesId][i].show_on_devices = false
              found = true
            }
            i++
          }
        } else {
          this.list[index].show_on_devices = false;
          if( this.subactivities[this.openedSubactivitiesId] )
            this.subactivities[this.openedSubactivitiesId].forEach( function(item, indice, array) {
              item.show_on_devices = false
            });
        }
      } else {
        this.$notify.error('error_add_favorite')
      }
    },

    goToPage(destiny = 'drawings') {

      switch (destiny) {

        case 'drawings':
            this.$router.push({ name: 'project.drawings' })
            break;
        case 'groups': // ver la vista de actividades agrupadas
            this.$router.push({ name: 'project.activities.groups' })
            break;
      }

    },

    colorGroup(group){
      let defaultColor = "#F29B57"
      let configs = JSON.parse(group.configs)

      return configs && configs.color ? configs.color : defaultColor
    },

    nameGroup(group){
      return group.name.substring(0, 2).toUpperCase()
    },

    updateColumnsConfig() {
      let columnsConfig = this.visualConfig?.activities_columns
      if (columnsConfig) {
        Object.entries(columnsConfig).map(c => {
          this.columns[c[0]].visible = c[1].visible
        })
      }
    },

    _HandleSelectItem(selectedOption, id) {
      this.columns[selectedOption.key].visible = true
    },

    _HandleRemoveItem(removedOption, id) {
      if (this.columns[removedOption.key])
        this.columns[removedOption.key].visible = false
    },

    quickButtonMenu() {
      return [
        // Vista agrupación de actividades
        {
          name: 'activity_grouping_view',
          text: this.$t('activity_groups'),
          showText: true,
          icon: 'layer-group',
          visible: true,
          tooltip: true,
          handleOnClick: () => { this.goToPage('groups') }
        },

        // Crear actividad
        {
          name: 'create_activity',
          text: this.$t('add_activity'),
          showText: true,
          icon: 'plus',
          visible: this.project.user_department.can_admin,
          tooltip: true,
          handleOnClick: this.onCreateActivityClicked
        },
        // Botón para abrir diagrama de gantt
        {
          name: 'gantt_chart_view',
          text: this.$t('display_gantt'),
          showText: false,
          icon: 'chart-gantt',
          visible: true,
          tooltip: true,
          handleOnClick: this.displayGantt
        },
        // Botón para ver Kanban
        {
          name: 'kanban_view',
          text: this.$t('display_kanban'),
          showText: false,
          icon: 'columns',
          visible: this.project.user_department.can_admin,
          tooltip: true,
          handleOnClick: this.displayKanban
        },
        // Botón para ver Carpetas documentos
        {
          name: 'document_folder_view',
          text: this.$t('display_folders'),
          showText: false,
          icon: 'folder-plus',
          visible: true,
          tooltip: true,
          handleOnClick: () => { this.$router.push({name: 'project.activities.folders'}); }
        }
      ]
    },

    hasPrelations(activity) {
      return activity.all_next_activities?.length || activity.all_pre_activities?.length
    },

    goToPrelations(activity) {
      if (this.hasPrelations(activity)) {
        this.$router.push({ name: 'activity.prelation', params: {
                  activity_id: activity.id
                } })
      }
    },

    handleRowSelected(row, updateRows = false) {
      this.$nextTick(() => {
        if (this.lastRow) {
          let lastRowElement = document.querySelector('.row_'+this.lastRow.id);
          if (lastRowElement) {
              lastRowElement.classList.remove('row-blue');
          }
          
          this.removeClassToNextRows(this.lastRow.all_next_activities)
          this.removeClassToPrevRows(this.lastRow.all_pre_activities)
          if (this.lastRow.id == row.id && !updateRows) {
            this.lastRow = null
            return;
          }
        }
        let rowElement = document.querySelector('.row_'+row.id);
        if (rowElement) {
            rowElement.classList.add('row-blue');
        }
  
        this.addClassToNextRows(row.all_next_activities)
        this.addClassToPrevRows(row.all_pre_activities)
        this.lastRow = row
      });
    },

    addClassToNextRows(nextActivities) {
      if (nextActivities) {
        for (let nextActivity of nextActivities) {
          let className = 'row-orange'
          let rowId = nextActivity.id
          if (nextActivity.parent_id) {
            className = 'row-red'

            let rowParentElement = document.querySelector('.row_'+nextActivity.parent_id);
            if (rowParentElement) {
                rowParentElement.classList.add(className);
            }
          }
  
          let rowElement = document.querySelector('.row_'+rowId);
          if (rowElement) {
              rowElement.classList.add(className);
          }
  
          this.addClassToNextRows(nextActivity.all_next_activities)
        }
      }
    },

    removeClassToNextRows(nextActivities) {
      if (nextActivities) {
        for (let nextActivity of nextActivities) {
          let className = 'row-orange'
          let rowId = nextActivity.id
          if (nextActivity.parent_id) {
            className = 'row-red'

            let rowParentElement = document.querySelector('.row_'+nextActivity.parent_id);
            if (rowParentElement) {
                rowParentElement.classList.remove(className);
            }
          }

          let rowElement = document.querySelector('.row_'+rowId);
          if (rowElement) {
              rowElement.classList.remove(className);
          }
  
          this.removeClassToNextRows(nextActivity.all_next_activities)
        }
      }
    },

    addClassToPrevRows(preActivities) {
      if (preActivities) {
        for (let preActivity of preActivities) {
          let className = 'row-green'
          let rowId = preActivity.id
          if (preActivity.parent_id) {
            className = 'row-green-dark'

            let rowParentElement = document.querySelector('.row_'+preActivity.parent_id);
            if (rowParentElement) {
                rowParentElement.classList.add(className);
            }
          }
  
          let rowElement = document.querySelector('.row_'+rowId);
          if (rowElement) {
              rowElement.classList.add(className);
          }
  
          this.addClassToPrevRows(preActivity.all_pre_activities)
        }
      }
    },

    removeClassToPrevRows(preActivities) {
      if (preActivities) {
        for (let preActivity of preActivities) {
          let className = 'row-green'
          let rowId = preActivity.id
          if (preActivity.parent_id) {
            className = 'row-green-dark'

            let rowParentElement = document.querySelector('.row_'+preActivity.parent_id);
            if (rowParentElement) {
                rowParentElement.classList.remove(className);
            }
          }

          let rowElement = document.querySelector('.row_'+rowId);
          if (rowElement) {
              rowElement.classList.remove(className);
          }
  
          this.removeClassToPrevRows(preActivity.all_pre_activities)
        }
      }
    }


  },

};
</script>

<style lang="scss">
.disabled-subactivity {
  background: #F8FAFC !important;
  color: #B8C2CA !important;
}

.reduce {
  transform: scale(0.80)
}

.vertical-center {
  vertical-align: inherit !important;
}

/* Se le debería de poder cambiar el icono al botón de details, no probado */
.sub-notifications > td.chevron-cell {
  background-color: #F8E0E0 !important;
}

.sub-notifications > td.chevron-cell svg {
  color: #e3342f !important;
}

.subactivities {
  .table th {
    background-color: #89a4d6;
  }

  &.b-table .table.is-bordered th.is-current-sort, &.b-table .table.is-bordered th.is-sortable:hover {
    background-color: #89a4d6 !important;
  }
}

.table-container {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  overflow-y: hidden;
  max-width: 100%;
}

.row-blue, .row-blue > * {
  background: #c4e3ff !important;
}

.row-orange, .row-orange > * {
  background: #fbd1a7 !important;
}

.row-red, .row-red > * {
  background: #fba7a7 !important;
}

.row-green, .row-green > * {
  background: #a7fbb8 !important;
}

.row-green-dark, .row-green-dark > * {
  background: #68f885 !important;
}

</style>

<style lang="scss" scoped>
.panel::v-deep .b-table .table-wrapper.has-sticky-header tr:first-child th {
  background: hsl(220, 43%, 53%);
}

.hover\:bg-epc-900:hover,
.bg-epc-900 {
  background-color: rgba(56, 93, 165, var(--tw-bg-opacity));
}
@media (min-width: 1024px) {
  .lg\:hover\:bg-epc-50:hover {
    background-color: #5577BB;
  }
}

@media (min-width: 1280px) {
  .xl\:inline {
    display: inline !important;
  }
}

@media (min-width: 640px) {
  .sm\:flex-row {
    flex-direction: row;
  }
}

</style>
