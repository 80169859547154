<template>
    <div>
        <div class="top-bar flex justify-end">
            <div class="export-button-container mr-4">
                <v-button @click="exportData" color="green">
                    <span v-if="!isLoading">{{ $t('export') }}</span>
                    <span v-else>
                        <i class="fa fa-spinner fa-spin"></i> {{ $t('loading') }}...
                    </span>
                </v-button>
            </div>
            <v-button @click="() => { showFormImportEntities = true }" icon="file-import" :disabled="showFormData">{{
                $t('cost_budget') }}</v-button>
            <v-button @click="openCostManagementModal(null)" icon="plus" class="ml-4"
                :disabled="showFormImportEntities">{{
                    $t('add_cost_budget') }}</v-button>
        </div>
        <div>
            <cost-management-filters ref="filtersInventoryRef" :projectId="projectId" :providers="providers"
                @filters-updated="filtersUpdate" />
        </div>
        <div class="flex">
            <div :class="[(showFormData || showFormImportEntities ? 'w-7/12' : 'w-full'), 'mr-2']">
                <cost-budgets ref="budgets_table" :projectId="projectId" @epc-edit="handleEditEntity" />
            </div>
            <transition name="fade">
                <div class="w-5/12 ml-2" v-if="showFormImportEntities">
                    <cost-management-import :projectId="projectId" :entity-type="'cost-management'"
                        @epc-close="(event) => { showFormImportEntities = false }"
                        @epc-success-import="() => { showFormImportEntities = false; $refs.budgets_table.reload() }" />
                </div>
            </transition>
        </div>
        <cost-management-modal ref="cost_management_modal" :projectId="projectId"
            @reload-list="handleReload"></cost-management-modal>
    </div>
</template>

<script>
import CostBudgets from './cost-budgets.vue';
import axios from 'axios';
import CostManagementFilters from './cost-management-filters'
import CostManagementModal from "./cost-budget-modal.vue";
import CostManagementImport from './cost-management-import.vue';
export default {
    name: "manage-cost-management",
    components: {
        CostBudgets,
        CostManagementFilters,
        CostManagementModal,
        CostManagementImport

    },
    data: () => ({
        material: null,
        showFormData: false,
        showFormImportEntities: false,
        showFormLink: false,
        providers: [],
        assignments: [],
        isLoading: false

    }),
    props: {
        projectId: { type: Number, required: true },
    },
    created() {
        this.getProviders();
        this.getAssignment();
    },
    mounted() {
    },
    methods: {
        handleReload() {
            this.$refs.budgets_table.reload();
        },
        async getProviders() {
            try {
                const { data } = await axios.get(`/api/v2/project/${this.projectId}/providers`);
                if (data && data.success) {
                    this.providers = data.providers;
                }
            } catch (error) {
                throw ('Error fetching providers:', error);
            }
        },
        async getAssignment() {
            try {
                const { data } = await axios.get(`/api/v2/project/assignments/${this.projectId}`);
                if (data && data.success) {
                    this.assignments = data.assignments;
                }
            } catch (error) {
                throw ('Error fetching assignment:', error);
            }
        },
        handleSuccess() {
            this.$refs.inventories_list.reload()
        },
        handleEditEntity(entity) {
            this.openCostManagementModal(entity)
        },
        // Botones de acción
        openCostManagementModal(budget = null) {
            this.$refs.cost_management_modal.show(this.providers, this.assignments, budget);
        },
        filtersUpdate(args = {}) {
            this.$refs.budgets_table.reload(args);
        },
        async exportData() {
            this.isLoading = true;
            try {
                const response = await axios.post('/api/v2/export/cost-budget', {
                    project_id: this.projectId,
                    orderDir: 'asc',
                    orderField: 'id',
                    filters: {}
                }, {
                    responseType: 'blob'
                });

                // Crear un enlace para descargar el archivo
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'cost_budget.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                throw ("Error al exportar datos:", error);
            }
            finally {
                this.isLoading = false;
            }
        }
    }

};
</script>

<style scoped></style>
