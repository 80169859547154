<template>

<div>

  <menu-views></menu-views>

  <!-- Filtros -->
  <folders-filters v-if="false"
    ref="foldersFiltersRef"
    :locationsList="locationsList"
    @foldersfilters-updated="filtersUpdated"
  />

  <!-- Breadcrumb -->
  <div class="py-4" style="font-size: 14px">
    <span v-for="step, index in tree" :key="'breadcrumb_'+step.id">
      <span v-if="index < tree.length-1" @click="openFolder(step)" class="cursor-pointer"
        >{{ step.name }}<i class="fas fa-angle-right px-2"></i>
      </span>
      <span v-else>
        <b>{{ step.name }}</b>
      </span>
    </span>
  </div>

  <div class="flex w-full mb-1">
    <b-field class="w-full">
      <b-input :placeholder="$t('name')"
          v-model="qNameFolder"
          icon="search"
          icon-pack="fas"
          icon-right="sliders-h"
          icon-right-clickable
          @icon-right-click="openFilterAvancesFolderClick"
          @input="_HandleInputFilterFolderSearch(qNameFolder)">
      </b-input>
    </b-field>

    <folders-filters-modal ref="refModalFilterFolders"
      :locations-list="locationsList"
      :with-button="false"
      @epc-apply-filters="_HandleApplyFilters"
    />
  </div>

  <!-- Loading... -->
  <div v-if="isLoading" class="mt-4 text-center">
    <div class="mt-4 text-center"><scale-loader></scale-loader></div>
  </div>


  <div v-else style="font-size: 14px">
    <div v-if="tree.length > 1" class="flex" style="font-size: 14px">
      <span
        class="p-2 cursor-pointer flex items-center"
        style="background: #d442; color: #d44"
        onmouseover="this.style.background='#d443'"
        onmouseout="this.style.background='#d442'"
        @click="openFolder(tree[tree.length - 2])">
        <b-icon pack="fas" icon="arrow-left" />
      </span>
      <entity-document-folder-create
        :selectedFolder="currentFolder"
        :canManageFolders="( canManageFolders || isProjectParticipant )"
        :parentId="selectedEntityId"
        :entityType="entityType"
        :showNameCurrentFolder="false"
        textHeader="create_folder"
        @reload-documents-list="openFolder(lastSelectedFolder)"
        style="background: #3a73; color: #285; border-radius: 0 !important; display: flex; align-items: center"
        onmouseover="this.style.background='#3a73'"
        onmouseout="this.style.background='#3a72'"
        :open-in-modal="true"
      />
      <span
        class="p-2 cursor-pointer flex items-center"
        style="background: #57b2; color: #358"
        onmouseover="this.style.background='#57b3'"
        onmouseout="this.style.background='#57b2'"
        v-if="(canManageFolders || isProjectParticipant || isProjectCollaborator)"
        @click="currentFolder.id  ? downloadFolder(currentFolder.id) : downloadFolderActivity(selectedEntityId)">
        <span class="hidden lg:inline">{{ $t('download_folder') }}</span> <b-icon pack="fas" icon="download" />
      </span>

      <span v-if="canAdd"
        class="p-2 cursor-pointer flex items-center"
        style="background: #57b2; color: #358"
        onmouseover="this.style.background='#57b3'"
        onmouseout="this.style.background='#57b2'"
        @click="onAddClicked()">
        <span class="hidden lg:inline">{{ $t('add_document') }}</span> <b-icon pack="fas" icon="file-upload" />
      </span>

      <!-- Modal validación -->
      <!-- <div class="rounded-sm mr-2 float-right" v-if="canAddValidationFlow"> -->
      <!-- De momento quitamos la restriccion esto lo hace al momento de abrir el modal-->
      <add-validation-flow
        :entity-id="parseInt(currentActivity.id)"
        entity-type="App\Models\Activity"
        class="inline"
        :openModalWith="'button-span'"
      />

      <!-- v-if="(user && user.id == 1183 && selectedEntityId && selectedEntityId == 72114)" para el anterior usuario -->
      <span
        class="p-2 cursor-pointer flex items-center"
        style="background: rgba(134, 135, 135, 0.133); color: rgb(60, 60, 60)"
        v-if="(user && user.id == 1035)"
        @click="downloadFolderTemp(selectedEntityId)">
        <span class="hidden lg:inline">Descargar Zip </span> <b-icon pack="fas" icon="cloud-arrow-down" />
      </span>
    </div>

    <table class="w-full text-center table_folders mt-1">
      <thead v-if="tree.length > 1"  class="h-9">
        <tr :class="['bg-gray-200', 'font-bold']">
          <!-- boton crear carpeta dentro de carpeta actual -->
          <td class="align-middle">
            <div class="text-left pl-2">Nombre del documento</div>
          </td>
          <td class="align-middle" :class="{ 'hidden': mobile.on_mobile }">Estado</td>
          <td class="align-middle hidden-" :class="{ 'hidden': mobile.on_mobile }">Creación</td>
          <td class="align-middle hidden-" :class="{ 'hidden': mobile.on_mobile }">Ultima Modificación</td>
          <td class="align-middle pr-2 hidden-" :class="{ 'hidden': mobile.on_mobile }">
            <div class="flex justify-end">
              <b-field>
                <b-select icon="sort-alpha-down"
                  icon-pack="fas"
                  size="is-small"
                  v-model="order_field"
                  @input="onSelectOrder()"
                >
                    <option value="name">Nombre</option>
                    <option value="created_at">Fecha creación</option>
                    <option value="updated_at">Fecha actualización</option>
                </b-select>
              </b-field>
            </div>
          </td>

          <td class="w-1/6">
          </td>
        </tr>
      </thead>
      <tbody>
        <!-- listado carpetas -->
        <tr v-for="folder, index in folders" :key="'f_' + folder.id" class="w-10">
          <td class="cursor-pointer flex">
            <!--Icono que representa tipo de directorio-->
            <div :class="folder.id != -1 ? folder.is_virtual ? 'text-blue' : 'text-yellow':'text-grey-dark'"
              @click="openFolder(folder)">
              <b-icon pack="fas" :icon="folder.icon || 'folder'" size="is-medium" />
            </div>

            <!--Muestra el nombre y permite editar el nombre-->
            <div class="ml-2 flex items-center" @click="openFolder(folder)">
              <span v-if="folder.id!=newFolder.id">{{ folder.name }}</span>
              <span v-if="false" class="text-xs text-blue-light font-bold ml-2">(NUM docs)</span>
              <span class="inline-flex" v-if="canManageFolders && !folder.is_virtual && folder.id != -1 && folder.id==newFolder.id">
                <input v-model="newFolder.name" type="text" :placeholder="$t('name')" class="input mr-2"  required="true">
                <input v-model="newFolder.code" type="text" :placeholder="$t('code')" class="input mr-2"  required="true">
                <div @click.stop="save()">
                  <b-tooltip :label="$t('save')" position="is-right">
                    <b-icon pack="fas" icon="save" type="is-success" size="is-medium" />
                  </b-tooltip>
                </div>
                <div @click.stop="actionFolder('cancel', null)">
                  <b-tooltip :label="$t('cancel')" position="is-right">
                    <b-icon pack="fas" icon="times" type="is-danger" size="is-medium" />
                  </b-tooltip>
                </div>
              </span>
            </div>
          </td>

          <td class="align-middle hidden-" :class="{ 'hidden': mobile.on_mobile }"></td>
          <td class="align-middle hidden-" :class="{ 'hidden': mobile.on_mobile }"></td>
          <td class="align-middle hidden-" :class="{ 'hidden': mobile.on_mobile }"></td>

          <td :class="{ 'hidden': mobile.on_mobile }">
            <div class="ml-2 flex items-center">
                <!--esto va en menu info-->
                <span v-if="folder.location">{{ folder.location.name }}</span>
                <div class="flex w-full justify-end pr-3" v-if="false">

                  <div v-if="canManageFolders && !folder.is_virtual && folder.id != -1"
                    @click="actionFolder('edit', folder)">
                    <b-tooltip :label="$t('edit')" position="is-left">
                      <b-icon pack="fas" icon="pencil-alt" />
                    </b-tooltip>
                  </div>

                  <div v-if="canManageFolders && !folder.is_virtual && folder.documents_count == 0 && folder.children == null && folder.id != -1"
                    @click="deleteFolder(folder.id, folder.name)">
                    <b-tooltip :label="$t('remove')" position="is-left">
                      <b-icon pack="fas" icon="trash-alt" />
                    </b-tooltip>
                  </div>

                  <div v-if="canManageFolders && !folder.is_virtual && (folder.documents_count != 0 || folder.children != null) && folder.id != -1"
                    @click="downloadFolder(folder.id)">
                    <b-tooltip :label="$t('download_folder')" position="is-left">
                      <b-icon pack="fas" icon="download" />
                    </b-tooltip>
                  </div>

                  <div v-if="canManageFolders && folder.is_virtual"
                    @click="downloadFolderActivity(folder.id)">
                    <b-tooltip :label="$t('download_folder')" position="is-left">
                      <b-icon pack="fas" icon="download" />
                    </b-tooltip>
                  </div>

                  <div v-if="canManageFolders && folder.id != -1 && !folder.is_virtual"
                    @click="moveFolder(folder.id)">
                    <b-tooltip :label="$t('move_folder')" position="is-left">
                      <b-icon pack="fas" icon="exchange-alt" />
                    </b-tooltip>
                  </div>
                </div>
            </div>
          </td>

          <!-- Menu drop down -->
          <td class="align-middle ">
            <div class="text-right pr-2">
              <menu-dropdown :options="getOptionsMenuFolders(folder)"></menu-dropdown>
            </div>
          </td>
        </tr>

        <!-- listado documentos -->
        <tr v-for="document, index in documents" :key="'d_' + document.document_id" :set="numRows = folders.length">
          <td class="flex">
            <!--Icon que representa tipo de documento-->
            <div @click="showPreview(document, index)" class="flex items-center cursor-pointer">
              <b-icon pack="far" icon="file" size="is-medium" />
            </div>

            <div class="ml-2 text-left" >
              <div class="leading-4 py-2">
                {{ document.name }}
                <span class="text-xs text-blue-light font-bold ml-2"></span>
                <span class="text-grey-dark font-bold cursor-pointer" @click="goToActivity(document.activity_id)" style="font-size: 11px" v-if="document.activity_id"><br>{{document.activity_name}}</span>
                <span class="text-blue-light font-bold cursor-pointer" style="font-size: 11px" v-if="document.related_validation_task" @click="goToValidationFlow(document.related_validation_task.validation_id)"><br>{{document.related_validation_task.validation_name}}</span>
                <span class="text-blue-light font-bold cursor-pointer" @click="goToValidationFlow(document.related_validation.validation_id)" style="font-size: 11px" v-else-if="document.related_validation"><br>{{document.related_validation.validation_name}}</span>
              </div>
            </div>
          </td>

          <td class="align-middle hidden-" :class="{ 'hidden': mobile.on_mobile }">
            <div>
              <validation-status-label v-if="document.related_validation_task"
                :status="document.related_validation_task.flow_status"
                class="w-full"
                style="vertical-align: middle"/>

              <validation-status-label v-else-if="document.related_validation"
                :status="document.related_validation.flow_status"
                class="w-full"
                style="vertical-align: middle"/>
            </div>
          </td>

          <td class="align-middle hidden-" :class="{ 'hidden': mobile.on_mobile }">{{document.created_at.substring(0,10)}}</td>
          <td class="align-middle hidden-" :class="{ 'hidden': mobile.on_mobile }">{{document.updated_at.substring(0,10)}}</td>
          <td :class="{ 'hidden': mobile.on_mobile }"></td>
          <td class="align-middle">
            <div class="text-right pr-2">
              <div v-if="isBeingEliminated(document)"
                class="flex w-full justify-end pr-3 text-blue-light">
                <b-icon icon="spinner" pack="fas" class="animate-spin" ></b-icon> <span>Eliminando ...</span>
              </div>
              <div v-else class="flex- w-full- justify-end- pr-3-">
                <div>
                  <button-circle v-if="!mobile.on_mobile"
                    :text-label="'<b>Creación: </b>' + document.created_at + '<b>Última modificación: </b>' + document.updated_at"
                    :icon="'info'"
                    :active-tooltip="true"
                    :with-bg="false"
                    :handle-on-click="() => { }"
                  ></button-circle>

                  <button-circle v-if="!mobile.on_mobile"
                    :text-label="document.user.fullname + ' (' + document.created_at + ')'"
                    :icon="'user'"
                    :active-tooltip="true"
                    :with-bg="false"
                    :handle-on-click="() => { }"
                  ></button-circle>

                  <button-circle v-if="!mobile.on_mobile"
                    :text-label="$t('display_versions')"
                    :text-sup="document.versions.length > 0 ? document.versions.length.toString() : null"
                    :icon="'history'"
                    :active-tooltip="true"
                    :disabled="document.versions.length <= 0 || !isOk(document)"
                    :with-bg="false"
                    :handle-on-click="() => { openVersionsModal(document.document_id) }"
                  ></button-circle>

                  <menu-dropdown :options="getOptionsMenuDocuments(document)"></menu-dropdown>
                </div>

              </div>
            </div>
          </td>


          <!-- Modal para subir versión -->
          <upload-document
              :ref="'upload_modal_' + document.document_id"
              :upload-endpoint="'/api/v2/document/' + document.document_id + '/add_version/' + entityType + '/' + selectedEntityId"
              :entity-id="selectedEntityId"
              :entity-type="entityType"
              :notify-users="true"
              @success="onSelectOrder"
              :validable-parent-code="activityCode"
          />

          <!-- Modal para ver versiones -->
          <document-versions
              ref="versions_modal"
              :document="document"
              :entityId="currentActivity.id"
              :entityType="'activity'"
              @reload-documents-list="reloadList"
          />

        </tr>
      </tbody>
    </table>

    <b-loading v-model="isSave"></b-loading>
    <!-- Modal para PREVIEW -->
    <!-- :entity-id="currentActivity.id ? currentActivity.id : 0" fix realizado para evitar el error cuando id es undefined o null -->
    <entity-document-preview
      :entity-id="currentActivity.id ? currentActivity.id : 0"
      :entity-type="'activity'"
      :document-id="documentId"
      ref="previewModal"
      :can-delete="canDeleteDocument"
      :showVersions="showVersions"
      @reload-documents-list="() => $emit('reload-documents-list')"
      @update-modal-document="updateModalDocument"
      :can-update-modal-document="documents.length > 1"
    />
    <!-- Modal para mover documento a otra carpeta -->
    <document-move-folder
        ref="document_move_folder"
        :tree="selectedEntityFolderTree"
        :entityId="selectedEntityId"
        @reload-documents-list="reloadList"
    />

    <!-- Modal mover carpeta o documento -->
    <b-modal v-model="showModalSelectFolder" size="lg" scroll="keep">
      <div class="flex flex-col border border-blue-light rounded shadow bg-white p-4">
        <select-folders
        :folderId="moveFolderId"
        :documentId="moveDocumentId"
        :parentId="selectedEntityId"
        @close="openFolder(lastSelectedFolder)" />
      </div>
    </b-modal>

    <!-- Modal para subir nuevos documentos -->
    <upload-document v-if="canAdd"
      ref="modal"
      :upload-endpoint="'/api/v2/' + entityType + '/' + currentActivity.id + '/document'"
      :allow-multiple-files="true"
      @success="successfullyUploaded"
      :entityId="currentActivity.id"
      :entityType="entityType"
      :selected-folder="currentFolder"
      :upload-folders="true"
      :notify-users="true"
      :validable-parent-code="activityCode"
    />

    <!-- mensaje carpeta vacia -->
    <div v-if="documents.length == 0 && folders.length == 0" class="p-4 text-red">
      {{ $t('empty_folder') }} <b-icon pack="fas" icon="ban" />
    </div>

    <div v-if="totalDocuments > perPage" class="mt-4">
      <b-pagination
          :total="totalDocuments"
          :current.sync="page"
          size="is-small"
          iconPack="fas"
          order="is-centered"
          :per-page="perPage"
          @change="onPageChange">
      </b-pagination>
    </div>

  </div>

</div>

</template>

<script>
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import EventBus from '~/plugins/bus'
  import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
  import entityDocumentPreview from "·/components/entity-document-preview";
  import entityDocumentFolderCreate from "·/components/entity-document-folder-create";
  import documentVersions from "·/components/document-versions";
  import documentMoveFolder from "·/components/document-move-folder";
  import foldersFilters from "·/components/documents-folders/folders-filters";
  import uploadDocument from "../../../components/upload-document";
  import addValidationFlow from '·/components/add-validation-flow';
  import selectFolders from "./select-folders";
  import validationStatusLabel from '·/components/validations/validation-status-label';
  import validationTaskStatusLabel from '·/components/validations/validation-task-status-label';
  import FoldersFiltersModal from '../../../components/documents-folders/folders-filters-modal.vue'

  import MenuViews from './components/menu-views.vue'

  import MenuDropdown from '../../../components/buttons-menu/button-circle-dropdown.vue'
  import ButtonCircle from '../../../components/buttons-menu/button-circle.vue';

  export default {
    middleware: 'auth',
    title: 'activities_page_title',
    subtitle: 'activities_page_subtitle',
    menuOption: '2-2',

    components: {
      ScaleLoader,
      entityDocumentPreview,
      entityDocumentFolderCreate,
      documentVersions,
      documentMoveFolder,
      foldersFilters,
      uploadDocument,
      addValidationFlow,
      selectFolders,
      validationStatusLabel,
      validationTaskStatusLabel,
      MenuViews,
      FoldersFiltersModal,
      MenuDropdown,
      ButtonCircle
    },

    computed: {
      ...mapGetters({
        user: "auth/user",
        project: 'app/project',
        mobile: 'device/mobile'
      }),
    },

    data: () => ({
      isLoading: true,
      isSave: false,
      tree: [{ id: null, name: 'Raiz', is_virtual: true }], // path de las carpetas que vamos abriendo
      currentFolder: {}, // carpeta seleccionada viendo sus ficheros
      folders: [], // carpetas (mezcla de DocumentFolder y Subactividades) de la carpeta actual
      foldersSub: [], // temporal con subcarpetas antes de añadirlas en folders
      documents: [], // documentos de la carpeta actual
      currentActivity: {}, // carpeta virtual 'actividad' seleccionada
      total: 0,
      showVersions: true,
      selectedEntityFolderTree: [], // arbol completo de la actividad seleccionada (para pasar a selector de arbol)
      selectedEntityId: 0, // id de la actividad donde cuelga la carpeta seleccionada (para pasar a selector arbol)
      lastSelectedFolder: {}, // la ultima carpeta que se ha abierto (parecido a currentFolder pero este mantiene todos los atributos)

      locationsList: [], // lista de emplazamientos (para filtro)
      entityType: 'activity',
      canAdd: false,

      canManageFolders: false,
      isProjectManager: false,
      isProjectParticipant: false,
      isProjectCollaborator: false,
      canAddValidationFlow: false,
      canManageDocuments: false,
      showModalSelectFolder: false,
      moveFolderId: null,
      moveDocumentId: null,
      activityCode: null, // almacena el planning_code de la actividad seleccionada (para pasar al componente al subir documento). Para proyectos con codificacion 'lean2' o 'lean3'

      newFolder: {
        id: '',
        code: '',
        name: '',
      },

      page: 1,
      perPage: 50,
      totalDocuments: 0,
      loadSubactivities: false,

      order_field: 'name',
      order_dir: 'desc',
      canDeleteDocument: false,
      documentId: null,

      filesToDelete: [], // Id de archivos que estan en proceso de eliminación
      qNameFolder: null
    }),

    mounted() {
      this.getActivitiesList(true)
    },

    created() {
      let self = this;
      EventBus.$on("refreshFolderDocuments", function(e) {
        e = e || null;
        let folder_parent_id = e == null ? self.lastSelectedFolder.id : e.folder_parent_id

        // si hay carpeta seleccionada (si estamos en raiz raiz no hace falta)
        if (self.lastSelectedFolder.id && (self.lastSelectedFolder.id == folder_parent_id) ) {
          self.openFolder(self.lastSelectedFolder)
        }

      });

      EventBus.$on('treeSelectorNodeSelected-ActivitySelected', function (node) {
        self.getActivityFolder(node)
      });

    },

    beforeDestroy(){
      EventBus.$off("refreshFolderDocuments");
      EventBus.$off("treeSelectorNodeSelected-ActivitySelected");
    },

    methods: {

      // listado inicial de carpetas, las actividades del proyecto
      async getActivitiesList(isFirstTime=false) {

        let args = {}

        // if (this.$refs.foldersFiltersRef !== undefined) {
        //   args = this.$refs.foldersFiltersRef.generateFilters();
        // }

        if (this.$refs.refModalFilterFolders !== undefined) {
          args = this.$refs.refModalFilterFolders.getFilters();
        }

        if ( this.qNameFolder != null ) {
          args.n = this.qNameFolder
        }

        args.p = this.project.id
        args.with_location = 1
        args.pg = 1

        if (! args.filterShowClosed) {
          // show not closed activities
          args.status = 100
        } else {
          // show ALSO closed activities (all)... no status filter
        }

        this.isLoading = true

        const {data} = await axios.get('/api/v2/activities', {params: args})

        // Last and total
        // this.last = data.lastPage
        this.total = data.total

        if (data && data.activities) {
          this.folders = data.activities.map(function(activity) {
            return {
                id: activity.id,
                name: activity.planning_code + ' - ' + activity.name,
                code: null,
                is_virtual: true,
                location: activity.location
            }
          })
          this.documents = []
          if (isFirstTime) {
            this.locationsList = this.getLocationsList(this.folders)
          }

        } else {
          this.$notify.error('error_loading_activities')
        }

        this.isLoading = false
      },


      // devuelve listado subactividades para pintarlas como carpetas virtuales de la actividad
      async getSubactivities(activityId) {
        let url = '/api/v2/activity/' + activityId + '/subactivities'
        let self = this
        await axios.get(url)
          .then(function (response) {

            if (response.data && response.data.subactivities) {
              self.foldersSub = response.data.subactivities.map(function(activity) {
                return {
                  id: activity.id,
                  name: activity.planning_code + ' - ' + activity.name,
                  code: null,
                  is_virtual: true
                }
              })

            }
          })
      },


      // puede ser carpeta virtual (una actividad) o carpeta de documentos dentro de la actividad
      openFolder(folder) {

        if( this.newFolder.id != '' )
          return

        this.lastSelectedFolder = folder
        this.setTree(folder)
        this.showModalSelectFolder = false

        // caso base, abrir carpeta raiz raiz de todo
        if (folder.id === null && folder.is_virtual) {
          this.canManageFolders = false
          this.canAdd = false
          this.getActivitiesList()
          this.activityCode = null
          return
        }

        this.loadSubactivities = false

        if (folder.is_virtual) {
          this.currentActivity.id = folder.id // carpeta ficticia actividad, abre documentFolders que cuelgan del raiz
          this.currentFolder.id = null // empezamos por la raiz
          // carga subcarpetas virtuales (las subactividades)
          this.loadSubactivities = true
          this.activityCode = folder.name.split(' - ')[0].trim() || '' // la primera parte es la activity code
        } else {
          this.currentFolder.id = folder.id //carpeta 'de verdad' documentFolder, abre lo que cuelga de este id
        }

        // carga documentos y carpetas que cuelgan de esta actividad/documentFolder
        this.loadDocumentsList(this.currentActivity.id, 'activity', this.currentFolder.id, this.loadSubactivities)

      },


      async loadDocumentsList(entityId, entityType, documentFolder=null, loadSubactivities=false) {

        this.isLoading = true
        let self = this

        let params = {
          per_page: self.perPage,
          page: self.page,
          order_field: self.order_field,
          order_dir: self.order_dir,
          with_folders: 1, // ordenado en carpetas
          folder_id: documentFolder, // docs y carpetas que cuelgan de esta carpeta (del raiz si es null)
        }

        await axios.get("/api/v2/"+ entityType +"/" + entityId + "/documents?-=1",
          { params: params })
          .then(function(response) {

            if (response.data && response.data.documents) {
              self.documents = response.data.documents//.sort(self.mySortByName); //comentamos sort(self.mySortByName) ya que los ducumentos llegan ordenados segun criterio
              self.folders = response.data.folders.map(function(folder) {
                  return {
                    id: folder.id,
                    name: folder.name,
                    code: folder.code,
                    is_virtual: false,
                    icon: folder.icon,
                    documents_count: folder.documents_count,
                    children: folder.children
                  }
                }).sort(self.mySortByName)

              // self.total = response.data.total
              self.totalDocuments = response.data.total // total de documentos
              self.currentFolder = response.data.currentFolder || {}

              if (loadSubactivities) {

                self.getSubactivities(entityId).then(function(response) {
                  self.folders = self.folders.concat(self.foldersSub).sort(self.mySortByName)
                })

              }

              // Caso raiz de actividad, cargamos su arbol de carpetas completo para opcion "mover" fichero
              if (documentFolder == null && entityType == 'activity') {
                self.getActivityFolder(entityId)
              }

            }
          })
          .catch(function(error) {
            console.log(error);
          })
          .finally(function () {
            self.isLoading = false;
          });
      },


      // Establece el Breadcrumb
      setTree(folder) {

        let alreadyOpened = this.tree.some(function(value) { return value.id == folder.id })

        // ha pulsado en una carpeta superior ya abierta, borramos las carpetas por debajo
        if (alreadyOpened) {
          let position = this.tree.findIndex(value => { return value.id == folder.id})
          this.tree = this.tree.filter( (value, index) => { return index <= position })
        } else {
          // la carpeta es nueva en la ruta, insertarla
          this.tree.push({ id: folder.id, name: folder.name, is_virtual: folder.is_virtual || false })
        }

      },


      getActivityFolder(entityId) {
        let self = this;

        axios.get("/api/v2/activity/" + entityId + "/", { params: {with_folders: 1} })
            .then(function(response) {
              self.selectedEntityFolderTree = response.data.data.folders || []
              self.selectedEntityId = entityId
              self.canAdd = (response.data.data.is_creator || response.data.data.is_manager || response.data.data.is_participant || response.data.data.is_collaborator) && !(response.data.data.status == 6) && (self.currentActivity.id && self.currentActivity.id > 0)
              self.canManageFolders = (response.data.data.is_project_manager || response.data.data.is_manager) && ( self.currentActivity.id && self.currentActivity.id > 0)
              self.canAddValidationFlow = (response.data.data.is_project_manager) && ( self.currentActivity.id && self.currentActivity.id > 0)
              self.isProjectManager = response.data.data.is_project_manager
              self.isProjectParticipant = response.data.data.is_project_participant
              self.isProjectCollaborator = response.data.data.is_project_collaborator
              self.canManageDocuments = (response.data.data.is_creator || response.data.data.is_manager || response.data.data.is_participant || response.data.data.is_collaborator)
            })
      },


      // ordenar las carpetas por nombre
      mySortByName(a, b) {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
      },


      /**
       * Cambia la ventana a la tabla de actividades normal
       */
      displayTable() {
        this.$router.push({name: 'project.activities'});
      },


      /**
       * Cambia la ventana a la vista kanban
       */
      displayKanban() {
        this.$router.push({name: 'project.activities.kanban'});
      },


      reloadList() {
        this.$emit('reload-documents-list')
      },


      showPreview(document, index) {
        this.canDeleteDocument = this.user.id == document.user.user_id || this.isProjectManager
        this.documentId = document.document_id
        this.$refs.previewModal.show(document);
        if (document.exif) {
          EventBus.$emit("load-locations", document.exif);
        }
      },


      openVersionsModal(docId) {
        // Asi ya no vale pasando un 'index'. BUG pasando index abre bien la primera vez, luego se corre el array de versions_modal y ya no funciona
        // this.$refs.versions_modal[index].show();

        // Arreglado buscando por document_id entre todos los componentes 'versions_modal'
        let pos = this.$refs.versions_modal.findIndex(value => { return value._props.document.document_id == docId})
        this.$refs.versions_modal[pos].show();
      },


      isOk(document){
        return document.status == 1;
      },


      documentDownloadTooltip(tooltip, document) {
        if(this.isOk(document))
          return tooltip;

        return 'document_still_processing';
      },


      async downloadDoc(document) {
        this.$notify.success("starting_download_document");
        let url = "/api/v2/document/" + document.document_id;

        const {data} = await axios.get(url);

        if (data) {

          window.open(data.data);

        } else {
          this.$notify.error("error_download_document");
        }
      },


      onMoveClick(document) {
        this.moveFolderId = null
        this.moveDocumentId = document.document_id
        this.showModalSelectFolder = true
        // ya no usamos este porque se cuelga cuando hay muchas carpetas/documentos
        //this.$refs.document_move_folder.show(document);

      },

      onAddClicked() {
        this.$refs.modal.show();
      },

      successfullyUploaded()
      {
        this.loadDocumentsList(this.currentActivity.id, this.entityType, this.currentFolder.id, this.lastSelectedFolder.is_virtual);

        let entity = this.entityType;
        entity = entity.charAt(0).toUpperCase() + entity.slice(1);
        //Evento : "refreshActivityDocumentsNotifications"

        EventBus.$emit('refresh'+entity+'DocumentsNotifications', true);
      },

      // a partir de la lista de carpetas con sus localizaciones devuelve array unico de localizaciones para filtros
      getLocationsList(folders) {
        let locations = folders.map(folder => folder.location)
        let locationsUnique = [] // ids de locations procesadas

        // devuelve las no repetidas
        return locations.filter(function(location){
          if (! locationsUnique.includes(location.id)) {
            locationsUnique.push(location.id)
            return true;
          }
        })
      },


      filtersUpdated() {
        this.tree = [{ id: null, name: 'Raiz', is_virtual: true }]
        this.getActivitiesList();
      },

      // Editar nombre de folder
      async actionFolder(action,folder) {
        if(action == 'edit') {
          this.newFolder.id = folder.id
          this.newFolder.name = folder.name
          this.newFolder.code = folder.code
        } else {
          this.newFolder.id = ''
          this.newFolder.name = ''
          this.newFolder.code = ''

        }
      },

      async save() {

        this.isSave = true

        if (this.entityType != 'activity') {
          this.$notify.error("Option only for activities")
          return
        }

        const { data } = await axios.post("/api/v2/" + this.entityType + "/" + this.selectedEntityId + "/document-folder/"+ this.newFolder.id + "/update", {
          code: this.newFolder.code,
          name: this.newFolder.name,
          // parent_id: this.selectedFolder.id || null,
        })
        this.isSave = false

        if (data && data.success) {

          for (const obj of this.folders) {
            if (obj.id === this.newFolder.id) {
              obj.name = this.newFolder.name;
              obj.code = this.newFolder.code;

              break;
            }
          }
          this.newFolder = {
            id: '',
            code: '',
            name: '',
          },
          this.actionFolder('cancel', null)
          this.$notify.success('success_editing')

        } else if (data && data.msg) {

          this.$notify.error(data.msg || 'no_access_permissions')

        } else {
          this.$notify.error('error_saving')
        }
      },

      // elimina la task de esta plantilla
      async deleteFolder(folderId, folderName) {

        let self = this;
        this.$dialog.confirm({
          title: this.$t('delete'),
          message: this.$t('delete_generic', [folderName]),
          confirmText: this.$t('delete'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {

            const { data } = await axios.post("/api/v2/" + this.entityType + "/" + this.selectedEntityId + "/document-folder/" + folderId + "/delete", {})
            if (data && data.success) {

              // reload
              // this.reloadList()
              this.actionFolder('cancel', null)

              let indexFolder = this.folders.findIndex( obj => { return obj.id == folderId })
              if(indexFolder > -1)
                this.folders.splice(indexFolder, 1)
              else
                this.openFolder(this.lastSelectedFolder)  // llama a la api si falla la busqueda en el array

              this.$notify.success('success_deleting_generic')

            } else if (data && data.msg) {
              this.$notify.error(data.msg)

            } else {
              this.$notify.error('error_saving')
            }
          }
        })
      },


      async downloadFolder(folderId) {
        let self = this;
        const { data } = await axios.get("/api/v2/document-folder/" + folderId + "/download", {})
        if (data && data.success) {
          this.$notify.success('download_folder_progress')

        } else if (data && data.msg) {
          this.$notify.error(data.msg)

        } else {
          this.$notify.error('download_folder_error')
        }
      },


      async downloadFolderActivity(activityId) {
        let self = this;
        const { data } = await axios.get("/api/v2/activity/" + activityId + "/document-folder/download", {})
        if (data && data.success) {
          this.$notify.success('download_folder_progress')

        } else if (data && data.msg) {
          this.$notify.error(data.msg)

        } else {
          this.$notify.error('download_folder_error')
        }
      },

      async downloadFolderTemp(id) {
        let self = this;
        const { data } = await axios.get("/api/v2/document-folder/" +  id + "/temp-download", {})
        if (data && data.success) {
          this.$notify.success('download_folder_progress')

        } else if (data && data.msg) {
          this.$notify.error(data.msg)

        } else {
          this.$notify.error('download_folder_error')
        }
      },


      moveFolder(folderId) {
        this.moveDocumentId = null
        this.moveFolderId = folderId
        this.showModalSelectFolder = true
      },

      openUploadModal(docId) {
        // this.$refs.upload_modal[index].show();
        this.$refs['upload_modal_' + docId][0].show();
      },


      async undelete(document) {
        let self = this;
        const { data } = await axios.post("/api/v2/document/" + document.document_id + "/undelete", {})

        if (data && data.success) {
          this.$notify.success('document_restored')
          self.openFolder(self.lastSelectedFolder)

        } else if (data && data.msg) {
          this.$notify.error(data.msg)

        } else {
          this.$notify.error('error_retrieving_data')
        }
      },


      goToActivity(activity_id) {
          this.$router.push({'name': 'activity.home', params: {activity_id}})
      },

      goToValidationFlow(validation_id) {
          this.$router.push({'name': 'validation.home', params: {validation_id}})
      },

      onPageChange(value) {
        this.page = value
        this.loadDocumentsList(this.currentActivity.id, this.entityType, this.currentFolder.id, this.loadSubactivities)
      },

      onSelectOrder() {
        this.loadDocumentsList(this.currentActivity.id, this.entityType, this.currentFolder.id, this.loadSubactivities)
      },

      updateModalDocument(event) {
        let newIndex = this.documents.findIndex(d => d.document_id == event.document_id) + event.direction
        if (newIndex == -1) {
          newIndex = this.documents.length - 1
        }
        if (newIndex >= this.documents.length) {
          newIndex = 0
        }
        let newDocument = this.documents[newIndex]
        this.showPreview(newDocument, newIndex)
      },

      // TODO: lo ideal seria que al aliminar no recargue nuevamente todo sino que simplemente elimine el elemento
      // del dom, si es que recibe notificación de que se elimino archivos se deberia verificar si el o los archivos
      // a eliminar estan en la zona de trabajo del usaurio pero debería simplemento eliminar del dom si esque existe
      // y notificar (si esque esta asi no dije nada solo es un apunte para tenerlo cuando se desee optimizar)
      onDelete(document) {
        this.$dialog.confirm({
          title: this.$t("delete_document"),
          message: this.$t("delete_document_confirm_text"),
          confirmText: this.$t("delete_document"),
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => {
            this.filesToDelete.push( document.document_id )
            let url = "/api/v2/" + this.entityType + "/" + this.selectedEntityId + "/document/" + document.document_id;
            const {data} = await axios.post(url);
            if (data && data.success) {
              this.$notify.success("success_deleting_document");

              // En ves de recargar y volver a traer todos los documentos eliminamos del dom
              // si hay problemas descomentar la linea siguiente y comentar la que elimina del dom
              // this.openFolder(this.lastSelectedFolder)  // llama a la api si falla la busqueda en el array
              let pos = this.documents.findIndex( (doc) => { return doc.document_id == document.document_id })
              if ( pos != -1 )
                this.documents.splice(pos, 1)

              // this.$emit("reload-documents-list");
            } else {
              this.$notify.error("error_deleting_document");
            }

            // quitamos el id del documento si existe en el filestodelete se haya o no eliminado
            let index = this.filesToDelete.findIndex( id => id == document.document_id )
            if ( index != -1 )
              this.filesToDelete.splice(index, 1)
          }
        });
      },

      isBeingEliminated(document) {
        return this.filesToDelete.findIndex( id => id == document.document_id ) != -1
      },

      openFilterAvancesFolderClick() {
        this.$refs.refModalFilterFolders.show()
      },

      _HandleInputFilterFolderSearch(filter) {
        if (filter.length >= 3 || filter.length == 0) {
          this.folders = []
          this.getActivitiesList();
        }
      },

      _HandleApplyFilters(args) {
        this.folders = []
        this.getActivitiesList();
      },

      getOptionsMenuFolders(folder) {
        return [
          // Editar
          {
            name: 'folder_edit',
            text: this.$t('edit'),
            icon: 'pencil-alt',
            visible: this.canManageFolders && !folder.is_virtual && folder.id != -1,
            handleOnClick: () => { this.actionFolder('edit', folder) }
          },
          // Eliminar
          {
            name: 'folder_delete',
            text: this.$t('remove'),
            icon: 'trash-alt',
            visible: this.canManageFolders && !folder.is_virtual && folder.documents_count == 0 && folder.children == null && folder.id != -1,
            handleOnClick: () => { this.deleteFolder(folder.id, folder.name) }
          },
          // Descargar carpeta
          {
            name: 'folder_download',
            text: this.$t('download_folder'),
            icon: 'download',
            visible: this.canManageFolders && !folder.is_virtual && (folder.documents_count != 0 || folder.children != null) && folder.id != -1,
            handleOnClick: () => { this.downloadFolder(folder.id) }
          },
          // Descargar carpeta tipo actividad
          {
            name: 'folder_activity_download',
            text: this.$t('download_folder'),
            icon: 'download',
            visible: this.canManageFolders && folder.is_virtual,
            handleOnClick: () => { this.downloadFolderActivity(folder.id) }
          },
          // Mover
          {
            name: 'folder_move',
            text: this.$t('move_folder'),
            icon: 'exchange-alt',
            visible: this.canManageFolders && folder.id != -1 && !folder.is_virtual,
            handleOnClick: () => { this.moveFolder(folder.id) }
          },
        ]
      },

      getOptionsMenuDocuments(document) {
        return [
          // Mover
          {
            name: 'document_move',
            text: this.$t('move_to'),
            icon: 'exchange-alt',
            visible: this.currentFolder.id != -1 && this.canManageDocuments,
            handleOnClick: () => { this.onMoveClick(document) }
          },
          // Eliminar
          {
            name: 'document_delete',
            text: this.$t('delete'),
            icon: 'trash-alt',
            visible: this.currentFolder.id != -1 && this.canManageDocuments,
            handleOnClick: () => { this.onDelete(document) }
          },
          // Restaurar
          {
            name: 'document_restore',
            text: this.$t('restore'),
            icon: 'trash-arrow-up',
            visible: this.currentFolder.id != -1 && this.canManageDocuments,
            handleOnClick: () => { this.undelete(document) }
          },
          // Descargar documento
          {
            name: 'document_download',
            text: this.$t(this.documentDownloadTooltip('download_document', document)),
            icon: 'download',
            visible: true,
            handleOnClick: () => { this.downloadDoc(document) }
          },
          // Subir versión
          {
            name: 'document_upload_version',
            text: this.$t(this.documentDownloadTooltip('upload_version', document)),
            icon: 'upload',
            disabled: !this.isOk(document),
            visible: this.showVersions && this.entityType != 'validation'
              && document.related_validation==null && document.related_validation_task==null && this.canManageDocuments,
            handleOnClick: () => { this.openUploadModal(document.document_id) }
          },
          // Editar
          {
            name: 'document_open_versions',
            text: this.$t('display_versions'),
            icon: 'history',
            disabled: document.versions.length <= 0 || !this.isOk(document),
            visible: this.showVersions && document.versions.length > 0,
            html: document.versions.length > 0
              ? `<span class="ml-1 text-xs text-white font-medium px-2 rounded-full"
                style="background-color: #5577bb;">${document.versions.length }</span>`
              : null,
            handleOnClick: () => { this.openVersionsModal(document.document_id) }
          },
        ]
      }
    },
  };
</script>

<style>
.table_folders > tbody > tr:nth-of-type(odd){
  background: #f1f5f8
}

</style>

<style scoped>
/* .table_folders tbody tr:hover{
  background-color: #e8eaed;
} */

@media (min-width: 1024px) {
  .lg\:inline {
    display: inline !important;
  }

  .lg\:block {
    display: block !important;
  }
}
</style>
