import { render, staticRenderFns } from "./folder-files-filters-fields.vue?vue&type=template&id=482cc8dc&scoped=true&"
import script from "./folder-files-filters-fields.vue?vue&type=script&lang=js&"
export * from "./folder-files-filters-fields.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "482cc8dc",
  null
  
)

export default component.exports