<template>
    <div>
        <div class="flex flex-wrap">
            <v-button icon="arrow-left" @click="goToPage('cost_budget')" color="grey" size="small" class="mr-8">
                {{ $t('back') }}
            </v-button>
        </div>
        <h1 class="flex-grow text-blue-light font-bold text-xl uppercase mt-4 mb-2">
            {{ $t('new') }} {{ $t('indirect_costs') }}
        </h1>
        <div class="top-bar flex justify-start">
            <b-field :label="$t('indirect_cost_name')" class="w-full lg:w-1/4 mr-4">
                <b-input v-model="name" type="text" expanded></b-input>
            </b-field>
            <b-field :label="$t('code')" class="w-full lg:w-1/4 mr-4">
                <b-input v-model="code" type="text" expanded></b-input>
            </b-field>
            <b-field :label="$t('total')" class="w-full lg:w-1/4 mr-4">
                <b-input v-model="price_total" type="number" expanded disabled></b-input>
            </b-field>
        </div>
        <div class="flex justify-end">
            <v-button @click="openIndirectCostModal">{{ $t('add') }} {{ $t('details') }}</v-button>
        </div>
        <indirect-cost-detail-modal ref="indirect_cost_detail_modal" :projectId="projectId" @reload-list="handleReload"
            @total_amount="handleTotalAmount" />
        <indirect-cost-details :projectId="projectId" ref="details_table" @epc-edit="handleEditDetail"
            @reload-list="handleReload" @total-price="handleTotalAmount" />
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";
import IndirectCostDetailModal from "../../components/cost-management/indirect-cost-detail-modal.vue";
import IndirectCostDetails from "../../components/cost-management/indirect-cost-details.vue";

export default {
    components: {
        IndirectCostDetailModal,
        IndirectCostDetails
    },
    data() {
        return {
            projectId: null,
            code: '',
            name: '',
            price_total: 0,
            details: []
        };
    },
    computed: {
        isFormValid() {
            // Validación de los campos 'name' y 'code'
            return this.name !== '' && this.code !== '';
        },
        ...mapGetters({
            navData: "app/navData"
        })
    },
    created() {
        this.projectId = this.navData.project_id;
    },
    methods: {
        handleReload() {
            this.$refs.details_table.reload({ indirect_cost_code: this.code });
        },
        async openIndirectCostModal(detail = null) {
            if (!this.isFormValid) {
                this.$toast.open({
                    message: 'Por favor, completa todos los campos requeridos',
                    type: 'is-danger',
                    position: 'is-top-right'
                });
                return;
            }
            // Pasar 'name' y 'code' al modal
            this.$refs.indirect_cost_detail_modal.show({ name: this.name, code: this.code, detail: detail });
        },
        handleEditDetail(detail) {
            this.openIndirectCostModal(detail);
        },
        handleTotalAmount(total) {
            this.price_total = total;
        },
        goToPage(destiny) {
            switch (destiny) {
                case 'cost_budget':
                    this.$router.push({
                        name: 'project.admin.cost-management',
                        params: {
                            project_id: this.projectId
                        }
                    });
                    break;
            }
        }
    },
    watch: {
        code(newCode) {
            if (newCode) {
                this.handleReload();
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
