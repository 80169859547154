<template>
    <b-modal :active.sync="isModalActive" :width="1028" scroll="keep" :can-cancel="canCancel" @close="closeModal">
        <div class="border border-blue-light rounded shadow bg-white p-4">
            <form @submit.prevent="saveCostBudget" class="w-full">
                <header class="font-thin uppercase text-blue-light modal-card-head_ mb-4">{{ $t('add_generic') }} / {{
                    $t('edit') }}
                    {{ $t('breakdown') }}
                </header>
                <div class="modal-card-body relative">
                    <div class="w-full flex flex-row mb-3">
                        <div class="w-1/2 pr-4">
                            <b-field :label="$t('select_assignment')" :message="resourceError"
                                :type="resourceError ? 'is-danger' : ''">
                                <multiselect v-model="selectedAssignment" :options="assignments"
                                    :placeholder="$t('select') + ' escribe lo que estas buscando'" :multiple="false"
                                    label="name" track-by="id" :show-labels="false" :searchable="true"
                                    :loading="isLoadingAssignments" noOptions="La lista esta vacía"
                                    @search-change="handleSearchChangeAssignments" :maxHeight="200"
                                    :class="{ 'is-danger': resourceError }" @input="handleAssignmentChange" />
                            </b-field>
                        </div>
                    </div>
                    <div class="w-full flex flex-row mb-3">
                        <div class="w-1/2 mr-4">
                            <b-field :label="$t('reference_code')">
                                <v-input v-model="form.reference_code" title="reference_code" required
                                    name="reference_code" placeholder="reference_code" maxlength="100" />
                            </b-field>
                        </div>
                        <b-field :label="$t('select_provider')" class="w-1/2 mr-4">
                            <b-select v-model="form.provider_id" :placeholder="$t('select_provider')" expanded>
                                <option v-for="(provider, index) in providers" :value="provider.id" :key="index">
                                    {{ provider.name }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                    <div class="w-full flex flex-row mb-3">
                        <div class="w-1/2 mr-4">
                            <b-field :label="$t('breakdown_name')">
                                <v-input v-model="form.name" title="breakdown_name" required name="name"
                                    placeholder="breakdown_name" maxlength="100" />
                            </b-field>
                        </div>
                        <div class="w-1/2 mr-4">
                            <b-field :label="$t('unit_price')">
                                <v-input v-model="form.unit_price" title="unit_price" required name="unit_price"
                                    placeholder="unit_price" type="number" step="0.01" />
                            </b-field>
                        </div>
                    </div>
                    <div class="w-full flex flex-row mb-3">
                        <div class="w-1/2 mr-4">
                            <b-field :label="$t('quantity')">
                                <b-input v-model="form.quantity" title="quantity" required name="quantity" type="number"
                                    step="0.01" />
                            </b-field>
                        </div>
                        <div class=" w-1/2 mr-4">
                            <b-field :label="$t('coefficient')">
                                <v-input v-model="form.coefficient" title="coefficient" required name="coefficient"
                                    placeholder="coefficient" type="number" />
                            </b-field>
                        </div>
                    </div>

                    <b-loading :is-full-page="false" v-model="processingData" :can-cancel="false"></b-loading>
                </div>
                <footer class="modal-card-foot_">
                    <div class="w-full inline-flex justify-end">
                        <button class="btn btn-blue" nativeType="submit" v-if="!processingData">{{ $t('save')
                            }}</button>
                        <v-button-processing v-if="processingData" />
                    </div>
                </footer>
            </form>
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect'
export default {
    components: {
        Multiselect
    },
    props: {
        projectId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isModalActive: false,
            processingData: false,
            canCancel: ['escape', 'x', 'outside'],
            selectedProvider: null,
            providers: [],
            form: {
                reference_code: '',
                presto_code: '',
                provider_id: null,
                assignment_id: null,
                name: '',
                unit_price: null,
                quantity: null,
                project_id: this.projectId,
                coefficient: null,
            },
            selectedAssignment: null,
            assignments: [],
            originalAssignments: [],
            isLoadingAssignments: false,
            resourceError: null,
        }
    },
    methods: {
        show(providers, assignments, budget = null) {
            this.providers = providers;
            this.originalAssignments = assignments;
            this.assignments = assignments;
            if (budget) {
                this.form.id = budget.id;
                this.form.reference_code = budget.code;
                this.form.presto_code = budget.presto_code;
                this.form.provider_id = budget.provider.id;
                this.form.assignment_id = budget.assignment.assignment_id;
                this.form.name = budget.name;
                this.form.unit_price = budget.unit_price;
                this.form.quantity = budget.quantity;
                this.form.coefficient = budget.coefficient;
            } else {
                this.resetForm();
            }
            this.isModalActive = true;
        },
        closeModal() {
            this.isModalActive = false;
        },
        async saveCostBudget() {
            this.processingData = true;
            try {
                if (this.form.id) {

                    // Update existing budget
                    await axios.put(`/api/v2/cost_budget/${this.form.id}`, this.form);
                    this.$buefy.toast.open({
                        message: this.$t('save_successful'),
                        type: 'is-success'
                    });
                } else {
                    // Create new budget
                    await axios.post(`/api/v2/cost_budget`, this.form);
                    this.$buefy.toast.open({
                        message: this.$t('save_successful'),
                        type: 'is-success'
                    });
                }
                this.closeModal();
                this.$emit('reload-list');
            } catch (error) {
                this.$buefy.toast.open({
                    message: this.$t('save_error'),
                    type: 'is-danger'
                });
            } finally {
                this.processingData = false;
            }
        },
        resetForm() {
            this.form = {
                id: null,
                reference_code: '',
                presto_code: '',
                provider_id: null,
                assignment_id: null,
                resources: '',
                unit_price: null,
                quantity: null,
                coefficient: null,
                project_id: this.projectId,
            };
            this.selectedAssignment = null;
        },
        handleSearchChangeAssignments(query) {
            if (query.length < 3) {
                this.assignments = this.originalAssignments;
            } else {
                const lowerCaseQuery = query.toLowerCase();
                this.assignments = this.originalAssignments.filter(assignment =>
                    assignment.name.toLowerCase().includes(lowerCaseQuery)
                );
            }
        },
        handleAssignmentChange(selectedAssignment) {
            this.form.assignment_id = selectedAssignment ? selectedAssignment.id : null;
        },
    },


}
</script>

<style lang="scss" scoped></style>
