/**
 * Rutas de la aplicación
 */

const Child = require("~/components/LaraBue/Child").default;

// Rutas del 'front-submodule'
// const Login = () => import("~/pages/auth/login").then(m => m.default); //borrar
const Login = require("~/pages/auth/login").default;
const Register = require("~/pages/auth/register").default;
const PasswordEmail = require("~/pages/auth/password/email").default;
const PictogramFrame = require("·/pages/pictogram/home").default;
const PictogramProgress = require("·/pages/pictogram/progress").default;

// After login
const SelectCompany = require("·/pages/select-company").default;

// Rutas de la App
const AdminHome = require("·/pages/admin/home").default;
const AdminAreas = require("·/pages/admin/areas").default;
const AdminGroups = require("·/pages/admin/groups").default;
const AdminProjects = require("·/pages/admin/projects").default;
const AdminProject = require("·/pages/admin/project-edit").default;
const AdminProjectCreateFromTemplate =
  require("·/pages/admin/project-create-from-template").default;
const AdminProjectTemplates =
  require("·/pages/admin/project-templates").default;
const AdminProjectTemplate =
  require("·/pages/admin/project-template-edit").default;
const AdminLiveMap = require("·/pages/admin/livemap").default;
const AdminLocations = require("·/pages/admin/project-locations").default;
const AdminAssignments = require("·/pages/admin/project-assignments").default;
const AdminMeasurementsTemplate =
  require("·/pages/admin/templates/manage-measurements").default;
const AdminExtrafields = require("·/pages/admin/project-extrafields").default;
const AdminProjectCostManagementIndirectCost =
  require("·/pages/admin/project-cost-management-indirect-cost").default;
const AdminKpi = require("·/pages/admin/project-kpis").default;
const AdminKpiTable = require("·/pages/admin/project-kpis-table").default;
const AdminProjectCostManagementBilling = require("·/pages/admin/project-cost-management-billing").default;
const AdminBulkActions = require("·/pages/admin/project-bulk-actions").default;
const AdminDrawings = require("·/pages/admin/project-drawings").default;
const AdminPictograms = require("·/pages/admin/project-pictograms").default;
const AdminPowerPlant = require("·/pages/admin/project-power-plant").default;
const AdminProjectValidations =
  require("·/pages/admin/project-validations").default;
const AdminProjectValidationsBulkActions =
  require("·/pages/admin/project-validations-bulk-actions").default;
const AdminIssues = require("·/pages/admin/project-issues").default;
const ManageIssueTypesTemplate =
  require("·/pages/admin/templates/manage-issue-types").default;
const ManageChaptersTemplate =
  require("·/pages/admin/templates/manage-chapters").default;
const ManageActivityGroupsTemplate =
  require("·/pages/admin/templates/manage-activity-groups").default;
const AdminPartnersProject = require("·/pages/admin/project-partners").default;
const AdminPartnersWorkOrders =
  require("·/pages/admin/project-partner-work-orders").default;
const AdminDocumentFolders = require("·/pages/admin/document-folders").default;
const AdminProjectStorehouse =
  require("·/pages/admin/project-storehouse").default;
const AdminProjectCostManagement =
  require("·/pages/admin/project-cost-management").default;
const AdminPlanning = require("·/pages/admin/project-planning").default;
const AdminDepartments = require("·/pages/admin/departments").default;
const AdminBim = require("·/pages/project/bim").default;
const AdminUsers = require("·/pages/admin/users").default;
const AdminReports = require("·/pages/admin/report/admins").default;
const AdminEditGroup = require("·/pages/admin/editGroup").default;
const AdminEditUser = require("·/pages/admin/editUser").default;
const AdminPartnerEdit = require("·/pages/admin/partners/editPartner").default;
const AdminPartnerUsers = require("·/pages/admin/partners/users").default;
const AdminPartnerUserEdit = require("·/pages/admin/partners/editUser").default;
const AdminPartnerMachineries =
  require("·/pages/admin/partners/machineries").default;
const AdminPartnerMachineryEdit =
  require("·/pages/admin/partners/editMachinery").default;
const ImportExportActivities =
  require("·/pages/admin/import-export-activities").default;
const ManageActivitiesTemplate =
  require("·/pages/admin/templates/manage-activities").default;
const ManageStatuses = require("·/pages/admin/manage-statuses").default;
const ManageStatusesTemplate =
  require("·/pages/admin/templates/manage-statuses").default;
const ImportUsers = require("·/pages/admin/import-users").default;
const AdminPartners = require("·/pages/admin/partners/partners").default;
const AdminValidations =
  require("·/pages/admin/validations/validations").default;
const EditValidationTemplate =
  require("·/pages/admin/validations/editValidationTemplate").default;
const EditValidationTemplateTask =
  require("·/pages/admin/validations/editValidationTemplateTask").default;
const AdminValidationsBulkActions =
  require("·/pages/admin/validations/validations-bulk-actions").default;
const AdminMeasurementUnits =
  require("·/pages/admin/measurements/units").default;
const AdminProjectRules = require("·/pages/admin/project-rules").default;

//Panel superadmin
const PanelCompanies = require("·/pages/panel/companies").default;
const AdminCompany = require("·/pages/panel/company-edit").default;

// Zona de trabajo
const SelectGroup = require("·/pages/project/select-group").default;
const SelectGroupMap = require("·/pages/project/select-group-map").default;
const SelectGroupRedir = require("·/pages/project/select-group-redir").default;
const SelectProject = require("·/pages/project/select-project").default;
const Project = require("·/pages/project/home").default;
const ProjectDashboard = require("·/pages/project/dashboard").default;
const ProjectDashboardIgnis =
  require("·/pages/project/dashboard-ignis").default;
const ProjectDashboardSando =
  require("·/pages/project/dashboard-sando").default;
const ProjectDashboardAytoMadrid =
  require("·/pages/project/dashboard-aytomadrid").default;
const ProjectDashboardSeiasa =
  require("·/pages/project/dashboard-seiasa").default;
const ProjectDashboardDSO = require("·/pages/project/dashboard-dso").default;
const ProjectDashboardEtaps =
  require("·/pages/project/dashboard-etaps").default;
const ProjectDashboardPhotovoltaic =
  require("·/pages/project/dashboard-photovoltaic").default;
const ProjectDashboardTsk = require("·/pages/project/dashboard-tsk").default;
const ProjectDashboardSanea =
  require("·/pages/project/dashboard-sanea").default;
const ProjectDashboardSaneaDSO =
  require("·/pages/project/dashboard-sanea-dso").default;
const ProjectDashboardReport =
  require("·/pages/project/dashboard-report").default;
const ProjectActivities = require("·/pages/project/activities").default;
const ProjectActivityGroups =
  require("·/pages/project/activity-groups").default;
const ProjectActivitiesKanban =
  require("·/pages/project/activities/kanban").default;
const ProjectActivitiesGantt =
  require("·/pages/project/activities/gantt").default;
const ProjectActivitiesFolders =
  require("·/pages/project/activities/prefolders").default;
const ProjectIssues = require("·/pages/project/issues").default;
const ProjectStorehouse = require("·/pages/project/storehouse").default;
const ProjectLocations = require("·/pages/project/locations").default;
const ProjectMeasurements = require("·/pages/project/measurements").default;
const ProjectValidations = require("·/pages/project/validations").default;
const ProjectDrawings = require("·/pages/project/drawings").default;
const ProjectDocuments = require("·/pages/project/documents").default;
const Activity = require("·/pages/activity/home").default;
const ActivityEdit = require("·/pages/activity/edit-activity").default;
const PreActivity = require("·/pages/activity/pre-activity").default;
const StreetsEdit = require("·/pages/activity/streets").default;
const ExtrafieldsEdit = require("·/pages/entity-extrafields").default;
const Issue = require("·/pages/issue/home").default;
const IssueEdit = require("·/pages/issue/edit-issue").default;
const Validation = require("·/pages/validation/home").default;
const ValidationEdit = require("·/pages/validation/edit-validation").default;
const VisualConfig = require("·/pages/project/visual-config").default;
const ProjectTechnicalSpecification =
  require("·/pages/admin/project-technical-specification").default;
const TechnicalSpecifications =
  require("·/pages/project/technical-specifications/technical-specifications").default;
const TechnicalSpecificationsDashboard =
  require("·/pages/project/technical-specifications/technical-specifications-dashboard").default;
const ActivityInterventions =
  require("·/pages/project/assignment-interventions/assignment-interventions").default;
const ProjectWorkOrders = require("·/pages/admin/project-work-orders").default;

// Daily Report
const ProjectImputations =
  require("·/pages/project/dailyReport/configuration/imputations").default;
const ProjectMeasures =
  require("·/pages/project/dailyReport/configuration/measures").default;
const ProjectProviders =
  require("·/pages/project/dailyReport/configuration/providers").default;
const ProjectMaterials =
  require("·/pages/project/dailyReport/configuration/materials").default;
const ProjectPersonals =
  require("·/pages/project/dailyReport/configuration/personals").default;
const ProjectMachineries =
  require("·/pages/project/dailyReport/configuration/machineries").default;
const ProjectParticipants =
  require("·/pages/project/dailyReport/configuration/participants").default;

// pipes
const PipesEdit = require("·/pages/activity/dismissed-pipes").default;

export default [
  { path: "/", redirect: { name: "login" } },

  // Authenticated routes.
  {
    path: "/admin",
    component: Child,
    children: [
      { path: "", name: "admin.home", component: AdminHome },
      { path: "areas", name: "admin.areas", component: AdminAreas },

      {
        path: "/admin/groups",
        component: Child,
        children: [
          { path: "", name: "admin.groups", component: AdminGroups },
          {
            path: "import",
            name: "admin.groups.import",
            component: ImportUsers,
          },
        ],
      },
      {
        path: "/admin/group/:id?",
        name: "admin.group",
        component: AdminEditGroup,
      },

      { path: "livemap", name: "admin.livemap", component: AdminLiveMap },
      { path: "projects", name: "admin.projects", component: AdminProjects },
      {
        path: "project",
        component: Child,
        children: [
          { path: "", name: "project.create", component: AdminProject },
          {
            path: "create-from-template",
            name: "project.create-from-template",
            component: AdminProjectCreateFromTemplate,
          },
          {
            path: ":project_id",
            component: Child,
            children: [
              { path: "", redirect: { name: "project.edit" } },
              { path: "edit", name: "project.edit", component: AdminProject },
              {
                path: "departments",
                name: "project.departments",
                component: AdminDepartments,
              },
              {
                path: "locations",
                name: "project.admin.locations",
                component: AdminLocations,
              },
              {
                path: "assignments",
                name: "project.admin.assignments",
                component: AdminAssignments,
              },
              {
                path: "extrafields",
                component: Child,

                children: [
                  {
                    path: "",
                    name: "project.admin.extrafields",
                    component: AdminExtrafields,
                  },
                  {
                    path: "edit",
                    name: "project.extrafields.edit",
                    component: ExtrafieldsEdit,
                  },
                ],
              },
              {
                path: "kpi",
                component: Child,
                children: [
                  { path: "", name: "project.admin.kpi", component: AdminKpi },
                  {
                    path: "table",
                    name: "project.admin.kpi.table",
                    component: AdminKpiTable,
                  },
                ],
              },
              {
                path: "bulk-actions",
                component: Child,

                children: [
                  {
                    path: "",
                    name: "project.admin.bulk-actions",
                    component: AdminBulkActions,
                  },
                ],
              },
              {
                path: "drawings",
                name: "project.admin.drawings",
                component: AdminDrawings,
              },
              {
                path: "pictograms",
                name: "project.admin.pictograms",
                component: AdminPictograms,
              },
              {
                path: "power-plant",
                name: "project.admin.powerplant",
                component: AdminPowerPlant,
              },
              {
                path: "validations",
                name: "project.admin.validations",
                component: AdminProjectValidations,
              },
              {
                path: "issues",
                name: "project.admin.issues",
                component: AdminIssues,
              },
              {
                path: "planning",
                name: "project.planning",
                component: AdminPlanning,
              },
              {
                path: "import-export-activities",
                name: "project.import-export-activities",
                component: ImportExportActivities,
              },
              {
                path: "manage-status",
                name: "project.manage-statuses",
                component: ManageStatuses,
              },
              {
                path: "partners",
                component: Child,

                children: [
                  {
                    path: "",
                    name: "project.admin.partners",
                    component: AdminPartnersProject,
                  },
                  {
                    path: ":partner_id",
                    name: "project.admin.partners.workorders",
                    component: AdminPartnersWorkOrders,
                  },
                ],
              },
              {
                path: "document-folders",
                name: "project.admin.document-folders",
                component: AdminDocumentFolders,
              },
              {
                path: "storehouse",
                name: "project.admin.storehouse",
                component: AdminProjectStorehouse,
              },
              {
                path: "cost-management",
                component: Child,
                children: [
                  {
                    path: "",
                    name: "project.admin.cost-management",
                    component: AdminProjectCostManagement,
                  },
                  {
                    path: "billing",
                    name: "project.admin.cost-management.billing",
                    component: AdminProjectCostManagementBilling,
                  },
                  {
                    path: "indirect_cost",
                    name: "project.admin.cost-management.indirect-cost",
                    component: AdminProjectCostManagementIndirectCost,
                  },
                ],
              },
              {
                path: "visual-config",
                name: "project.admin.visual-config",
                component: VisualConfig,
              },
              {
                path: "technical-specification",
                name: "project.admin.technical-specification",
                component: ProjectTechnicalSpecification,
              },
              {
                path: "work-orders",
                name: "project.admin.work-orders",
                component: ProjectWorkOrders,
              },
              {
                path: "rules",
                name: "project.admin.rules",
                component: AdminProjectRules,
              },
              {
                path: "bulk-actions",
                name: "project.admin.project-bulk-actions",
                component: AdminProjectValidationsBulkActions,
              },
            ],
          },
        ],
      },
      {
        path: "project-templates",
        name: "admin.project-templates",
        component: AdminProjectTemplates,
      },
      {
        path: "project-template",
        component: Child,
        children: [
          {
            path: "",
            name: "project-template.create",
            component: AdminProjectTemplate,
          },
          {
            path: ":template_id",
            component: Child,
            children: [
              { path: "", redirect: { name: "project-template.edit" } },
              {
                path: "edit",
                name: "project-template.edit",
                component: AdminProjectTemplate,
              },
              {
                path: "manage-status",
                name: "project-template.manage-statuses",
                component: ManageStatusesTemplate,
              },
              {
                path: "issues-type",
                name: "project-template.admin.issue-types",
                component: ManageIssueTypesTemplate,
              },
              {
                path: "chapters",
                name: "project-template.admin.chapters",
                component: ManageChaptersTemplate,
              },
              {
                path: "activity-groups",
                name: "project-template.admin.activity-groups",
                component: ManageActivityGroupsTemplate,
              },
              {
                path: "import-export-activities",
                name: "project-template.import-export-activities",
                component: ManageActivitiesTemplate,
              },
              {
                path: "measurements",
                name: "project-template.admin.measurements",
                component: AdminMeasurementsTemplate,
              },
            ],
          },
        ],
      },
      {
        path: "measurement-units",
        name: "admin.measurement-units",
        component: AdminMeasurementUnits,
      },
      {
        path: "/admin/users",
        component: Child,
        children: [
          { path: "", name: "admin.users", component: AdminUsers },
          {
            path: "import",
            name: "admin.users.import",
            component: ImportUsers,
          },
        ],
      },
      {
        path: "/admin/validations",
        component: Child,
        children: [
          { path: "", name: "admin.validations", component: AdminValidations },
          {
            path: "bulk-actions",
            name: "admin.validations.bulk-actions",
            component: AdminValidationsBulkActions,
          },
        ],
      },
      {
        path: "validation-template",
        component: Child,
        children: [
          // { path: "", name: "validation.create", component: AdminProject },
          {
            path: ":validation_id",
            component: Child,
            children: [
              { path: "", redirect: { name: "validation-template.edit" } },
              {
                path: "edit",
                name: "validation-template.edit",
                component: EditValidationTemplate,
              },

              {
                path: "task",
                component: Child,
                children: [
                  {
                    path: ":task_id",
                    component: Child,
                    children: [
                      {
                        path: "",
                        redirect: { name: "validation-template-task.edit" },
                      },
                      {
                        path: "edit",
                        name: "validation-template-task.edit",
                        component: EditValidationTemplateTask,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/admin/partners",
        component: Child,
        children: [
          { path: "", name: "admin.partners", component: AdminPartners },
        ],
      },
      {
        path: "partner",
        component: Child,
        children: [
          { path: "", name: "partner.create", component: AdminPartnerEdit },
          {
            path: ":id",
            component: Child,
            children: [
              { path: "", redirect: { name: "partner.edit" } },
              {
                path: "edit",
                name: "partner.edit",
                component: AdminPartnerEdit,
              },
              {
                path: "users",
                name: "partner.users",
                component: AdminPartnerUsers,
              },
              {
                path: "user/:userId?",
                name: "partner.user.edit",
                component: AdminPartnerUserEdit,
              },
              {
                path: "machineries",
                name: "partner.machineries",
                component: AdminPartnerMachineries,
              },
              {
                path: "machinery/:machineryId?",
                name: "partner.machinery.edit",
                component: AdminPartnerMachineryEdit,
              },
            ],
          },
        ],
      },
      {
        path: "/admin/report/admins/:id?",
        name: "admin.reports",
        component: AdminReports,
      },
      {
        path: "/admin/user/:id?",
        name: "admin.user",
        component: AdminEditUser,
      },
      {
        path: "/admin/collaborator/:id?",
        name: "admin.collaborator",
        component: AdminEditUser,
      },
    ],
  },

  // Zona de trabajo
  { path: "/select-company", name: "company.select", component: SelectCompany },
  { path: "/select-group", name: "group.select", component: SelectGroup },
  {
    path: "/select-group-map",
    name: "group.select.map",
    component: SelectGroupMap,
  },
  {
    path: "/select-group-redir",
    name: "group.preselect",
    component: SelectGroupRedir,
  },
  { path: "/select-project", name: "project.select", component: SelectProject },
  {
    path: "/project",
    component: Child,
    children: [
      { path: "", name: "project.home", component: Project },
      {
        path: "activities",
        name: "project.activities",
        component: ProjectActivities,
      },
      {
        path: "activities/gantt",
        name: "project.activities.gantt",
        component: ProjectActivitiesGantt,
      },
      {
        path: "activities/kanban",
        name: "project.activities.kanban",
        component: ProjectActivitiesKanban,
      },
      {
        path: "activities/folders",
        name: "project.activities.folders",
        component: ProjectActivitiesFolders,
      },
      {
        path: "activities/groups",
        name: "project.activities.groups",
        component: ProjectActivityGroups,
      },
      {
        path: "measurements",
        name: "project.measurements",
        component: ProjectMeasurements,
      },
      { path: "issues", name: "project.issues", component: ProjectIssues },
      {
        path: "orgchart",
        name: "project.orgchart",
        component: AdminDepartments,
      },
      {
        path: "storehouse",
        name: "project.storehouse",
        component: ProjectStorehouse,
      },
      {
        path: "design-viewer",
        name: "project.bim",
        component: AdminBim,
      },
      {
        path: "locations",
        name: "project.locations",
        component: ProjectLocations,
      },
      {
        path: "validations",
        name: "project.validations",
        component: ProjectValidations,
      },
      {
        path: "technical-specifications",
        component: Child,
        children: [
          {
            path: "",
            name: "project.technical-specifications",
            component: TechnicalSpecifications,
          },
          {
            path: "dashboard",
            name: "project.technical-specifications.dashboard",
            component: TechnicalSpecificationsDashboard,
          },
        ],
      },
      {
        path: "documents",
        name: "project.documents",
        component: ProjectDocuments,
      },
      {
        path: "drawings",
        name: "project.drawings",
        component: ProjectDrawings,
      },
      {
        path: "dashboard",
        component: Child,
        children: [
          { path: "", name: "project.dashboard", component: ProjectDashboard },
          {
            path: "avances",
            name: "project.dashboard.ignis",
            component: ProjectDashboardIgnis,
          }, // dejamos en este orden por que es como lo vue
          {
            path: "sando",
            name: "project.dashboard.sando",
            component: ProjectDashboardSando,
          },
          {
            path: "aytomadrid",
            name: "project.dashboard.aytomadrid",
            component: ProjectDashboardAytoMadrid,
          },
          {
            path: "seiasa",
            name: "project.dashboard.seiasa",
            component: ProjectDashboardSeiasa,
          },
          {
            path: "etaps",
            name: "project.dashboard.etaps",
            component: ProjectDashboardEtaps,
          },
          // { path: "tsk", name: "project.dashboard.tsk", component: ProjectDashboardTsk },
          {
            path: "photovoltaic",
            name: "project.dashboard.photovoltaic",
            component: ProjectDashboardPhotovoltaic,
          },
          {
            path: "report",
            name: "project.dashboard.report",
            component: ProjectDashboardReport,
          },
          {
            path: "tsk",
            name: "project.dashboard.tsk",
            component: ProjectDashboardTsk,
          },
          {
            path: "sanea",
            name: "project.dashboard.sanea",
            component: ProjectDashboardSanea,
          },
          {
            path: "sanea/:activity_id",
            name: "project.dashboard.sanea.dso",
            component: ProjectDashboardSaneaDSO,
          },
          {
            path: ":activity_id",
            name: "project.dashboard.dso",
            component: ProjectDashboardDSO,
          },
        ],
      },
    ],
  },
  {
    path: "/activity",
    component: Child,
    children: [
      { path: "", name: "activity.create", component: ActivityEdit },
      {
        path: ":activity_id",
        component: Child,
        children: [
          { path: "", name: "activity.home", component: Activity },
          { path: "edit", name: "activity.edit", component: ActivityEdit },
          {
            path: "subactivity",
            component: Child,
            children: [
              { path: "", name: "subactivity.create", component: ActivityEdit },
              {
                path: ":subactivity_id",
                component: Child,
                children: [
                  { path: "", name: "subactivity.home", component: Activity },
                  {
                    path: "edit",
                    name: "subactivity.edit",
                    component: ActivityEdit,
                  },
                  {
                    path: "issue",
                    component: Child,
                    children: [
                      {
                        path: "",
                        name: "subissue.create",
                        component: IssueEdit,
                      },
                      {
                        path: ":issue_id/edit",
                        name: "subissue.edit",
                        component: IssueEdit,
                      },
                    ],
                  },
                  {
                    path: "extrafields",
                    component: Child,
                    children: [
                      {
                        path: "edit",
                        name: "subactivity.extrafields.edit",
                        component: ExtrafieldsEdit,
                      },
                    ],
                  },
                  {
                    path: "interventions",
                    name: "subactivity.interventions",
                    component: ActivityInterventions,
                  },
                ],
              },
            ],
          },
          {
            path: "extrafields",
            component: Child,
            children: [
              {
                path: "edit",
                name: "activity.extrafields.edit",
                component: ExtrafieldsEdit,
              },
            ],
          },
          {
            path: "streets",
            component: Child,
            children: [
              {
                path: "edit",
                name: "activity.streets.edit",
                component: StreetsEdit,
              },
            ],
          },
          {
            path: "dismissed-pipes",
            component: Child,
            children: [
              {
                path: "edit",
                name: "activity.dismissed-pipes.edit",
                component: PipesEdit,
              },
            ],
          },
          {
            path: "issue",
            component: Child,
            children: [
              { path: "", name: "issue.create", component: IssueEdit },
              {
                path: ":issue_id",
                component: Child,
                children: [
                  {
                    path: "/activity/:activity_id/subactivity/:subactivity_id/issue/:issue_id",
                    name: "issue.home-sub",
                    component: Issue,
                  },
                  {
                    path: "/activity/:activity_id/issue/:issue_id",
                    name: "issue.home",
                    component: Issue,
                  },
                  { path: "edit", name: "issue.edit", component: IssueEdit },
                ],
              },
            ],
          },
          { path: "interventions", name: "activity.interventions", component: ActivityInterventions },
          { path: "prelation", name: "activity.prelation", component: PreActivity }
        ]
      }
    ]
  },

  {
    path: "/validation",
    component: Child,
    children: [
      // { path: "", name: "validation.create", component: ValidationEdit }, por ahora via excel
      {
        path: ":validation_id",
        component: Child,
        children: [
          { path: "", name: "validation.home", component: Validation },
          { path: "edit", name: "validation.edit", component: ValidationEdit },
        ],
      },
    ],
  },
  {
    path: "/pictogram/:pictogramId/:wl?/:activity?/:subactivity?",
    name: "progress.pictograms",
    component: PictogramProgress,
  },
  // { path: "/pictogram-frame/:pictogramId/:wl?", name: "frame.pictograms", component: PictogramFrame },

  //panel superadmin
  {
    path: "/panel",
    component: Child,
    children: [
      { path: "", name: "panel.companies", component: PanelCompanies },
      { path: "/company", name: "company.create", component: AdminCompany },
      {
        path: "/company/:company_id",
        name: "company.edit",
        component: AdminCompany,
      },
    ],
  },

  // Daily Report
  {
    path: "/project/report/imputation/:project_id",
    name: "project.imputation",
    component: ProjectImputations,
  },
  {
    path: "/project/report/measure/:project_id",
    name: "project.measure",
    component: ProjectMeasures,
  },
  {
    path: "/project/report/provider/:project_id",
    name: "project.provider",
    component: ProjectProviders,
  },
  {
    path: "/project/report/material/:project_id",
    name: "project.material",
    component: ProjectMaterials,
  },
  {
    path: "/project/report/personal/:provider_id",
    name: "project.personal",
    component: ProjectPersonals,
  },
  {
    path: "/project/report/machinery/:provider_id",
    name: "project.machinery",
    component: ProjectMachineries,
  },
  {
    path: "/project/report/participant/:provider_id",
    name: "project.participant",
    component: ProjectParticipants,
  },

  // Guest routes.
  { path: "/login", name: "login", component: Login },
  // { path: "/register", name: "register", component: Register }, // No es necesario poder acceder
  {
    path: "/password/reset",
    name: "password.request",
    component: PasswordEmail,
  },

  { path: "*", component: require("~/pages/errors/404") },
  //  { path: "*", component: () => import('~/pages/errors/404') }
];
