<template>
  <div class="flex flex-row max-h-screen bg-white border rounded shadow overflow-x-none border-blue-light_ relative"
    style="height:75vh" v-if="document">

    <div
      :id="'viewer_' + nameViewerRef"
      :ref="'viewer_' + nameViewerRef"
      class="flex flex-col w-full h-full"
      style="height:75vh"></div>

  </div>
</template>

<script>
  import EventBus from "~/plugins/bus";
  import {mapGetters} from "vuex";
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
  import WebViewer from "@pdftron/webviewer";
  import axios from 'axios'
  import FileUploader from "~/plugins/fileUploader";

  import  {  getInstance  }  from  '@pdftron/webviewer'

  export default {
    name: "epc-editor-document",

    components: {
      ScaleLoader,
      WebViewer
    },

    props: {
      entityId: {type: Number, required: true},
      entityType: {type: String, required: true},
      enableMeasurement: { type: Boolean, default: false },
      enableBtnSave: { type: Boolean, default: true },
      nameViewerRef: { type: String, default: 'web' }
    },

    data: () => ({
      filename: '',
      extension: '',
      uploadEndpoint: '',
      document: null,
      isOpen: false,
      isPdf: true,
      isLoading: true,
      userSignatures: '', // firmas generadas en pdftron guardadas en otras ocasiones

      annotationsMeasure: [],
      annotationsMeasureNew: [],

      iframeId: null,
      instanceViewerPDF: null,
    }),

    mounted() {
      this.addMessageHandler();
    },

    computed: {
      ...mapGetters({
        project: 'app/project',
        user: 'auth/user',
      }),
    },

    beforeDestroy() {
      EventBus.$off("load-locations");
      this.removeMessageHanlder();

      if( this.instanceViewerPDF != null ) {
        this.instanceViewerPDF.UI.dispose()
        this.instanceViewerPDF = null
      }
    },

    methods: {

      addMessageHandler: function() {
        let self = this;

        // escuchará los mensajes enviados desde /pdftron_config.js
        window.addEventListener('message', this.handleAddMessage);
      },


      removeMessageHanlder: function(){
        window.removeEventListener('message', this.handleAddMessage);
      },

      handleAddMessage(event) {
        let self = this;

        // recibido mensaje con id del iframe creado por Webviewer
        if (event.data.type=='viewerReady') {

          // en produccion: assets en cloudfront.net, distinto al dominio *.epc-tracker.com, limitaciones protocolarias no recibe id iframe
          // Si no recibe id de iframe, detectarlo aqui, será el primer nodo hijo del div 'webviewer'
          let iframeId = event.data.data || document.getElementById('viewer_' + self.nameViewerRef).childNodes[0].id
          self.iframeId = iframeId
          // enviamos mensaje a ese iframe con el documento a mostrar
          self.sendMessage(iframeId) // id del iframe que crea Webviewer para mostrarse
        }

        // recibido mensaje con el documento ya editado que hay que guardar
        if (event.data.type=='saveDocument') {
          // guardar como nueva version
          self.saveDocument(event.data.data) // Blob del fichero editado
        }

        // recibido mensaje con las firmas del usuario a guardar
        if (event.data.type=='saveSignatures') {
          // guardar firmas en DB (firmas vienen serializadas. Son trazos si son dibujos y base64 si son imagenes)
          self.saveSignatures(event.data.data)
        }

        // se se ha dibujado una anotación tipo medición
        if (event.data.type=='drawingLine') {
          // captura la medición
          self.emitDrawingLine(event.data.data)
        }
      },


      // envia postMessage que será recogido en /pdftron_config.js
      sendMessage(frameId='webviewer-1') {
        // pasa un mensaje al iframe alojado en "otro" dominio (cloudfront.net)
        // con url del documento a cargar. Asi evitamos cors
        document.getElementById(frameId)
          .contentWindow.postMessage({ type:'epc-document', data: this.document }, '*')

        // establecer usuario que está editando (aparecerá su nombre al firmar)
        document.getElementById(frameId)
          .contentWindow.postMessage({ type:'epc-user', data: this.user }, '*')

        // carga en herramienta de firmas las firmas guardadas del usuario
        if (this.userSignatures.length > 0) {
          document.getElementById(frameId)
            .contentWindow.postMessage({ type:'user-signatures', data: this.userSignatures }, '*')
        }

        if ( this.enableMeasurement ) {
          document.getElementById(frameId)
            .contentWindow.postMessage({ type:'only-distance-measurement', data: { enable: this.enableMeasurement } }, '*')
        }

        if ( this.document.project_document_scale != null ) {
          document.getElementById(frameId)
            .contentWindow.postMessage({ type:'set-scale-drawings', data: { scale: this.document.project_document_scale } }, '*')
        }

        // Mandamos message para deshanilitar boton guardar
        document.getElementById(frameId)
          .contentWindow.postMessage({ type: 'enable-button-save', data: { enable: this.enableBtnSave } }, '*')

      },

      loadPdftron() {
        // Esto creará un iframe dentro del div webviewer
        // y aparentemente estará alojado en otro dominio ya que va por la url de los assets (clouldfront.net)
        this.instanceViewerPDF = WebViewer({
          licenseKey: 'EPC TRACKER DEVELOPMENTS S.L. (epc-tracker.com):OEM:EPC Tracker::B+:AMS(20240330):82B571D30477A80A0360B13AC9A2D57860612FDDEBC3AD8846C78E53BF875C0791C6B6F5C7',
          path: `${process.env.ASSET_PATH}webviewer`,
          config: `${process.env.ASSET_PATH}pdftron_config.js`,
          enableMeasurement: this.enableMeasurement,
        }, this.$refs['viewer_' + this.nameViewerRef])
          .then(instance => {
            // todo el codigo está en el fichero de pdftron_config.js pasado arriba
            // para que pueda ser ejecutado en produccion donde el dominio (app/dev).epc-tracker.com
            // será distinto que los asset (*.cloudfront.net)
            // asi se evita los problemas de cors porque ese fichero pdftron_config.js se ejecuta en el mismo dominio que webviewer
            // para ello hemos insertado los dominios en whitelisted in public/pdftron_configorigin.txt
            this.instanceViewerPDF = instance

          })
      },


      async saveDocument(blobDocument) {

        let params = {
          entityType: this.entityType,
          entityId: this.entityId,
          projectId: this.project.id,
          uploadEndpoint: this.uploadEndpoint,
        }

        // Recuperar el nombre perdido en el webviewer
        blobDocument.name = this.document.name

        this.isLoading = true
        const res = await FileUploader.submitFile(blobDocument, params)
        this.isLoading = false

        if (res) {
          this.$notify.success("success_editing_document");
          this.$emit("reload-documents-list");

          this.$emit("data-processing-finished", true) //

          this.hide() // hide modal
        } else {
          this.$notify.error("upload-document_error");
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'El documento '+this.document.name+' no se ha podido guardar, por favor intentelo de nuevo',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }

      },


      async getSignatures() {

        let self = this;

        const {data} = await axios.get('/api/v2/user/' + this.user.id + '/pdf-signatures')
        if (data && data.success) {
          self.userSignatures = data.data.signatures || ''
        } else {
          console.log("Could not load user PDFSignatures")
        }

      },


      async saveSignatures(signatures) {

        const {data} = await axios.post('/api/v2/user/' + this.user.id + '/pdf-signatures', { signatures })
        if (data && data.success) {
          // do nothing. silently saved
        } else {
          this.$notify.error(data.msg)
        }

      },


      async show(document) {

        this.hide()
        await this.sleep(1000)
        this.isLoading = true

        this.getSignatures(); // aqui en show trae firmas nuevas cada vez que edita. En created solo cada vez que recarge. Evaluar...
        this.uploadEndpoint = `/api/v2/document/${document.document_id}/add_version/${this.entityType}/${this.entityId}`

        this.document = document;
        this.extension = this.getFileExtension(document);
        this.filename = document.name.replace(/\.[^/.]+$/, "");

        // Esperar que el div 'webviewer' esté cargado en el dom y se pueda referenciar
        setTimeout(() => this.loadPdftron(), 1000)

        this.isLoading = false
      },

      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },

      getFileExtension(file) {
        let re = /(?:\.([^.]+))?$/;

        return '.' + re.exec(file.name)[1];
      },


      hide() {
        this.document = null;
        this.filename = "";
        this.extension = "";
      },

      clickButtonSavePdfTron(data) {
        document.getElementById(this.iframeId)
          .contentWindow.postMessage({ type:'save-version', data: data }, '*')
      },

      emitDrawingLine(data) {
        this.$emit( 'drawing-line', data )
      }
    }
  };
</script>
