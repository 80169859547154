<template>
  <section>
    <div class="mt-4">
      <div class="flex mr-4 text-blue cursor-pointer" @click="downloadTemplate">
        <b-icon pack="fas" icon="file-excel"></b-icon>
        {{ $t('download_template') }}
      </div>
      <template>
        <section>
          <b-modal v-model="downloadModal" title="Descargar plantilla de importación de tareas" :width="300">
            <div class="flex flex-col bg-white p-4 rounded-lg" >
              <div class="flex items center">
                <span class="text-blue md:text-lg font-bold">{{$t('select_template')}}</span>
              </div>
              <div class="border-b border-gray-300 mt-4"></div>
              <div class="flex flex-col mt-4" v-for="(file, index) in files" :key="index">
                <div class="flex items-center cursor-pointer mt-2
                " @click="downloadTemplateByName(file.filename)">
                  <b-icon pack="fas" icon="file-excel" class="mr-2 text-blue"></b-icon>
                  <span class="text-blue"
                  >{{ file.name }}</span>
                </div>
              </div>
            </div>
          </b-modal>
        </section>
      </template>
      <div class="panel">
        <form @submit.prevent="submitForm" :files="true">
          <div class="flex w-full">
            <div class="flex">
              <b-field class="flex mr-4">
                <b-upload v-model="form.excelFile" @input="cleanErrors" required
                  accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                  <a class="button is-primary">
                    <b-icon pack="fas" icon="upload"></b-icon>
                    <span>{{ $t('select_file') }}</span>
                  </a>
                </b-upload>
                <span class="file-name" v-if="form.excelFile">{{ form.excelFile.name }}</span>
              </b-field>
            </div>
            <div class="flex" v-if="entityType == 'assignment'">
              <b-field class="mr-2">
                <b-select v-model="form.assignmentType" size="is-small" rounded icon="file" :required="true">
                  <option value="task">Tareas</option>
                  <option value="resources_bc3">Recursos (BC3)</option>
                  <option value="certifiers">Certificadoras</option>
                  <option value="resources_bcs">Simple</option> <!-- para importar mediciones plantilla simple -->
                  <option value="chapters">Capítulos</option> <!-- para importar capítulos -->
                  <option value="certifiers-chapters">Capítulos con partidas</option>
                  <!-- para importar capítulos con certificadoras dentro -->
                </b-select>
              </b-field>
            </div>
            <div class="flex" v-if="entityType == 'expected_executions'">
              <b-field class="mr-2">
                <b-select v-model="form.workType" size="is-small" rounded icon="file" :required="true">
                  <option value="0">Tuberias</option>
                  <option value="2">Economico Ejecutado</option>  
                  <option value="3">Economico Redactado</option>  
                  <option value="4">Ejecutado Mínimo (6m)</option>
                  <option value="5">Redactado Mínimo (3m)</option>
                  <option value="6">Economico Ejecutado DSO</option>
                </b-select>
              </b-field>
            </div>
            <div class="flex" v-if="overwriteEnabled">
              <v-switch v-model="form.overwrite" ref="form_overwrite" :label="$t('allow_to_overwrite')"
                :color="form.overwrite ? 'green' : 'red'" class="mr-1"
                v-on:checked="(value) => form.overwrite = value" />
            </div>
            <div class="flex ml-4">
              <slot name="descripcion"></slot>
            </div>
          </div>
          <!-- fragmento provisional. Lotes canal cargas anteriores -->
          <div class="flex w-full mt-4 mb-0" v-if="form.assignmentType == 'task'">
            <div class="flex w-full">
              (Fecha efectiva: Solo admin Epc-tracker)
            </div>
            <div class="flex w-full">
              <b-field class="w-full">
                <b-datepicker icon-pack="fas" type="month" v-model="effectiveDate" :placeholder="'fecha efectiva'"
                  :month-names="$t('months_names')" :day-names="$t('days_names')"
                  :first-day-of-week="parseInt($t('first_day_of_week'))" :readonly="false" :max-date="previousMonth">
                </b-datepicker>
              </b-field>
            </div>
          </div>
          <!-- fin fragmento provisional -->
          
          <!-- IMPORTANDO MEDICIONES. Linea para busqueda actividad y fecha efectiva -->
          <div class="flex w-full mt-4 mb-0"
            v-if="form.assignmentType == 'certifiers' || form.assignmentType == 'chapters' || form.assignmentType == 'certifiers-chapters'">
            <div class="flex w-full">
              <b-field class="w-full">
                <b-autocomplete required :placeholder="$t('planning_code') + ' ' + $t('activity')"
                  v-model="form.activityCode" minlength="3" maxlength="255" expanded :disabled="disableActivityCode"
                  :data="filteredActivities" @input="getFilteredActivities">
                </b-autocomplete>
              </b-field>
            </div>
            <div class="flex w-full">
              <b-field class="w-full">
                <b-datepicker type="month" v-model="effectiveDate" :placeholder="'fecha efectiva'"
                  :month-names="$t('months_names')" :day-names="$t('days_names')"
                  :first-day-of-week="parseInt($t('first_day_of_week'))" :readonly="false" :max-date="previousMonth">
                </b-datepicker>
              </b-field>
            </div>
          </div>

          <!-- IMPORTANDO PLANIFICACION DE AVANCES. Linea para busqueda actividad, solo para el caso especial 'Economico Ejecutado DSO' -->
          <div class="flex w-full mt-4 mb-0"
            v-if="form.workType == 6">
            <div class="flex w-full">
              <b-field class="w-full">
                <b-autocomplete required :placeholder="$t('planning_code') + ' ' + $t('activity')"
                  v-model="form.activityCode" minlength="3" maxlength="255" expanded :disabled="disableActivityCode"
                  :data="filteredActivities" @input="getFilteredActivities">
                </b-autocomplete>
              </b-field>
            </div>
          </div>

          <!-- input de descripcion -->
          <div class="flex w-full mt-4">
            <div class="flex w-full">
              <b-field class="w-full">
                <b-input required :placeholder="$t('description')" v-model="form.description" maxlength="255"
                  expanded></b-input>
              </b-field>
            </div>
          </div>

          <div class="flex w-full">
            <v-button :loading="isLoading" type="submit" color="green" icon="file-import" icon-pack="fas">{{
              $t('import') }}</v-button>
          </div>
        </form>
      </div>
      <div>
        <b-tabs type="is-boxed" v-model="importActiveTab">
          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="box-open"></b-icon>
              <span>{{ $t('imports_archive') }}</span>
            </template>
            <div>
              <entity-imports-archive v-if="withHistory" :project-id="projectId" :partner-id="partnerId"
                :company-id="companyId" :entity-type="entityType" :overwrite-field="true"
                ref="entity_imports"></entity-imports-archive>
            </div>
          </b-tab-item>

          <b-tab-item :disabled="errors.length < 1">
            <template slot="header">
              <b-icon pack="fas" icon="exclamation-circle"></b-icon>
              <span>
                {{ $t('error_log') }}
                <b-tag rounded>{{ errorsCount }}</b-tag>
              </span>
            </template>
            <div>
              <div class="flex items-center font-bold text-red-dark text-ml mb-4">
                <span>{{ $t('error_import_title') }}</span>
              </div>
              <div v-for="(error, index) in errors" :key="'error_' + index">
                <ul>
                  <li v-for="(msg, index) in error.messages" :key="'errorli_' + index" class="menu-header mb-2">
                    <div class="flex items-center">
                      <div class="text-red-dark text-ml">
                        <span>
                          {{ $t('import_row_title', [error.row]) }}.
                          {{ msg }}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </section>
</template>

<script>
import Form from "vform";
import entityImportsArchive from "./entity-imports-archive";

import axios from 'axios';
import moment from "moment";

export default {
  name: "import-entities",
  components: {
    entityImportsArchive,
  },

  props: {
    withHistory: { type: Boolean, default: true },
    projectId: { type: Number, default: null, required: false },
    partnerId: { type: Number, default: null, required: false },
    companyId: { type: Number, default: null, required: false },
    entityId: { type: String, default: '', required: false }, // mediciones certificadoras van sobre una actividad concreta
    entityType: { type: String, required: true },
    canOverwrite: { type: Boolean, default: false },
    actionType: { type: String, default: '' }, // distinciones a tener en cuenta por el importador
    morphType: { type: String, default: '' }, // usado para cuando se tenga que trabajar con importaciones polimorphicas
    assignmentType: { type: String, default: 'certifiers' }, // para cargar certificadoras se requiere que por defecto sea seleccionado
  },

  created() {
    // si pasamos un codigo actividad, para importar cantidades ejecutadas sobre una medicion (desde la modal)
    if (this.entityId !== '') {
      this.form.activityCode = this.entityId
      this.form.overwrite = 1 // para cargar cantidades ejecutadas se requiere esta opcion a true
      this.form.assignmentType = this.assignmentType
      this.disableActivityCode = true
    }
  },

  data: () => ({
    isLoading: false,
    form: new Form({
      projectId: null,
      partnerId: null,
      companyId: null,
      excelFile: null,
      description: "",
      overwrite: false,
      assignmentType: null,
      activityCode: null,
      actionType: "",
      workType: null,
      effectiveDate: "",
    }),
    files: [
      { filename:"template_import_recursos_(BC3).xlsx", entityType: "assignment", name:"Recursos (BC3)" },
      { filename:"template_import_mediciones_Simple.xlsx", entityType: "assignment", name:"Simple" },
    ],
    downloadModal: false,
    effectiveDate: null,
    errors: [],
    importActiveTab: 0,
    errorsCount: 0,
    disableActivityCode: false,
    filteredActivities: [],
  }),

  computed: {
    templateName() {
      // TODO: de momento hacemos este hack para mantener funcionando (replace('/', '-')) lo ideal es quitar el replace y mantener una convencion para entitytype
      return this.assetVapor("template_import_" + this.entityType.replace('/', '-') + ".xlsx");
    },
    templateFileName() {
      // TODO: de momento hacemos este hack para mantener funcionando (replace('/', '-'))
      return this.$t(this.entityType.replace('/', '-') + "_import_template") + ".xlsx";
    },
    overwriteEnabled() {
      return this.canOverwrite;
    },
    previousMonth() {
      let today = new Date()
      return new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
    }
  },

  methods: {
    submitForm() {
      this.isLoading = true;
      var self = this;
      this.cleanErrors();

      this.form.projectId = this.projectId;
      this.form.partnerId = this.partnerId;
      this.form.companyId = this.companyId;
      this.form.actionType = this.actionType;
      this.form.effectiveDate = this.effectiveDate
        ? moment(this.effectiveDate).format('YYYY-MM-DD')
        : "";
      if (this.morphType)
        this.form.morphType = this.morphType
      this.form
        .post("/api/v2/import/" + this.entityType)
        .then(function (response) {

          self.isLoading = false;
          if (response.data.success) {

            self.$notify.success("success_import");
            self.form.description = "";
            if (self.withHistory)
              self.$refs.entity_imports.reloadImports();
            self.$emit("success");
          } else {

            self.$notify.error("error_import");

            // Procesamos los errores para mostrarlos
            for (let key in response.data.errors) {
              let error_info = key.split(".");
              let row = parseInt(error_info[0]);
              let field = `[${error_info[1]}]`;//self.$t(error_info[1]);

              let messages = response.data.errors[key].map(function (msg) {
                return self.$t(msg) !== msg
                  ? self.$t(msg, [field])
                  : msg; // prefiero el msg tal cual se devuelve en el importador
                // : self.$t("import_generic_error_msg", [parseInt(field)+1]); // mensaje generico sin muchas pistas
              });

              self.errorsCount = self.errorsCount + messages.length;

              self.errors.push({ row: row, field: field, messages: messages });
            }

            self.errors = self.errors.sort(function (a, b) {
              return parseInt(a.row) - parseInt(b.row);
            });

            self.importActiveTab = 1;
          }
        })
        .catch(error => {
          self.isLoading = false;
          self.$notify.error("error_import");
        });

      this.form.excelFile = null;
    },

    cleanErrors() {
      this.errors = [];
      this.errorsCount = 0;
      this.importActiveTab = 0;
    },
    downloadTemplateByName(filename) {
      let link = document.createElement("a");
      link.href = this.assetVapor(filename);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
    },
    downloadTemplate() {
      if (this.entityType == 'assignment') {
        this.downloadModal = true;
        return;
      }
      let link = document.createElement("a");
      link.href = this.templateName;
      link.download = this.templateFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    async getFilteredActivities() {
      if (this.form.activityCode && this.form.activityCode.length > 2) {
        let getParams = {
          p: this.projectId,
          filter_planning_code: this.form.activityCode,
          light: 1
        }
        const { data } = await axios.get('/api/v2/activities', { params: getParams });
        this.filteredActivities = data.activities.map(activity => activity.planning_code);
      }

      else {
        this.filteredActivities = [];
      }
    },
  }
};
</script>
