<template>
    <div>
        <!-- Tabla de recursos asociados a una factura -->
        <div class="panel">
            <b-table :data="products" :loading="isLoadingTable" paginated backend-pagination :per-page="perPage"
                :total="total" @page-change="onPageChange" backend-sorting :default-sort="orderField"
                :default-sort-direction="orderDir" @sort="onSort" class="w-full" striped bordered icon-pack="fas">
                <template>
                    <b-table-column field="name" :label="$t('associated_resources')" sortable v-slot="props">
                        {{ props.row.product.name }}
                    </b-table-column>
                    <b-table-column field="unit_price " :label="$t('unit_price')" sortable v-slot="props" centered>
                        {{ formatNumber(props.row.unit_price) }}
                    </b-table-column>
                    <b-table-column field="quantity" :label="$t('quantity')" sortable v-slot="props">
                        {{ props.row.quantity === 0 ? "-" : props.row.quantity }}
                    </b-table-column>
                    <b-table-column field="total" :label="$t('total_resource')" sortable v-slot="props">
                        {{ formatNumber(props.row.quantity === 0 ? props.row.unit_price : props.row.quantity
                            * props.row.unit_price) }}
                    </b-table-column>
                    <b-table-column :label="$t('actions')" field="actions" centered width="150" v-slot="props">
                        <div class="w-full h-full flex items-center justify-around">
                            <b-tooltip :label="$t('edit_billing')" position="is-left">
                                <div @click="editButtonClicked(props.row)" class="cursor-pointer flex text-blue">
                                    <b-icon pack="fas" icon="pencil-alt" />
                                </div>
                            </b-tooltip>
                            <b-tooltip :label="$t('remove_billing')" position="is-left" type="is-danger">
                                <div @click="removeResources(props.row)" class="cursor-pointer flex text-red">
                                    <b-icon pack="fas" icon="trash-alt" />
                                </div>
                            </b-tooltip>
                        </div>
                    </b-table-column>
                </template>
                <template #empty>
                    <div class="has-text-centered">{{ $t('no_results') }}</div>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from 'axios'

import { parseQuantity } from './helpers.js'

export default {
    props: {
        projectId: {
            type: Number,
            required: true
        },
    },
    computed: {
        ...mapGetters({
            company: "app/company",
        }),
        filters() {
            let filters = {
                'order_field': this.orderField,
                'order_dir': this.orderDir,
                'perPage': this.perPage,
                'page': this.page,
                'project_id': this.projectId,
            }
            return filters
        }
    },

    data: function () {
        return {
            isLoadingTable: true,
            products: [],
            perPage: 25,
            orderField: 'code',
            orderDir: 'asc',
            page: 1,
            last: 1,
            total: 0,
            applyFilters: {},
            selectedProviderId: null
        }
    },

    created() {
        this.getResources();
    },
    methods: {
        formatNumber(value) {
            return value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        },
        async getResources(filters = null) {
            this.isLoadingTable = true;
            try {
                if (filters !== null) {
                    let url = `/api/v2/invoices/provider/resources`;
                    const { data } = await axios.get(url, { params: filters });
                    if (data && data.success) {
                        this.products = data.data;
                        this.total = data.total;
                        this.las = data.lastPage;
                    }
                }
            } catch (error) {
                throw ('Error fetching resources:', error);
            } finally {
                this.isLoadingTable = false;
            }
        },
        onSort(field, order) {
            this.companyId = this.company.id
            this.orderField = field
            this.orderDir = order
            this.getResources({
                provider: this.selectedProviderId,
                orderField: this.orderField,
                orderDir: this.orderDir,
            });
        },

        onPageChange(page) {
            this.page = page;
            this.getResources({
                perPage: this.perPage,
                orderField: this.orderField,
                orderDir: this.orderDir,
                page: this.page,
                provider: this.selectedProviderId
            });
        },

        reload(args = {}) {
            let filters = { ...args, ...this.filters }
            this.selectedProviderId = args.provider;
            this.getResources(filters);
        },

        async editButtonClicked(entity) {
            this.$emit('epc-edit', entity)
        },

        deleteButtonClicked(materialId, materialName) {
            this.$dialog.confirm({
                title: this.$t('delete'),
                message: this.$t('delete_confirm_text', ['el material', materialName]),
                confirmText: this.$t('delete') + ' ' + this.$t('material'),
                type: 'is-danger',
                hasIcon: true,
                onConfirm: async () => {
                    let url = '/api/v2/store-inventory/' + materialId + '/delete'
                    const { data } = await axios.post(url)
                    if (data && data.success) {
                        this.$notify.success('success_deleting_generic')
                        this.getInventary()
                    } else {
                        this.$notify.error(data.error)
                    }
                }
            })
        },

        archiveClass(value) {
            return value ? "cursor-pointer flex text-yellow-dark" : "cursor-pointer flex text-blue"
        },

        handleSelect(newRow) {
            this.$emit('update:template', newRow);
        },

        clearSelected() {
            this.rowSelected = null
        },

        showQuantity(quantity) {
            return parseQuantity(quantity);
        },
        async removeResources(resource) {
            this.$dialog.confirm({
                title: this.$t("delete_resource"),
                message: this.$t('delete_activity_confirm_text', [resource.product.name]),
                confirmText: this.$t("delete_breakdown"),
                type: "is-danger",
                hasIcon: true,
                onConfirm: async () => {
                    this.isLoading = true;
                    let url = "/api/v2/invoice-items/" + resource.id;
                    const { data } = await axios.delete(url);
                    if (data.success) {
                        this.$toast.open({
                            message: this.$t("removed_successfully"),
                            type: "is-success",
                            position: "is-top-right"
                        });
                        this.$emit('total_amount', data.total_amount);
                        this.$emit('reload-list')
                    } else {
                        this.isLoading = false;
                        // Error
                        this.$toast.open({
                            message: this.$t("remove_error"),
                            type: "is-danger",
                            position: "is-top-right"
                        });
                    }
                }
            });
        },
    },

};
</script>