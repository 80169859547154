<template>
    <div>
        <!-- Tabla de costos indirectos -->
        <div class="panel">
            <b-table :data="details" :loading="isLoadingTable" paginated backend-pagination :per-page="perPage"
                :total="total" @page-change="onPageChange" backend-sorting :default-sort="orderField"
                :default-sort-direction="orderDir" @sort="onSort" class="w-full" striped bordered icon-pack="fas">
                <template>
                    <b-table-column field="code" :label="$t('code')" sortable v-slot="props">
                        {{ props.row.code }}
                    </b-table-column>
                    <b-table-column field="description" :label="$t('description')" sortable v-slot="props">
                        {{ props.row.description }}
                    </b-table-column>
                    <b-table-column field="price" :label="$t('price')" sortable v-slot="props" centered>
                        {{ formatNumber(props.row.price) }}
                    </b-table-column>
                    <b-table-column field="quantity" :label="$t('quantity')" sortable v-slot="props">
                        {{ props.row.quantity === 0 ? "-" : props.row.quantity }}
                    </b-table-column>
                    <b-table-column :label="$t('actions')" field="actions" centered width="150" v-slot="props">
                        <div class="w-full h-full flex items-center justify-around">
                            <b-tooltip :label="$t('edit')" position="is-left">
                                <div @click="editButtonClicked(props.row)" class="cursor-pointer flex text-blue">
                                    <b-icon pack="fas" icon="pencil-alt" />
                                </div>
                            </b-tooltip>
                            <b-tooltip :label="$t('remove')" position="is-left" type="is-danger">
                                <div @click="removeDetail(props.row)" class="cursor-pointer flex text-red">
                                    <b-icon pack="fas" icon="trash-alt" />
                                </div>
                            </b-tooltip>
                        </div>
                    </b-table-column>
                </template>
                <template #empty>
                    <div class="has-text-centered">{{ $t('no_results') }}</div>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from 'axios';

export default {
    props: {
        projectId: {
            type: Number,
            required: true
        },
    },
    computed: {
        ...mapGetters({
            company: "app/company",
        }),
        filters() {
            return {
                order_field: this.orderField,
                order_dir: this.orderDir,
                per_page: this.perPage,
                page: this.page,
                project_id: this.projectId,
            };
        }
    },
    data() {
        return {
            isLoadingTable: true,
            details: [],
            perPage: 25,
            orderField: 'code',
            orderDir: 'asc',
            page: 1,
            total: 0,

        };
    },
    created() {
        this.getDetails();
    },
    methods: {
        formatNumber(value) {
            return value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        },
        async getDetails(filters = null) {
            this.isLoadingTable = true;
            try {
                const url = '/api/v2/indirect-cost-detail';
                const { data } = await axios.get(url, { params: filters });

                this.$emit('total-price', data.total_price);
                if (data && data.success) {
                    this.details = data.indirect_cost_details;
                    this.total = data.total;
                }
            } catch (error) {
                throw ('Error fetching details:', error);
            } finally {
                this.isLoadingTable = false;
            }
        },
        onSort(field, order) {
            this.orderField = field;
            this.orderDir = order;
            this.getDetails({
                ...this.filters,
                order_field: this.orderField,
                order_dir: this.orderDir,
            });
        },
        onPageChange(page) {
            this.page = page;
            this.getDetails({
                ...this.filters,
                page: this.page,
            });
        },
        async editButtonClicked(detail) {
            this.$emit('epc-edit', detail);
        },

        async removeDetail(detail) {
            this.$dialog.confirm({
                title: this.$t('delete'),
                message: this.$t('delete_confirm_text', [detail.description]),
                confirmText: this.$t('delete'),
                type: 'is-danger',
                hasIcon: true,
                onConfirm: async () => {
                    try {
                        const url = `/api/v2/indirect-cost-detail/${detail.id}`;
                        const { data } = await axios.delete(url);
                        if (data.success) {
                            this.$toast.open({
                                message: this.$t('removed_successfully'),
                                type: 'is-success',
                                position: 'is-top-right'
                            });
                            this.$emit('reload-list')
                        } else {
                            this.$toast.open({
                                message: this.$t('remove_error'),
                                type: 'is-danger',
                                position: 'is-top-right'
                            });
                        }
                    } catch (error) {
                        throw ('Error removing detail:', error);
                    }
                }
            });
        },
        reload(args = {}) {
            let filters = { ...args, ...this.filters };
            this.getDetails(filters);
        }
    }
};
</script>
