<template>
  <div>
    <div class="flex flex-col w-full relative">
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
      <div v-if="pictogram == null">
        <div class="panel">
          <p class="mb-4"><span class="text-blue-light font-light">Avance proyecto: </span> {{ _GetProjectProgress() }}<span>%</span></p>

          <div class="flex space-x-4">

            <div class="w-2/3 pb-5 relative">
              <b-loading :is-full-page="false" :active="isLoadingImage"></b-loading>
              <b-image v-if="imgPlant.src"
                  :src="imgPlant.src"
                  :alt="imgPlant.name"
                  @load="onImageLoad"
                  @error="onImageError"
              ></b-image>
            </div>

            <div class="w-1/3">
              <table class="w-full border-blue-lighter border-collapse border">
                <thead class="bg-blue-lightest">
                  <tr>
                    <th class="p-2 text-sm font-semibold text-blue-dark border border-blue-lighter">Tipo</th>
                    <th class="p-2 text-sm font-semibold text-blue-dark border border-blue-lighter">Total</th>
                    <th class="p-2 text-sm font-semibold text-blue-dark border border-blue-lighter">Ejecutados</th>
                    <th class="p-2 text-sm font-semibold text-blue-dark border border-blue-lighter">%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="lyr in [{code: 'pilings'}, {code: 'structures'}, {code: 'modules'}]">
                    <td class="justify-start w-auto h-full p-2 align-middle border border-blue-lighter">
                      {{ $t(lyr.code) }}
                      <span v-if="imgPlant.type != lyr.code" class="cursor-pointer text-blue-light"
                        @click="_ShowImagePlant(lyr.code)">
                        <b-icon icon="eye" pack="fas"></b-icon>
                      </span>
                    </td>
                    <td class="justify-start w-auto h-full p-2 align-middle border border-blue-lighter">{{ _GetTotalElements(lyr.code) }}</td>
                    <td class="justify-start w-auto h-full p-2 align-middle border border-blue-lighter">{{ _GetExecutedElements(lyr.code) }}</td>
                    <td class="justify-start w-auto h-full p-2 align-middle border border-blue-lighter">
                      {{ _GetExecutedElements(lyr.code) > 0 ? ((_GetExecutedElements(lyr.code) / _GetTotalElements(lyr.code)) * 100).toFixed(2) : 0 }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div v-if="pictograms.length"
                class="mt-3 mb-3">
                <h5 class="ext-blue-light font-light">Pictogramas</h5>
              </div>

              <table class="w-full border-blue-lighter border-collapse border">
                <tbody>
                  <tr v-for="item in pictograms" @click="_HandleClickSelectPictogram(item)" class="hover:bg-gray-100 cursor-pointer">
                    <td class="justify-start w-auto h-full p-2 align-middle border border-blue-lighter">{{ item.activity_planning_code + ' - ' + item.activity_associated }}</td>
                    <td class="justify-start w-auto h-full p-2 align-middle border border-blue-lighter text-right">{{ item.activity_progress }} <span>%</span></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
      <div v-else>
        <div class="panel">
          <div>
            <b-button
              @click="() => { isLoadingImage = false; pictogram = null; imgPictogram.src = null; imgPictogram.extension = null; imgPictogram.type = null }"
              type="is-small is-primary"
              class="font-semibold uppercase">{{ $t("back") }}
            </b-button>
          </div>
          <div class="flex">
            <div class="w-2/3 pt-5 pb-5 relative">
              <b-loading :is-full-page="false" :active="isLoadingImage"></b-loading>
              <b-image
                  :src="imgPictogram.src"
                  :alt="pictogram.name"
                  @load="onImageLoad"
                  @error="onImageError"
              ></b-image>
            </div>
            <div class="w-1/3">
              <p class="mb-4"><span class="text-blue-light font-light">Avance {{ pictogram.name }}: </span> {{ pictogram.activity_progress }}<span>%</span></p>
              <table class="w-full border-blue-lighter border-collapse border">
                <thead class="bg-blue-lightest">
                  <tr>
                    <th class="p-2 text-sm font-semibold text-blue-dark">Tipo</th>
                    <th class="p-2 text-sm font-semibold text-blue-dark">Total</th>
                    <th class="p-2 text-sm font-semibold text-blue-dark">Ejecutados</th>
                    <th class="p-2 text-sm font-semibold text-blue-dark">%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="lyr in pictogram.layers">
                    <td class="justify-start w-auto h-full p-2 align-middle border border-blue-lighter">{{ $t(lyr.code) }}</td>
                    <td class="justify-start w-auto h-full p-2 align-middle border border-blue-lighter">{{ _GetPartialElements(lyr.code) }}</td>
                    <td class="justify-start w-auto h-full p-2 align-middle border border-blue-lighter">{{ _GetPartialExecutedElements(lyr.code) }}</td>
                    <td class="justify-start w-auto h-full p-2 align-middle border border-blue-lighter">
                      {{ _GetPartialExecutedElements(lyr.code) > 0 ? ((_GetPartialExecutedElements(lyr.code) / _GetPartialElements(lyr.code)) * 100).toFixed(2) : 0 }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="flex space-x-4 mt-5">
            <div v-for="lyr in pictogram.layers" class="w-1/3">
              <table class="w-full border-blue-lighter border-collapse border">
                <thead class="bg-blue-lightest">
                  <tr>
                    <th colspan="2" class="p-2">
                      Estado de {{ lyr.name }}
                      <span v-if="imgPictogram.type != lyr.code" class="cursor-pointer text-blue-light"
                        @click="_ShowImage(lyr.code)">
                        <b-icon icon="eye" pack="fas"></b-icon>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="state in lyr.statuses">
                    <td class="justify-start w-auto h-full p-2 align-middle border border-blue-lighter">
                      <div class="w-4 h-4 rounded-sm" :style="'background-color: ' + state.color"></div>
                    </td>
                    <td class="justify-start w-auto h-full p-2 align-middle border border-blue-lighter text-right">{{ state.name }}: <span>{{ _GetStatusProgress(lyr.code, state.id) }}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from "axios";
import ViewerProgress from "./viewer-konva/design-konva/ViewerProgress.vue"

export default {
  name: "manage-photovoltaic-park",

  components: {
    ViewerProgress
  },

  data: () => ({
    pictograms: [], // contiene la lista de pictogramas existentes en el proyecto
    pictogram: null, // contiene el pictograma seleccionado
    isLoading: false, // bandera que indica si se esta cargando la informacion
    isLoadingImage: false, // bandera que indica si se esta cargando la imagen

    imgPictogram: {
      src: null,
      extension: null,
      type: null
    },

    powerPlant: null,
    imgPlant: {
      src: null,
      extension: null,
      type: null
    },

    designMode: false, // bandera que indica si se esta en modo diseño
    partsPark: [], // arreglo que contiene las partes que component el paque fotovoltaico
    datasetProject: true,
    progressPictogram: null
  }),

  props: {
    projectId: { type: Number, required: true },
    showDownloadFileOriginal: { type: Boolean, default: true },
    permittedActions: { type:Object, default() { return {} } }, // acciones que se mostraran en la columna acciones por defecto todas
    canActionsPictogram: { type:Object, default() { return {} } } // acciones que se puede realizar sobre el pictograma por defecto ninguna
  },

  computed: {
  },

  created() {
    this.getProjectPictograms();
  },

  mounted() {
  },

  destroyed() {
  },

  beforeDestroy() {

    },

  methods: {
    async getProjectPictograms() {
      this.isLoading = true;
      let params = {
        with_screenshot: 1,
        with_associated_activity: 1,
        with_power_plant: 1
      };

      const { data } = await axios.get(
        "/api/v2/project/" + this.projectId + "/pictograms",
        { params: params }
      );

      if (data && data.success) {
        this.pictograms = data.pictograms.filter((el) => {
          return ! el.activity_planning_code.includes('.R.')
        });

        this.powerPlant = data.powerPlant

        if(data.powerPlant) {
          this.isLoadingImage = true

          // TODO: aqui falta condision en caso de que screenshot sea null
          this.imgPlant.src = data.powerPlant.screenshot + '?v=' + new Date().getTime()
          this.imgPlant.extension = data.powerPlant.screenshot.split('.').pop()
          let tmp = data.powerPlant.screenshot.split('_')
          this.imgPlant.type = tmp[tmp.length - 1].split('.').shift()
        }

        this.total = data.pictograms ? data.pictograms.length : 0;
      } else {
        this.$notify.error("error_loading_documents");
      }
      this.isLoading = false;
    },

    _GetTotalElements(type) {

      if (this.pictograms.length > 0) {
        let total = 0
        this.pictograms.forEach( (item) => {
          total += item.metadata['total_' + type]
        })
        return total
      } else {
        return 0
      }
    },

    _GetExecutedElements(type) {

      if (this.pictograms.length > 0) {
        let total = 0
        this.pictograms.forEach( (item) => {
          if (item.metadata.total_progress != null)
            total += (item.metadata.total_progress[type] ?? 0)
        })
        return total
      } else {
        return 0
      }
    },

    _GetPartialElements(type) {

      if (this.pictogram != null) {
        return this.pictogram.metadata['total_' + type]
      } else {
        return 0
      }
    },

    _GetPartialExecutedElements(type) {

      if (this.pictogram != null) {
        return this.pictogram.metadata.total_progress ? this.pictogram.metadata.total_progress[type] : 0

      } else {
        return 0
      }
    },

    _GetProjectProgress() {
      if (this.pictograms.length > 0) {
        let total = 0
        this.pictograms.forEach( (item) => {
          total += item.activity_progress
        })
        return (total / this.pictograms.length).toFixed(2)
      } else {
        return 0
      }
    },

    _GetStatusProgress(type, idState) {
      if (this.pictogram.metadata.statuses_progress != null ) {
        if (this.pictogram.metadata.statuses_progress[type])
          return this.pictogram.metadata.statuses_progress[type][idState]
      }

      return 0
    },

    _ShowImage(type) {
      if (this.pictogram != null && this.pictogram.screenshot != null) {
        this.isLoadingImage = true
        this.imgPictogram.src = this.imgPictogram.src.replace('_' + this.imgPictogram.type + '.' + this.imgPictogram.extension, '_' + type + '.' + this.imgPictogram.extension)

        let url = new URL(this.imgPictogram.src)
        url.searchParams.delete('v')
        this.imgPictogram.src = url.href

        this.imgPictogram.src += '?v=' + new Date().getTime()
        this.imgPictogram.type = type
      }
    },

    _ShowImagePlant(type) {
      if (this.powerPlant != null && this.powerPlant.screenshot != null) {
        this.isLoadingImage = true
        this.imgPlant.src = this.imgPlant.src.replace('_' + this.imgPlant.type + '.' + this.imgPlant.extension, '_' + type + '.' + this.imgPlant.extension)

        let url = new URL(this.imgPlant.src)
        url.searchParams.delete('v')
        this.imgPlant.src = url.href

        this.imgPlant.src += '?v=' + new Date().getTime()
        this.imgPlant.type = type
      }
    },

    _HandleClickSelectPictogram(item) {
      this.isLoadingImage = true
      this.pictogram = item

      if (this.pictogram.screenshot != null) {
        this.imgPictogram.src = item.screenshot + '?v=' + new Date().getTime()
        this.imgPictogram.extension = item.screenshot.split('.').pop()
        let tmp = item.screenshot.split('_')
        this.imgPictogram.type = tmp[tmp.length - 1].split('.').shift()
      }
    },

    onImageLoad() {
      // Se llama cuando la imagen se ha cargado correctamente
      this.isLoadingImage = false;
    },
    onImageError() {
      // Se llama si hay un error al cargar la imagen
      this.isLoadingImage = false;
      console.error('Error al cargar la imagen');
    }
  }

};
</script>

<style scoped>
</style>
